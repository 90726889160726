import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// actions
import { loginUser, socialLogin, resetLoginFlag } from "../../store/actions";

import logoLight from "../../assets/images/logo-light.png";
//Import config
import { facebook, google } from "../../config";
//import images

import { ToastContainer } from "react-toastify";
import { getWithExpiry, setWithExpiry } from "../../Components/Hooks/Functions";

const Login = (props) => {
	const dispatch = useDispatch();
	// const { user } = useSelector(state => ({
	//     user: state.Account.user,
	// }));

	const [loading, setLoading] = useState(false);

	const [infoLogin, setInfoLogin] = useState({
		username: "",
		password: "",
		isChecked: false,
	});

	useEffect(() => {
		if (getWithExpiry("checkbox") && getWithExpiry("username") !== "") {
			setInfoLogin({
				username: getWithExpiry("username"),
				password: getWithExpiry("password"),
				isChecked: true,
			});
		}
	}, []);

	const loginSubmit = () => {
		const { username, password, isChecked } = infoLogin;
		if (isChecked && username !== "") {
			setWithExpiry("username", username);
			setWithExpiry("password", password);
			setWithExpiry("checkbox", isChecked);
		} else if (!isChecked && username !== "") {
			localStorage.removeItem("username");
			localStorage.removeItem("password");
		}
		// here call the API to signup/login
	};

	const onChangeCheckbox = (event) => {
		setInfoLogin((prev) => {
			return { ...prev, ["isChecked"]: event.target.checked };
		});
	};

	const onChangeValue = (event) => {
		setInfoLogin((prev) => {
			return { ...prev, [event.target.name]: event.target.value };
		});
	};

	const [values, setValues] = React.useState({
		password: "",
		showPassword: false,
	});

	const [userLogin, setUserLogin] = useState([]);

	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			// email: userLogin.email || "admin@themesbrand.com" || '',
			// password: userLogin.password || "123456" || '',
			username: userLogin.username || infoLogin.username || "",
			password: userLogin.password || infoLogin.password || "",
		},
		validationSchema: Yup.object({
			// username: Yup.string().email("Invalid Email Format").required("Please Enter Your Email!"),
			username: Yup.string().required("Please Enter Your User Name or Email"),
			password: Yup.string().required("Please Enter Your Password"),
		}),
		onSubmit: (values) => {
			setLoading(true);
			loginSubmit();
			dispatch(loginUser(values, props.history));
		},
	});

	const { error } = useSelector((state) => ({
		error: state.Login.error,
	}));

	const signIn = (res, type) => {
		if (type === "google" && res) {
			const postData = {
				name: res.profileObj.name,
				email: res.profileObj.email,
				token: res.tokenObj.access_token,
				idToken: res.tokenId,
			};
			dispatch(socialLogin(postData, props.history, type));
		} else if (type === "facebook" && res) {
			const postData = {
				name: res.name,
				email: res.email,
				token: res.accessToken,
				idToken: res.tokenId,
			};
			dispatch(socialLogin(postData, props.history, type));
		}
	};

	//handleGoogleLoginResponse
	const googleResponse = (response) => {
		signIn(response, "google");
	};

	//handleTwitterLoginResponse
	// const twitterResponse = e => {}

	//handleFacebookLoginResponse
	const facebookResponse = (response) => {
		signIn(response, "facebook");
	};

	useEffect(() => {
		setTimeout(() => {
			dispatch(resetLoginFlag());
		}, 3000);
	}, [dispatch, error]);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1500);
	}, [loading]);

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	document.title = "SignIn | IP Supply";

	return (
		<React.Fragment>
			<ToastContainer closeButton={false} />
			<ParticlesAuth>
				<div className="auth-page-content">
					<Container>
						<Row>
							<Col lg={12}>
								<div className="text-center mt-sm-5 mb-4 text-white-50">
									<p className="mt-3 fs-15 fw-medium">
										<Link to="/" className="d-inline-block auth-logo">
											<img src={logoLight} alt="" height="100" />
										</Link>
									</p>
								</div>
							</Col>
						</Row>

						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="mt-4">
									<CardBody className="p-4">
										<div className="text-center mt-2">
											<h5 className="text-primary">Welcome Back !</h5>
											<p className="text-muted">Sign in to continue to IP Supply.</p>
										</div>
										{error && error ? <Alert color="danger"> {error} </Alert> : null}
										<div className="p-2 mt-4">
											<Form
												onSubmit={(e) => {
													e.preventDefault();
													validation.handleSubmit();
													return false;
												}}
												action="#">
												{/* <div className="mb-3">
                          <Label htmlFor="username" className="form-label">
                            User name
                          </Label>
                          <Input
                            name="username"
                            className="form-control"
                            placeholder="Enter username"
                            type="text"
                            onChange={(e) => {
                              onChangeValue(e);
                              validation.handleChange(e);
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username &&
                              validation.errors.username
                                ? true
                                : false
                            }
                          />
                          {validation.touched.username &&
                          validation.errors.username ? (
                            <FormFeedback type="invalid">
                              {validation.errors.username}
                            </FormFeedback>
                          ) : null}
                        </div> */}
												<div className="mb-3">
													<Label htmlFor="username" className="form-label">
														User name / Email
													</Label>
													<Input
														name="username"
														className="form-control"
														placeholder="Enter user name or email"
														type="text"
														onChange={(e) => {
															onChangeValue(e);
															validation.handleChange(e);
														}}
														onBlur={validation.handleBlur}
														value={validation.values.username || ""}
														invalid={validation.touched.username && validation.errors.username ? true : false}
													/>
													{validation.touched.username && validation.errors.username ? (
														<FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
													) : null}
												</div>

												<div className="mb-3">
													<div className="float-end">
														<Link to="/forgot-password" className="text-muted">
															Forgot password?
														</Link>
													</div>
													<Label className="form-label" htmlFor="password-input">
														Password
													</Label>
													<div className="position-relative auth-pass-inputgroup mb-3">
														<Input
															name="password"
															value={validation.values.password || ""}
															type={values.showPassword ? "text" : "password"}
															className="form-control pe-5"
															placeholder="Enter Password"
															onChange={(e) => {
																onChangeValue(e);
																validation.handleChange(e);
															}}
															onBlur={validation.handleBlur}
															invalid={validation.touched.password && validation.errors.password ? true : false}
														/>
														{validation.touched.password && validation.errors.password ? (
															<FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
														) : null}
														<button
															className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
															type="button"
															id="password-addon"
															onClick={handleClickShowPassword}>
															{validation.values.password != "" ? (
																values.showPassword ? (
																	<i className="ri-eye-fill align-middle"></i>
																) : (
																	<i className="ri-eye-fill align-middle"></i>
																)
															) : null}
														</button>
													</div>
												</div>

												<div className="form-check">
													<Input
														className="form-check-input"
														type="checkbox"
														value=""
														checked={infoLogin.isChecked}
														onChange={onChangeCheckbox}
														id="auth-remember-check"
													/>
													<Label className="form-check-label" htmlFor="auth-remember-check">
														Remember me
													</Label>
												</div>

												<div className="mt-4">
													<Button color="success" className="btn btn-success w-100" type="submit" disabled={loading}>
														Sign In
													</Button>
												</div>
											</Form>
										</div>
									</CardBody>
								</Card>

								{/* <div className="mt-4 text-center">
                  <p className="mb-0">
                    Don't have an account ?{" "}
                    <Link
                      to="/register"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      Signup
                    </Link>
                  </p>
                </div> */}
							</Col>
						</Row>
					</Container>
				</div>
			</ParticlesAuth>
		</React.Fragment>
	);
};

export default withRouter(Login);
