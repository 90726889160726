import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";

// Crypto Redux States
import {
	SEARCH_VPN_GET_PRODUCT_PRICE_XERO,
	SEARCH_VPN_GET_PRODUCT_PRICE,
	SEARCH_VPN_GET_PRODUCT_PRICE_QUOTE_IN,
	SEARCH_VPN_GET_PRODUCT_ERP,
	SEARCH_VPN_REFRESH_LOADING,
	SEARCH_VPN_GET_XERO_INVOICE_BY_NUM,
	SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_AU,
	SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_US,
	SEARCH_VPN_GET_LIST_SERIAL_NUMBER_SEARCH_VPN,
	SEARCH_VPN_PRODUCT_ERP_UPDATE_SN,
	SEARCH_VPN_GET_SN_BY_ID,
	SEARCH_VPN_GET_LIST_POSITION,
	SEARCH_VPN_GET_LIST_MODEL,
	SEARCH_VPN_GET_LIST_EMAIL,
	SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP,
	SEARCH_VPN_GET_TEST_LOG_SERIAL_NUMBER_PRODUCT_ERP,
	SEARCH_VPN_GET_HISTORY_PRODUCT_ERP,
	SEARCH_VPN_GET_RECORD_LOGS_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_WAREHOUSE_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_CONDITION_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_MANAGEMENT_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_STATUS_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_HASHTAGS_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_SUPPLIER_PRODUCT_ERP,
	SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP,
	SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_MODEL_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_ORGANIZATION_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_POSITION_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_USER_PRODUCT_ERP,
	SEARCH_VPN_GET_PRODUCT_PRICE_XERO_QUOTE_IN,
} from "./actionType";
import {
	dashboardSearchVPNApiSuccess,
	dashboardSearchVPNApiError,
	updateSNProductErpSearchVPNSuccess,
	updateSNProductErpSearchVPNFail,
	getLogTextSNProductErpSearchVPNFail,
	getLogTextSNProductErpSearchVPNSuccess,
	quickAddSupplierFilterProductErpSearchVPNFail,
	quickAddSupplierFilterProductErpSearchVPNSuccess,
	quickAddSupplierEditProductErpSearchVPNSuccess,
	quickAddSupplierEditProductErpSearchVPNFail,
} from "./action";

//Include Both Helper File with needed methods
import {
	getProductPriceXero,
	getProductPrice,
	getProductPriceQuoteIn,
	getProductErp,
	getXeroInvoice,
	getAmountProductErp,
	transferGetData,
	transferPostData,
	getListDictionary,
	getListOrganization,
	getListWarehouse,
	getListSuplier,
	getListModel,
	getScanLogText,
} from "../../helpers/fakebackend_helper";
import { getTransationList } from "../actions";
import { toast } from "react-toastify";

function* getProductPriceXeroDataSearchVPN({ payload: data }) {
	try {
		var response;
		response = yield call(getProductPriceXero, data);
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_PRODUCT_PRICE_XERO, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_PRODUCT_PRICE_XERO, error));
	}
}

function* getProductPriceDataSearchVPN({ payload: data }) {
	try {
		var response;
		response = yield call(getProductPrice, data);
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_PRODUCT_PRICE, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_PRODUCT_PRICE, error));
	}
}

function* getProductPriceDataQuoteInSearchVPN({ payload: data }) {
	try {
		var response;
		response = yield call(transferGetData, data);
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_PRODUCT_PRICE_QUOTE_IN, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_PRODUCT_PRICE_QUOTE_IN, error));
	}
}
function* getProductPriceDataXeroQuoteInSearchVPN({ payload: data }) {
	try {
		var response;
		response = yield call(transferGetData, data);
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_PRODUCT_PRICE_XERO_QUOTE_IN, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_PRODUCT_PRICE_XERO_QUOTE_IN, error));
	}
}

function* getProductDataErpSearchVPN({ payload: data }) {
	try {
		var response;
		response = yield call(getProductErp, data);
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_PRODUCT_ERP, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_PRODUCT_ERP, error));
	}
}

function* getAmountProductErpAuSearchVPN({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_AU, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_AU, error));
	}
}

function* getAmountProductErpUsSearchVPN({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_US, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_US, error));
	}
}

//Detail
function* getListSNDataSearchVPN({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_LIST_SERIAL_NUMBER_SEARCH_VPN, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_SERIAL_NUMBER_SEARCH_VPN, error));
	}
}
export function* watcherSearchVpnGetListSNData() {
	yield takeEvery(SEARCH_VPN_GET_LIST_SERIAL_NUMBER_SEARCH_VPN, getListSNDataSearchVPN);
}

//xero invoice
function* getXeroInvoiceDataSearchVPN({ payload: data }) {
	try {
		var response;
		response = yield call(getXeroInvoice, data);
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_XERO_INVOICE_BY_NUM, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_XERO_INVOICE_BY_NUM, error));
	}
}

// function* resetInitData() {
// 	yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_REFRESH_LOADING, []));
// }

export function* watcherSearchVPNGetProductPriceXero() {
	yield takeEvery(SEARCH_VPN_GET_PRODUCT_PRICE_XERO, getProductPriceXeroDataSearchVPN);
}
export function* watcherSearchVPNGetProductPrice() {
	yield takeEvery(SEARCH_VPN_GET_PRODUCT_PRICE, getProductPriceDataSearchVPN);
}
export function* watcherSearchVPNGetProductPriceQuoteIn() {
	yield takeEvery(SEARCH_VPN_GET_PRODUCT_PRICE_QUOTE_IN, getProductPriceDataQuoteInSearchVPN);
}

export function* watcherSearchVPNGetProductPriceXeroQuoteIn() {
	yield takeEvery(SEARCH_VPN_GET_PRODUCT_PRICE_XERO_QUOTE_IN, getProductPriceDataXeroQuoteInSearchVPN);
}

export function* watcherSearchVPNGetProductPriceErp() {
	yield takeEvery(SEARCH_VPN_GET_PRODUCT_ERP, getProductDataErpSearchVPN);
}

export function* watcherSearchVPNgetAmountProductErpAuSearchVPN() {
	yield takeEvery(SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_AU, getAmountProductErpAuSearchVPN);
}
export function* watcherSearchVPNgetAmountProductErpUsSearchVPN() {
	yield takeEvery(SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_US, getAmountProductErpUsSearchVPN);
}

// export function* watcherSearchVPNResetInitData() {
// 	yield takeEvery(SEARCH_VPN_REFRESH_LOADING, resetInitData);
// }

export function* watcherSearchVPNGetXeroInvoiceData() {
	yield takeEvery(SEARCH_VPN_GET_XERO_INVOICE_BY_NUM, getXeroInvoiceDataSearchVPN);
}

// Update SN
export function* updateSerialNumberProductErp({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);

		if (response.Status === "OK") {
			const dataByID = yield call(transferGetData, {
				urlAPI: "/api/stock-model-serial/get-info/" + response.data.id,
			});
			yield put(updateSNProductErpSearchVPNSuccess(dataByID));

			toast.success("Update Serial Number Successfully", { autoClose: 1000 });
		} else {
			yield put(updateSNProductErpSearchVPNFail(response));
			toast.error("Update Serial Number Failed", { autoClose: 3000 });
		}
	} catch (error) {
		yield put(updateSNProductErpSearchVPNFail(error));
		toast.error("Update Serial Number Failed", { autoClose: 3000 });
	}
}
export function* watcherupdateSerialNumberErp() {
	yield takeEvery(SEARCH_VPN_PRODUCT_ERP_UPDATE_SN, updateSerialNumberProductErp);
}

//Get SN BY ID
function* getSNByIDProductErp({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		if (response?.errors || response?.result === "ERROR") {
			yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_SN_BY_ID, response));
			return;
		}
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_SN_BY_ID, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_SN_BY_ID, error));
	}
}

export function* watcherSearchVPNGetSNByIDProductErp() {
	yield takeEvery(SEARCH_VPN_GET_SN_BY_ID, getSNByIDProductErp);
}

function* getListOrganizationProductErp({ payload: data }) {
	try {
		const response = yield call(getListOrganization, data);
		if (response?.errors || response?.result === "ERROR") {
			yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_ORGANIZATION_PRODUCT_ERP, response));
			return;
		}
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_LIST_ORGANIZATION_PRODUCT_ERP, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_ORGANIZATION_PRODUCT_ERP, error));
	}
}
export function* watcherSearchVPNGetListOrganizationErp() {
	yield takeEvery(SEARCH_VPN_GET_LIST_ORGANIZATION_PRODUCT_ERP, getListOrganizationProductErp);
}

function* getListPositionProductErp({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		if (response?.errors || response?.result === "ERROR") {
			yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_POSITION_PRODUCT_ERP, response));
			return;
		}
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_LIST_POSITION_PRODUCT_ERP, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_POSITION_PRODUCT_ERP, error));
	}
}

export function* watcherSearchVPNGetListPositionErp() {
	yield takeEvery(SEARCH_VPN_GET_LIST_POSITION, getListPositionProductErp);
}

function* getListEmailSearchVPN({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_LIST_EMAIL, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_EMAIL, error));
	}
}
export function* watcherSearchVPNGetListEmail() {
	yield takeLatest(SEARCH_VPN_GET_LIST_EMAIL, getListEmailSearchVPN);
}

// << == Product Detail ERP  == >> //
// Scan Log - Product Detail ERP
function* getDataLogTextSerialNumberProductErp({ payload: data }) {
	try {
		const response = yield call(getScanLogText, data);
		if (response?.errors || response?.result === "ERROR") {
			yield put(getLogTextSNProductErpSearchVPNFail(response));
			return;
		}
		yield put(getLogTextSNProductErpSearchVPNSuccess(response));
	} catch (error) {
		yield put(getLogTextSNProductErpSearchVPNFail(error));
		toast.error("Get data scan log failed", { autoClose: 3000 });
	}
}

export function* watcherSearchVPNGetDataLogTextSerialNumberErp() {
	yield takeEvery(SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP, getDataLogTextSerialNumberProductErp);
}

function* getDataTestLogSerialNumberProductErp({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		if (response?.errors || response?.result === "ERROR") {
			yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_TEST_LOG_SERIAL_NUMBER_PRODUCT_ERP, response));
			return;
		}
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_TEST_LOG_SERIAL_NUMBER_PRODUCT_ERP, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_TEST_LOG_SERIAL_NUMBER_PRODUCT_ERP, error));
	}
}

export function* watcherSearchVPNGetDataTestLogSerialNumberErp() {
	yield takeEvery(SEARCH_VPN_GET_TEST_LOG_SERIAL_NUMBER_PRODUCT_ERP, getDataTestLogSerialNumberProductErp);
}

//Get List History
function* getHistoryProductErp({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		if (response?.errors || response?.result === "ERROR") {
			yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_HISTORY_PRODUCT_ERP, response));
			return;
		}
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_HISTORY_PRODUCT_ERP, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_HISTORY_PRODUCT_ERP, error));
	}
}

export function* watcherSearchVPNGetHistoryErp() {
	yield takeEvery(SEARCH_VPN_GET_HISTORY_PRODUCT_ERP, getHistoryProductErp);
}

//Get List Record Logs
function* getRecordLogsProductErp({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		if (response?.errors || response?.result === "ERROR") {
			yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_RECORD_LOGS_PRODUCT_ERP, response));
			return;
		}
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_RECORD_LOGS_PRODUCT_ERP, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_RECORD_LOGS_PRODUCT_ERP, error));
	}
}

export function* watcherSearchVPNGetRecordLogsErp() {
	yield takeEvery(SEARCH_VPN_GET_RECORD_LOGS_PRODUCT_ERP, getRecordLogsProductErp);
}

function* getListWarehouseProductErp({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);

		if (response?.errors || response?.result === "ERROR") {
			yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_WAREHOUSE_PRODUCT_ERP, response));
			return;
		}
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_LIST_WAREHOUSE_PRODUCT_ERP, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_WAREHOUSE_PRODUCT_ERP, error));
	}
}
export function* watcherSearchVPNGetListWarehouseErp() {
	yield takeEvery(SEARCH_VPN_GET_LIST_WAREHOUSE_PRODUCT_ERP, getListWarehouseProductErp);
}

//ProductModel (ProductERP Detail)
function* getListModelProductErp({ payload: data }) {
	try {
		const response = yield call(getListModel, data);
		if (response?.errors || response?.result === "ERROR") {
			yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_MODEL, response));
			return;
		}
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_LIST_MODEL, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_MODEL, error));
	}
}

export function* watcherSearchVPNGetListModelErp() {
	yield takeEvery(SEARCH_VPN_GET_LIST_MODEL_PRODUCT_ERP, getListModelProductErp);
}

//Condition (ProductERP Detail)
function* getListConditionProductErp({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		if (response?.errors || response?.result === "ERROR") {
			yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_CONDITION_PRODUCT_ERP, response));
			return;
		}
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_LIST_CONDITION_PRODUCT_ERP, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_CONDITION_PRODUCT_ERP, error));
	}
}

export function* watcherSearchVPNGetListConditionErp() {
	yield takeEvery(SEARCH_VPN_GET_LIST_CONDITION_PRODUCT_ERP, getListConditionProductErp);
}

//StatusList (ProductERP Detail)
function* getListStatusProductErp({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		if (response?.errors || response?.result === "ERROR") {
			yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_STATUS_PRODUCT_ERP, response));
			return;
		}
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_LIST_STATUS_PRODUCT_ERP, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_STATUS_PRODUCT_ERP, error));
	}
}
export function* watcherSearchVPNGetListStatusErp() {
	yield takeEvery(SEARCH_VPN_GET_LIST_STATUS_PRODUCT_ERP, getListStatusProductErp);
}

//HashTagsList (ProductERP Detail)
function* getListHashTagsProductErp({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		if (response?.errors || response?.result === "ERROR") {
			yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_HASHTAGS_PRODUCT_ERP, response));
			return;
		}
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_LIST_HASHTAGS_PRODUCT_ERP, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_HASHTAGS_PRODUCT_ERP, error));
	}
}
export function* watcherSearchVPNGetListHashTagsErp() {
	yield takeEvery(SEARCH_VPN_GET_LIST_HASHTAGS_PRODUCT_ERP, getListHashTagsProductErp);
}

//SupplierList (ProductERP Detail)
function* getListSupplierProductErp({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		if (response?.errors || response?.result === "ERROR") {
			yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_SUPPLIER_PRODUCT_ERP, response));
			return;
		}
		if (Array.isArray(response)) {
			yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_LIST_SUPPLIER_PRODUCT_ERP, response));
		}
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_SUPPLIER_PRODUCT_ERP, error));
	}
}

export function* watcherSearchVPNGetListSupplierErp() {
	yield takeEvery(SEARCH_VPN_GET_LIST_SUPPLIER_PRODUCT_ERP, getListSupplierProductErp);
}

//ManagementList (ProductERP Detail)
function* getListManagementProductErp({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		if (response?.errors || response?.result === "ERROR") {
			yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_MANAGEMENT_PRODUCT_ERP, response));
			return;
		}
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_LIST_MANAGEMENT_PRODUCT_ERP, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_MANAGEMENT_PRODUCT_ERP, error));
	}
}
export function* watcherSearchVPNGetListManagementErp() {
	yield takeEvery(SEARCH_VPN_GET_LIST_MANAGEMENT_PRODUCT_ERP, getListManagementProductErp);
}

//Get List Users
function* getListUserProductErp({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		if (response?.errors || response?.result === "ERROR") {
			yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_USER_PRODUCT_ERP, response));
			return;
		}
		yield put(dashboardSearchVPNApiSuccess(SEARCH_VPN_GET_LIST_USER_PRODUCT_ERP, response));
	} catch (error) {
		yield put(dashboardSearchVPNApiError(SEARCH_VPN_GET_LIST_USER_PRODUCT_ERP, error));
	}
}

export function* watcherSearchVPNGetListUserErp() {
	yield takeEvery(SEARCH_VPN_GET_LIST_USER_PRODUCT_ERP, getListUserProductErp);
}

// Supplier (QuickAdd)
function* quickAddSupplierFilterProductErp({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(quickAddSupplierFilterProductErpSearchVPNFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(quickAddSupplierFilterProductErpSearchVPNFail(response));
		} else {
			yield put(quickAddSupplierFilterProductErpSearchVPNSuccess(response));
			toast.success("Add Supplier Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		yield put(quickAddSupplierFilterProductErpSearchVPNFail(error));
		toast.error("Add Supplier Failed", { autoClose: 3000 });
	}
}

export function* watcherSearchVPNQuickAddSupplierFilterErp() {
	yield takeEvery(SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP, quickAddSupplierFilterProductErp);
}

// Supplier (QuickAdd)
function* quickAddSupplierEditProductErp({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(quickAddSupplierEditProductErpSearchVPNFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(quickAddSupplierEditProductErpSearchVPNFail(response));
		} else {
			yield put(quickAddSupplierEditProductErpSearchVPNSuccess(response));
			toast.success("Add Supplier Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		yield put(quickAddSupplierEditProductErpSearchVPNFail(error));
		toast.error("Add Supplier Failed", { autoClose: 3000 });
	}
}

export function* watcherSearchVPNQuickAddSupplierEditErp() {
	yield takeEvery(SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP, quickAddSupplierEditProductErp);
}
// << == Product Detail ERP  == >> //

function* dashboardSearchVPNSaga() {
	yield all([
		fork(watcherSearchVPNGetProductPriceXero),
		fork(watcherSearchVPNGetProductPrice),
		fork(watcherSearchVPNGetProductPriceQuoteIn),
		fork(watcherSearchVPNGetProductPriceErp),
		fork(watcherSearchVPNGetXeroInvoiceData),
		fork(watcherSearchVPNgetAmountProductErpAuSearchVPN),
		fork(watcherSearchVPNgetAmountProductErpUsSearchVPN),
		fork(watcherSearchVpnGetListSNData),
		fork(watcherupdateSerialNumberErp),
		fork(watcherSearchVPNGetSNByIDProductErp),
		fork(watcherSearchVPNGetListEmail),
		fork(watcherSearchVPNGetListOrganizationErp),
		fork(watcherSearchVPNGetListPositionErp),
		fork(watcherSearchVPNGetListModelErp),
		fork(watcherSearchVPNGetListWarehouseErp),
		fork(watcherSearchVPNGetListConditionErp),
		fork(watcherSearchVPNGetListManagementErp),
		fork(watcherSearchVPNGetListHashTagsErp),
		fork(watcherSearchVPNGetListStatusErp),
		fork(watcherSearchVPNGetListSupplierErp),
		fork(watcherSearchVPNGetListUserErp),
		fork(watcherSearchVPNQuickAddSupplierFilterErp),
		fork(watcherSearchVPNQuickAddSupplierEditErp),
		fork(watcherSearchVPNGetDataLogTextSerialNumberErp),
		fork(watcherSearchVPNGetDataTestLogSerialNumberErp),
		fork(watcherSearchVPNGetHistoryErp),
		fork(watcherSearchVPNGetRecordLogsErp),
		fork(watcherSearchVPNGetProductPriceXeroQuoteIn),
	]);
}

export default dashboardSearchVPNSaga;
