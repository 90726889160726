import {
	LOGISTICS_API_RESPONSE_ERROR,
	LOGISTICS_API_RESPONSE_SUCCESS,
	LOGISTICS_GET_LIST_ADDRESS,
	LOGISTICS_GET_LIST_HISTORY,
	LOGISTICS_ADD_ADDRESS,
	LOGISTICS_UPDATE_ADDRESS,
	LOGISTICS_SET_VALUE_FROM_TO_DOMESTIC,
	LOGISTICS_SET_VALUE_FROM_TO_INTERNATIONAL,
	LOGISTICS_GET_LIST_PACKAGE_SIZE,
	LOGISTICS_ADD_PACKAGE_SIZE,
	LOGISTICS_UPDATE_PACKAGE_SIZE,
	LOGISTICS_DELETE_PACKAGE_SIZE,
	LOGISTICS_DELETE_PACKAGE_SIZE_SUCCESS,
	LOGISTICS_DELETE_PACKAGE_SIZE_FAIL,
	LOGISTICS_SET_VALUE_PACKAGE_SIZE_DOMESTIC,
	LOGISTICS_SET_VALUE_PACKAGE_SIZE_INTERNATIONAL,
	LOGISTICS_GET_RATE_SHIPMENT,
	LOGISTICS_GET_DEFINITIONS,
	LOGISTICS_ADD_ROWS_PACKAGE_SIZE_SUCCESS,
	LOGISTICS_UPDATE_ROWS_PACKAGE_SIZE_SUCCESS,
	LOGISTICS_DELETE_ROWS_PACKAGE_SIZE_SUCCESS,
	LOGISTICS_GET_LIST_ADDRESS_DEFAULT,
	LOGISTICS_SET_VALUE_DOMESTIC_TO_INTERNATIONAL,
	LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_DOMESTIC,
	LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_INTERNATIONAL,
	LOGISTICS_DELETE_ADDRESS,
} from "./actionType";

// common success
export const listLogisticsApiResponseSuccess = (actionType, data) => ({
	type: LOGISTICS_API_RESPONSE_SUCCESS,
	payload: { actionType, data },
});
// common error
export const listLogisticsApiResponseError = (actionType, error) => ({
	type: LOGISTICS_API_RESPONSE_ERROR,
	payload: { actionType, error },
});

export const listLogisticsGetListMails = (page, limit, order, sortDirection, search = "", shippingType = "", isBooked) => ({
	type: LOGISTICS_GET_LIST_HISTORY,
	payload: {
		filter: {
			limit: limit,
			skip: page * limit - limit,
			order: order + " " + sortDirection,
			where: {
				_q: search,
				shippingType,
				isBooked: isBooked ? isBooked : null,
			},
		},
	},
});

export const listLogisticsGetListAddress = (page, limit, order, sortDirection, search = "") => ({
	type: LOGISTICS_GET_LIST_ADDRESS,
	payload: {
		filter: {
			limit: limit,
			skip: page * limit - limit,
			order: order + " " + sortDirection,
			where: {
				_q: search,
			},
		},
	},
});

export const listLogisticsGetListAddressDefaultSearchAddressDomestic = (page, limit, order, sortDirection, search = "") => ({
	type: LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_DOMESTIC,
	payload: {
		filter: {
			limit: limit,
			skip: page * limit - limit,
			order: order + " " + sortDirection,
			where: {
				shippingType: "Domestic",
			},
		},
	},
});

export const listLogisticsGetListAddressDefaultSearchAddressInternational = (page, limit, order, sortDirection, search = "") => ({
	type: LOGISTICS_GET_LIST_ADDRESS_DEFAULT_SEARCH_ADDRESS_INTERNATIONAL,
	payload: {
		filter: {
			limit: limit,
			skip: page * limit - limit,
			order: order + " " + sortDirection,
			where: {
				shippingType: "International",
			},
		},
	},
});

export const listLogisticsGetListAddressDefault = (page = 1, limit = 50, order = "updatedAt", sortDirection = "desc", search = "") => ({
	type: LOGISTICS_GET_LIST_ADDRESS_DEFAULT,
	payload: {
		filter: {
			limit: limit,
			skip: page * limit - limit,
			order: order + " " + sortDirection,
			where: {
				_q: search,
				default: 1,
			},
		},
	},
});

//Save address
export const listLogisticsAddAddress = (data) => ({
	type: LOGISTICS_ADD_ADDRESS,
	payload: {
		data,
	},
});

export const listLogisticsUpdateAddress = (data) => ({
	type: LOGISTICS_UPDATE_ADDRESS,
	payload: {
		data,
	},
});

export const listLogisticsDeleteAddress = (data) => ({
	type: LOGISTICS_DELETE_ADDRESS,
	payload: {
		data,
	},
});

export const setData = (data) => ({
	type: LOGISTICS_UPDATE_ADDRESS,
	payload: {
		data,
	},
});

export const listLogisticsSetValueFromToDomestic = (data) => ({
	type: LOGISTICS_SET_VALUE_FROM_TO_DOMESTIC,
	payload: data,
});

export const listLogisticsSetValueDomesticToInternational = (data) => ({
	type: LOGISTICS_SET_VALUE_DOMESTIC_TO_INTERNATIONAL,
	payload: data,
});

export const listLogisticsSetValueFromToInternational = (data) => ({
	type: LOGISTICS_SET_VALUE_FROM_TO_INTERNATIONAL,
	payload: data,
});

//Get list package size
export const getListPackageSize = (page, limit, order, sortDirection, search = "") => ({
	type: LOGISTICS_GET_LIST_PACKAGE_SIZE,
	payload: {
		filter: {
			limit: limit,
			skip: page * limit - limit,
			order: order + " " + sortDirection,
			where: {
				_q: search,
			},
		},
	},
});

//Save package size
export const listLogisticsAddPackageSize = (data, index) => ({
	type: LOGISTICS_ADD_PACKAGE_SIZE,
	payload: {
		data,
		index,
	},
});

export const listLogisticsAddRowsPackageSize = (data) => ({
	type: LOGISTICS_ADD_ROWS_PACKAGE_SIZE_SUCCESS,
	payload: data,
});
export const listLogisticsUpdateRowsPackageSize = (data) => ({
	type: LOGISTICS_UPDATE_ROWS_PACKAGE_SIZE_SUCCESS,
	payload: data,
});
export const listLogisticsDeleteRowsPackageSize = (data) => ({
	type: LOGISTICS_DELETE_ROWS_PACKAGE_SIZE_SUCCESS,
	payload: data,
});

export const listLogisticsUpdatePackageSize = (data) => ({
	type: LOGISTICS_UPDATE_PACKAGE_SIZE,
	payload: {
		data,
	},
});

export const listLogisticsDeletePackageSize = (data) => ({
	type: LOGISTICS_DELETE_PACKAGE_SIZE,
	payload: data,
});

export const listLogisticsDeletePackageSizeSuccess = (data) => ({
	type: LOGISTICS_DELETE_PACKAGE_SIZE_SUCCESS,
	payload: data,
});

export const listLogisticsDeletePackageSizeFail = (error) => ({
	type: LOGISTICS_DELETE_PACKAGE_SIZE_FAIL,
	payload: error,
});

export const listLogisticsSetValuePackageSizeDomestic = (data) => ({
	type: LOGISTICS_SET_VALUE_PACKAGE_SIZE_DOMESTIC,
	payload: data,
});

export const listLogisticsSetValuePackageSizeInternational = (data) => ({
	type: LOGISTICS_SET_VALUE_PACKAGE_SIZE_INTERNATIONAL,
	payload: data,
});

//GET RATE SHIPMENT
export const listLogisticsGetRateShipment = (data) => ({
	type: LOGISTICS_GET_RATE_SHIPMENT,
	payload: {
		data,
	},
});

//Demension
export const listLogisticsGetDefinitions = () => ({
	type: LOGISTICS_GET_DEFINITIONS,
	payload: {},
});
