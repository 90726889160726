import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";

import { useProfile } from "../Components/Hooks/UserHooks";

import { logoutUser } from "../store/actions";
import useAuthUser from "../Components/Hooks/AuthUser";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const { userProfile, loading, token } = useProfile();
  useEffect(() => {
    if (userProfile && !loading && token) {
      setAuthorization(token);
    } else if (!userProfile && loading && !token) {
      dispatch(logoutUser());
    }
  }, [token, userProfile, loading, dispatch]);

  /*
    redirect is un-auth access protected routes via url
    */
  // console.log("userProfile", userProfile, "loading", loading, "token", token);
  if (!userProfile && loading && !token) {
    return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  const { arrayPageRoles } = useAuthUser();
  if (arrayPageRoles.includes(rest.role) || rest.path == "/") {
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <>
              <Component {...props} />
            </>
          );
        }}
      />
    );
  } else {
    return <></>;
  }
};

export { AuthProtected, AccessRoute };
