import React from "react";

const FormSubmit = (props) => {
	const { children, ...otherProps } = props;
	const preventEnterSubmit = (event) => {
		if ((event.charCode || event.keyCode) === 13 && !event.target.matches("textarea")) {
			event.preventDefault();
		}
	};
	return (
		<form onKeyDown={preventEnterSubmit} {...otherProps}>
			{children}
		</form>
	);
};

export default FormSubmit;
