import React, { useState, useEffect, memo, useMemo, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableProductRows from "./TablePackageRows";
import { Button, Col, Row, Table, Input, FormFeedback } from "reactstrap";
import ModelQuickSelect from "../ModelQuickSelect/ModelQuickSelect";
import { getListPackageSize, listLogisticsSetValuePackageSizeDomestic } from "../../../../store/actions";
import TableQuickSelectRows from "./TableQuickSelectRows";
import InputComponent from "../../../../Components/Hooks/InputComponent";
import CommoditiesInformation from "../CommoditiesInformation/CommoditiesInformation";

function ListPackageTable({ setFieldValue, isDisablePO = false, isIpadMode, valuesParent, formikParent, setIsDisableSubmit, errors, touched }) {
	const dispatch = useDispatch();

	const [rowsData, setRowsData] = useState([]);
	const [rowsDataSelect, setRowsDataSelect] = useState([]);
	const [inputValueSearchProductCode, setInputValueSearchProductCode] = useState("");
	const [adding, setAdding] = useState(false);
	const [openModalQuickSelect, setOpenModalQuickSelect] = useState(false);
	const [defaultData, setDefaultData] = useState(false);
	const [defaultDataSelect, setDefaultDataSelect] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [order, setOrder] = useState("_iid");
	const [sortDirection, setSortDirection] = useState("asc");
	const [perPage, setPerPage] = useState(50);

	const toggleQuickSelect = () => {
		setOpenModalQuickSelect(false);
	};

	const { dataListPackagaSize, dataValuPackagaSizeDomestic } = useSelector((state) => state.DashboardListLogistics);

	const disabledPO = {
		pointerEvents: isDisablePO ? "none" : "unset",
		opacity: isDisablePO ? 0.7 : 1,
	};

	useEffect(() => {
		if (dataListPackagaSize?.length > 0) {
			const dataListPackagaSizeCustom = dataListPackagaSize.filter((item) => item.id != "");
			setRowsDataSelect(dataListPackagaSizeCustom);
		} else setRowsDataSelect([{ id: "", name: "", weight: "", sizeL: "", sizeW: "", sizeH: "", statusChecked: false }]);
	}, [dataListPackagaSize]);

	//set data
	useEffect(() => {
		if (dataValuPackagaSizeDomestic?.length > 0) {
			const dataCustom = dataValuPackagaSizeDomestic.map((item) => ({
				id: item.id ?? "",
				numberOfPackage: item.name,
				numPK: 1,
				weight: item.weight.toString(),
				length: item.sizeL.toString(),
				width: item.sizeW.toString(),
				height: item.sizeH.toString(),
				statusChecked: false,
			}));
			setRowsData(dataCustom);
		} else setRowsData([{ numberOfPackage: "", numPK: 1, weight: "", length: "", width: "", height: "", statusChecked: false }]);
	}, [dataValuPackagaSizeDomestic]);

	const addTableRows = useCallback(() => {
		const rowInput = { numberOfPackage: "", numPK: 1, weight: "", length: "", width: "", height: "", statusChecked: false };
		let newRowsData = rowsData?.length > 0 ? rowsData : [];
		setRowsData([...newRowsData, rowInput]);
		setAdding(true);
	}, [rowsData]);

	useEffect(() => {
		if (
			(rowsData[rowsData.length - 1]?.weight && rowsData[rowsData.length - 1]?.weight !== "") ||
			(rowsData[rowsData.length - 1]?.length && rowsData[rowsData.length - 1]?.length !== "") ||
			(rowsData[rowsData.length - 1]?.width && rowsData[rowsData.length - 1]?.width !== "") ||
			(rowsData[rowsData.length - 1]?.height && rowsData[rowsData.length - 1]?.height !== "")
		) {
			addTableRows();
		} else if (rowsData?.length == 0) {
			addTableRows();
		}
	}, [rowsData, addTableRows]);

	useEffect(() => {
		if (adding) {
			const element = document.getElementById(`list-product-scroll`);
			if (element) {
				element.scrollTop = element.scrollHeight;
			}
			setAdding(false);
		}
	}, [adding]);

	const deleteTableRows = (index) => {
		setRowsData((prevData) => [...prevData.slice(0, index), ...prevData.slice(index + 1, prevData.length)]);
	};

	//Get Product Code

	useEffect(() => {
		let delayDebounceFn = setTimeout(() => {
			// dispatch(receivingGetListOptionProductCode(inputValueSearchProductCode));
		}, 300);

		return () => clearTimeout(delayDebounceFn);
	}, [dispatch, inputValueSearchProductCode]);

	const handleChangeInputSearch = (value) => {
		setInputValueSearchProductCode(value);
	};

	const handleChange = (index, value, name, productModelId = "") => {
		setRowsData((prevData) => prevData.map((item, key) => (key === index ? { ...item, [name]: value } : item)));
	};

	const handleChangeNote = (index, value, name = "shortDescription") => {
		setRowsData((prevData) => prevData.map((item, key) => (key === index ? { ...item, [name]: value } : item)));
	};

	const handleChangeChecked = (index) => {
		const rowsInput = [...rowsData];
		if (rowsInput[index].status === "active") rowsInput[index].status = "inactive";
		else rowsInput[index].status = "active";
		setRowsData(rowsInput);
	};

	function splitArrayBasedOnNumPK(array) {
		const newArray = [];
		array.forEach((item) => {
			for (let i = 0; i < item.numPK; i++) {
				const newItem = { ...item, numPK: 1 };
				newArray.push(newItem);
			}
		});
		return newArray;
	}

	useEffect(() => {
		let newRowsData = [];
		if (rowsData?.length > 0) {
			rowsData.forEach((row) => {
				if (
					// typeof row?.numberOfPackage !== "undefined" &&
					// row.numberOfPackage !== "" &&
					typeof row?.width !== "undefined" &&
					row.width !== "" &&
					typeof row?.length !== "undefined" &&
					row.length !== "" &&
					typeof row?.height !== "undefined" &&
					row.height !== "" &&
					typeof row?.weight !== "undefined" &&
					row.weight !== ""
				) {
					newRowsData.push(row);
				}
			});
		}
		const splitArray = splitArrayBasedOnNumPK(newRowsData);
		const output_data = splitArray.map((item) => {
			const weight = {
				value: parseFloat(item.weight),
				units: "KG",
			};

			const dimensions = {
				length: parseFloat(item.length),
				width: parseFloat(item.width),
				height: parseFloat(item.height),
				units: "CM",
			};

			return {
				weight: weight,
				dimensions: dimensions,
				groupPackageCount: 1,
			};
		});
		setFieldValue("newRowsDataPackageInformation", rowsData);
		setFieldValue("requestedPackageLineItems", output_data);
	}, [rowsData, setFieldValue]);

	const handleRowDoubleClick = (data) => {
		const dataSearch = rowsData.find((item) => data.id === item.id);
		if (dataSearch) {
			return false;
		} else {
			const convertedObject = {
				id: data.id ?? "",
				numberOfPackage: data.name,
				numPK: 1,
				weight: data.weight.toString(), // Đã là string, lệnh .toString() không cần thiết nhưng có thể giữ lại cho nhất quán
				length: data.sizeL.toString(),
				width: data.sizeW.toString(),
				height: data.sizeH.toString(),
				statusChecked: false,
			};

			const checkHaveValue = rowsData.filter(
				(item) => item.numberOfPackage != "" || item.weight != "" || item.length != "" || item.width != "" || item.height != ""
			);
			let newRowsData = checkHaveValue.length > 0 ? [...checkHaveValue, convertedObject] : [convertedObject];
			setRowsData(newRowsData);
		}
	};

	const [searchPackage, setSearchPackage] = useState("");
	const handleSearch = () => {
		dispatch(getListPackageSize(currentPage, perPage, order, sortDirection, searchPackage));
	};

	return (
		<React.Fragment>
			<ModelQuickSelect isOpen={openModalQuickSelect} toggle={toggleQuickSelect} />
			<div id="list-product-scroll" className="pb-0 pt-0">
				<Row>
					<Col lg={6} className="">
						<fieldset className="border rounded-3">
							<legend className="float-none w-auto px-3 text-left fw-bold">Package Information</legend>
							<Row>
								<Col lg={7} className="ps-1 pe-0">
									<Table className="table-bordered align-middle table-wrap mb-0" size="sm">
										<thead style={{ ...disabledPO, position: "sticky", top: "-1px", zIndex: "1" }} className="table-light-add-row bg-theme-dark">
											<tr>
												<th
													scope="col"
													className="text-center"
													style={{
														background: "white",
														borderTop: "1px solid white",
														borderRight: "0px solid white",
														borderBottom: "1px solid white",
													}}></th>
												<th scope="col" className="text-center">
													Weight
												</th>
												<th scope="col" className="text-center">
													Length
												</th>
												<th scope="col" className="text-center">
													Width
												</th>
												<th scope="col" className="text-center">
													Height
												</th>
												<th
													scope="col"
													className="text-center"
													style={{
														background: "white",
														borderTop: "1px solid white",
														borderRight: "0px solid white",
														borderBottom: "1px solid white",
													}}></th>
											</tr>
										</thead>
										<tbody id="tbody-table">
											{rowsData.length > 0 ? (
												rowsData.map((data, index) => {
													return (
														<tr key={index}>
															<TableProductRows
																setFieldValue={setFieldValue}
																data={data}
																index={index}
																handleChange={handleChange}
																handleChangeChecked={handleChangeChecked}
																handleChangeNote={handleChangeNote}
																handleChangeInputSearch={handleChangeInputSearch}
																deleteTableRows={deleteTableRows}
																disabledPO={disabledPO}
																isIpadMode={isIpadMode}
																valuesParent={valuesParent}
																formikParent={formikParent}
																setIsDisableSubmit={setIsDisableSubmit}
																rowsDataLength={rowsData?.length ?? 0}
															/>
														</tr>
													);
												})
											) : (
												<tr>
													<td colSpan={7} className="text-center">
														<span>Already package all orders or no packages</span>
													</td>
												</tr>
											)}

											{touched.requestedPackageLineItems && errors.requestedPackageLineItems ? (
												<tr>
													<td colSpan={7} className="">
														<span className="error">{errors.requestedPackageLineItems}</span>
													</td>
												</tr>
											) : null}
											{touched.requestedPackageLineItemsError && errors.requestedPackageLineItemsError ? (
												<tr>
													<td colSpan={7} className="">
														<span className="error">{errors.requestedPackageLineItemsError}</span>
													</td>
												</tr>
											) : null}
										</tbody>
									</Table>
								</Col>
								<Col lg={5}>
									<div className="pb-2 border-bottom border-1 w-100 align-items-center justify-content-between">
										<InputComponent
											showButtonSearch
											type="search"
											className="form-control"
											placeholder={"Search..."}
											value={searchPackage}
											onChange={(value) => {
												setSearchPackage(value);
											}}
											onKeyDown={(keyEvent) => {
												if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
													setCurrentPage(1);
													handleSearch();
												}
											}}
											onClickSearch={() => {
												handleSearch();
												setCurrentPage(1);
											}}
										/>
									</div>
									<div
										className="border border-1"
										style={{
											height: "200px",
											overflowY: "scroll",
										}}>
										{rowsDataSelect.map((item, key) => {
											return (
												<React.Fragment key={key}>
													<div
														className=" align-items-center p-2 justify-content-between row-data-custom"
														onClick={() => {
															handleRowDoubleClick(item);
														}}
														// style={item?.id == rowData?.id && rowData?.id ? { backgroundColor: "aliceblue" } : null}
														key={key}>
														<div>
															<span className="font-weight-normal fs-12">{item?.name}</span>
														</div>
													</div>
													<hr className="p-0 m-0"></hr>
												</React.Fragment>
											);
										})}
									</div>
									<div style={{ ...disabledPO }} className="text-end">
										<Button
											className="mt-1 p-1"
											type="button"
											color="success"
											onClick={() => {
												setOpenModalQuickSelect(true);
											}}>
											Config
										</Button>
									</div>
								</Col>
							</Row>
						</fieldset>
					</Col>

					<Col lg={6} className="ps-0">
						<fieldset className="border rounded-3">
							<legend className="float-none w-auto px-3 text-left fw-bold">Commodities Information</legend>
							<CommoditiesInformation setFieldValue={setFieldValue} />
						</fieldset>
					</Col>
				</Row>
			</div>
		</React.Fragment>
	);
}

export default ListPackageTable;
