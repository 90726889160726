module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    // API_URL: "https://erp.danielvu.com", //server
    // API_URL: "https://api-node.themesbrand.website",
    // API_URL: "http://127.0.0.1:8000", //local
    // API_URL: "https://api.nswteam.net", //dev
    // API_URL: "", //server
    // API_URL: "https://erp.danielvu.com", //server
    // API_URL: "https://int.ipsupply.com.au"
    API_URL: process.env.REACT_APP_API_URL
  }
};