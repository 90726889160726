import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";
// Crypto Redux States
import { UPDATE_VALUE_SEARCH } from "./actionType";

function* onUpdateValueSearch({ payload: todo }) {
  
}
export function* watchGetValueSearch() {
  yield takeEvery(UPDATE_VALUE_SEARCH, onUpdateValueSearch);
}
function* dashboardValueSearchSaga() {
  yield all([fork(watchGetValueSearch)]);
}

export default dashboardValueSearchSaga;
