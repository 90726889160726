import favicon_old from "../assets/icon/favicon_old.ico";
import favicon from "../assets/icon/favicon.ico";

const dataIcon = {
	"/log-time": favicon,
	// "/log-time-management": favicon_old,
	default: favicon,
};

export default dataIcon;
