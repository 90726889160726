import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
// import * as XLSX from "xlsx";
// import * as FileSaver from "file-saver";

export const resizeFile = (
	file,
	maxWidth = 1600,
	maxHeight = 1200,
	compressFormat = "JPEG",
	quality = 100,
	rotation = 0,
	outputType = "base64",
	minWidth = 640,
	minHeight = 480
) => {
	const size = file.size / 1024 ** 2;
	let qualityBySize = quality;

	if (size > 100) qualityBySize = 75;
	if (size <= 100) qualityBySize = 80;
	if (size <= 50) qualityBySize = 85;
	if (size <= 30) qualityBySize = 90;
	if (size <= 10) qualityBySize = 95;
	// if (size <= 2) qualityBySize = 100;

	return new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			maxWidth,
			maxHeight,
			compressFormat,
			qualityBySize,
			rotation,
			(uri) => {
				resolve(uri);
			},
			outputType,
			minWidth,
			minHeight
		);
	});
};

export const dataURLtoFile = (dataurl, filename = "fileResize") => {
	const arr = dataurl.split(",");
	const mime = arr[0].match(/:(.*?);/)[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
};

export const handleCheckFile = (newFiles, allowed = /(\.jpg|\.jpeg|\.png)$/i) => {
	const storeFileArr = [];
	for (const file of newFiles) {
		if (allowed.exec(file.name)) {
			storeFileArr.push(file);
		} else {
			toast.error(`${file.name} is not in the correct format`, { autoClose: 3000 });
		}
	}
	return storeFileArr;
};

// const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
// const fileExtension = ".xlsx";

// export const handleExportExcel = (dataTable, fileName = "Excel file") => {
// 	const ws = XLSX.utils.json_to_sheet(dataTable);
// 	const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
// 	const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
// 	const data = new Blob([excelBuffer], { type: fileType });
// 	FileSaver.saveAs(data, `${fileName}` + fileExtension);
// };

export const toBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

export const getWidthHeightImage = (src = "") => {
	if (!src) return;
	return new Promise((resolve) => {
		const i = new Image();
		i.onload = () => {
			// console.log(i.width, i.height);
			const result = {
				height: i.height,
				width: i.width,
			};
			resolve(result);
		};
		i.src = src;
	});
};

export const getBase64URLSubmit = async (file, fileBase64Init, maxWidth = 2448, maxHeight = 1836) => {
	const maximumSize = maxWidth > maxHeight ? maxWidth : maxHeight;
	const minimumSize = maxWidth > maxHeight ? maxHeight : maxWidth;
	const size = file.size / 1024 ** 2;
	if (size <= 2) {
		return fileBase64Init;
	}
	let url = fileBase64Init;
	const result = await getWidthHeightImage(fileBase64Init);
	const { width, height } = result;
	if (width === height) {
		// height <= 1836 && width <= 2448
		if (height <= minimumSize && width <= minimumSize) {
			url = fileBase64Init;
		}
		// height > 1836 && width > 2448
		if (height > minimumSize && width > minimumSize) {
			url = await resizeFile(file, minimumSize, minimumSize);
		}
	}

	if (width > height) {
		// height <  1836 && width < 2448
		if (height < minimumSize && width < maximumSize) {
			url = fileBase64Init;
		}
		// height > 1836 && width < 2448
		if (height > minimumSize && width < maximumSize) {
			url = await resizeFile(file, width, minimumSize);
		}
		// height < 1836 && width > 2448
		if (height < minimumSize && width > maximumSize) {
			url = await resizeFile(file, maximumSize, height);
		}
		// height >  1836 && width > 2448
		if (height > minimumSize && width > maximumSize) {
			url = await resizeFile(file, maximumSize, minimumSize, "JPEG", 100, 0, "base64");
		}
	}
	if (width < height) {
		// height < 2448 && width < 1836
		if (height < maximumSize && width < minimumSize) {
			url = fileBase64Init;
		}
		// height > 2448 && width < 1836
		if (height > maximumSize && width < minimumSize) {
			url = await resizeFile(file, width, maximumSize);
		}
		// height < 2448 && width > 1836
		if (height < maximumSize && width > minimumSize) {
			url = await resizeFile(file, minimumSize, height);
		}
		// height > 2448 && width > 1836
		if (height > maximumSize && width > minimumSize) {
			url = await resizeFile(file, minimumSize, maximumSize, "JPEG", 100, 0, "base64");
		}
	}
	return url;
	//
};

//////////////////////

export const parseFileToHexString = (imgFile) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.onload = (event) => {
			const fileContent = event.target.result; // Nội dung của tệp
			// const fileType = file.type; // Loại tệp
			const uint8Array = new Uint8Array(fileContent).subarray(0, 4);
			// console.log("File buffer:", uint8Array);
			const hexaString = Array.from(uint8Array, (byte) => byte.toString(16).padStart(2, "0"))
				.join("")
				.substring(0, 8);
			resolve(hexaString);
			event.target.value = "";
		};
		reader.readAsArrayBuffer(imgFile);
	});
};

const checkMagicNumberIsImg = (magicNum) => {
	switch (magicNum) {
		case "89504e47":
			return "image/png";

		case "47494638":
			return "image/gif";

		case "ffd8ffe0":
		case "ffd8ffe1":
		case "ffd8ffe2":
		case "ffd8ffe3":
		case "ffd8ffe8":
			return "image/jpeg";
		default:
			return "";
	}
};
export const handleCheckValidImage = (fileArr) => {
	const imageFileArr = handleCheckFile(fileArr, /(\.jpg|\.jpeg|\.png)$/i);
	const validFileArr = Array.from(imageFileArr).reduce(async (arrPrev, imgFile) => {
		const arrResult = await arrPrev;
		const hexaString = await parseFileToHexString(imgFile);
		if (hexaString) {
			// const isOriginFileAsImg = await hexaString.startsWith(magicNumberForImg(imgFile.type));
			const isOriginFileAsImg = checkMagicNumberIsImg(hexaString);

			if (isOriginFileAsImg) arrResult?.push(imgFile);
			if (!isOriginFileAsImg) toast.error(`${imgFile.name} is a dangerous file that has been modified into an image."`, { autoClose: 3000 });

			return arrResult;
		}
	}, []);

	return validFileArr;
};
