import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

//import Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import RightSidebar from "../Components/Common/RightSidebar";
import useAuthUser from "../Components/Hooks/AuthUser";
//import actions
import {
	changeLayout,
	changeSidebarTheme,
	changeLayoutMode,
	changeLayoutWidth,
	changeLayoutPosition,
	changeTopbarTheme,
	changeLeftsidebarSizeType,
	changeLeftsidebarViewType,
	changeSidebarImageType,
	changePreLoader,
	changeLayoutConfig,
} from "../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { replaceIconFavicon } from "../Components/Hooks/Functions";
import { api } from "../config";

const Layout = (props) => {
	const [headerClass, setHeaderClass] = useState("");
	const currentUser = useAuthUser();
	const dispatch = useDispatch();
	const {
		layoutType,
		leftSidebarType,
		layoutModeType,
		layoutWidthType,
		layoutPositionType,
		topbarThemeType,
		leftsidbarSizeType,
		leftSidebarViewType,
		leftSidebarImageType,
		preloader,
	} = useSelector((state) => ({
		layoutType: state.Layout.layoutType,
		leftSidebarType: state.Layout.leftSidebarType,
		layoutModeType: state.Layout.layoutModeType,
		layoutWidthType: state.Layout.layoutWidthType,
		layoutPositionType: state.Layout.layoutPositionType,
		topbarThemeType: state.Layout.topbarThemeType,
		leftsidbarSizeType: state.Layout.leftsidbarSizeType,
		leftSidebarViewType: state.Layout.leftSidebarViewType,
		leftSidebarImageType: state.Layout.leftSidebarImageType,
		preloader: state.Layout.preloader,
	}));

	/*
    layout settings
    */
	useEffect(() => {
		if (
			layoutType ||
			leftSidebarType ||
			layoutModeType ||
			layoutWidthType ||
			layoutPositionType ||
			topbarThemeType ||
			leftsidbarSizeType ||
			leftSidebarViewType ||
			leftSidebarImageType ||
			preloader
		) {
			dispatch(changeLeftsidebarViewType(leftSidebarViewType));
			dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
			dispatch(changeSidebarTheme(leftSidebarType));
			dispatch(changeLayoutMode(layoutModeType));
			dispatch(changeLayoutWidth(layoutWidthType));
			dispatch(changeLayoutPosition(layoutPositionType));
			dispatch(changeTopbarTheme(topbarThemeType));
			dispatch(changeLayout(layoutType));
			dispatch(changeSidebarImageType(leftSidebarImageType));
			dispatch(changePreLoader(preloader));
		}
	}, [
		layoutType,
		leftSidebarType,
		layoutModeType,
		layoutWidthType,
		layoutPositionType,
		topbarThemeType,
		leftsidbarSizeType,
		leftSidebarViewType,
		leftSidebarImageType,
		preloader,
		dispatch,
	]);

	useEffect(() => {
		if (localStorage.getItem("config_template")) {
			let config_template = JSON.parse(localStorage.getItem("config_template"));
			if (Array.isArray(config_template)) {
				config_template.map((option) => {
					if (typeof option.layout !== "undefined") {
						dispatch(changeLayout(option.layout));
					}
					if (typeof option.color_scheme !== "undefined") {
						dispatch(changeLayoutMode(option.color_scheme));
					}
					if (typeof option.layout_width !== "undefined") {
						dispatch(changeLayoutWidth(option.layout_width));
					}
					if (typeof option.layout_position !== "undefined") {
						dispatch(changeLayoutPosition(option.layout_position));
					}
					if (typeof option.topbar_color !== "undefined") {
						dispatch(changeTopbarTheme(option.topbar_color));
					}

					if (typeof option.sidebar_size !== "undefined") {
						dispatch(changeLeftsidebarSizeType(option.sidebar_size));
					}
					if (typeof option.sidebar_view !== "undefined") {
						dispatch(changeLeftsidebarViewType(option.sidebar_view));
					}
					if (typeof option.sidebar_color !== "undefined") {
						dispatch(changeSidebarTheme(option.sidebar_color));
					}
					if (typeof option.sidebar_images !== "undefined") {
						dispatch(changeSidebarImageType(option.sidebar_images));
					}

					if (typeof option.preloader !== "undefined") {
						dispatch(changePreLoader(option.preloader));
					}
				});
			}
		}
	}, []);
	/*
    call dark/light mode
    */
	const onChangeLayoutMode = (value) => {
		if (changeLayoutMode) {
			dispatch(changeLayoutMode(value));
			dispatch(
				changeLayoutConfig(
					layoutType,
					value,
					layoutWidthType,
					layoutPositionType,
					topbarThemeType,
					leftsidbarSizeType,
					leftSidebarViewType,
					leftSidebarType,
					leftSidebarImageType,
					preloader
				)
			);
		}
	};

	// class add remove in header
	useEffect(() => {
		window.addEventListener("scroll", scrollNavigation, true);
	});
	function scrollNavigation() {
		var scrollup = document.documentElement.scrollTop;
		if (scrollup > 50) {
			setHeaderClass("topbar-shadow");
		} else {
			setHeaderClass("");
		}
	}

	useEffect(() => {
		replaceIconFavicon(currentUser.pathName);
	}, [currentUser.pathName]);

	const [apiVersion, setApiVersion] = useState("");

	useEffect(() => {
		const fetchApiVersion = async () => {
			try {
				const response = await fetch(api.API_URL + "/api/version");
				const data = await response.json();
				if (typeof data?.data?.name != "undefined") setApiVersion(data?.data?.name);
			} catch (error) {
				console.error("Error fetching API version:", error);
			}
		};

		fetchApiVersion();
	}, [currentUser.pathName]);

	useEffect(() => {
		if (apiVersion != "" && typeof apiVersion != "undefined") caching();
	}, [apiVersion]);

	const caching = () => {
		if (currentUser.version != apiVersion) {
			if ("caches" in window) {
				caches.keys().then((names) => {
					// Delete all the cache files
					names.forEach((name) => {
						caches.delete(name);
					});
				});
				// Makes sure the page reloads. Changes are only visible after you refresh.
				window.location.reload(true);
			}
			localStorage.setItem("version", apiVersion);
		}
	};

	const toogleMenuBtn = () => {
		var windowSize = document.documentElement.clientWidth;

		if (windowSize > 767) document.querySelector(".hamburger-icon").classList.toggle("open");

		//For collapse horizontal menu
		if (document.documentElement.getAttribute("data-layout") === "horizontal") {
			document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
		}

		//For collapse vertical menu
		if (document.documentElement.getAttribute("data-layout") === "vertical") {
			if (windowSize < 1380 && windowSize > 767) {
				document.body.classList.remove("vertical-sidebar-enable");
				document.documentElement.getAttribute("data-sidebar-size") === "sm"
					? document.documentElement.setAttribute("data-sidebar-size", "")
					: document.documentElement.setAttribute("data-sidebar-size", "sm");
			} else if (windowSize > 1380) {
				document.body.classList.remove("vertical-sidebar-enable");
				document.documentElement.getAttribute("data-sidebar-size") === "lg"
					? document.documentElement.setAttribute("data-sidebar-size", "sm")
					: document.documentElement.setAttribute("data-sidebar-size", "lg");
			} else if (windowSize <= 767) {
				document.body.classList.add("vertical-sidebar-enable");
				document.documentElement.setAttribute("data-sidebar-size", "lg");
			}
		}

		//Two column menu
		if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
			document.body.classList.contains("twocolumn-panel")
				? document.body.classList.remove("twocolumn-panel")
				: document.body.classList.add("twocolumn-panel");
		}
	};

	return (
		<React.Fragment>
			<div id="layout-wrapper">
				<Header headerClass={headerClass} layoutModeType={layoutModeType} onChangeLayoutMode={onChangeLayoutMode} toogleMenuBtn={toogleMenuBtn} />
				<Sidebar layoutType={layoutType} toogleMenuBtn={toogleMenuBtn} />
				<div className="main-content">
					{props.children}
					{/* <Footer /> */}
				</div>
			</div>
			{/* <RightSidebar /> */}
		</React.Fragment>
	);
};

Layout.propTypes = {
	children: PropTypes.object,
};

export default withRouter(Layout);
