import {
	SEARCH_VPN_GET_PRODUCT_PRICE_XERO,
	SEARCH_VPN_GET_PRODUCT_PRICE,
	SEARCH_VPN_GET_PRODUCT_PRICE_QUOTE_IN,
	SEARCH_VPN_RESPONSE_SUCCESS,
	SEARCH_VPN_RESPONSE_ERROR,
	SEARCH_VPN_GET_PRODUCT_ERP,
	SEARCH_VPN_REFRESH_LOADING,
	SEARCH_VPN_REFRESH_LOADING_LIST_SN,
	SEARCH_VPN_GET_XERO_INVOICE_BY_NUM,
	RESET_LIST_XERO_INVOICE_BY_NUM,
	GET_XERO_INVOICE_BY_NUM,
	RESET_XERO_INVOICE_BY_NUM,
	SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_AU,
	SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_US,
	SEARCH_VPN_GET_FILTER_CONTACT_NAME,
	SEARCH_VPN_GET_FILTER_INVOICE_DATE,
	SEARCH_VPN_GET_LIST_SERIAL_NUMBER_SEARCH_VPN,
	SEARCH_VPN_PRODUCT_ERP_UPDATE_SN_SUCCESS,
	SEARCH_VPN_CLEAR_IS_FETCH_LIST_PRODUCT_ERP,
	SEARCH_VPN_GET_SN_BY_ID,
	SEARCH_VPN_GET_LIST_POSITION,
	SEARCH_VPN_GET_LIST_ORGANIZATION,
	SEARCH_VPN_GET_LIST_STATUS,
	SEARCH_VPN_GET_LIST_MANAGEMENT,
	SEARCH_VPN_GET_LIST_SUPPLIER,
	SEARCH_VPN_GET_LIST_HASHTAGS,
	SEARCH_VPN_CACHE_QUICK_ADD_SUPPLIER,
	SEARCH_VPN_QUICK_ADD_SUPPLIER_FAIL,
	SEARCH_VPN_QUICK_ADD_SUPPLIER_SUCCESS,
	SEARCH_VPN_GET_FILTER_CONDITION,
	SEARCH_VPN_GET_WAREHOUSE,
	SEARCH_VPN_GET_PARAMS_TABLE_FOOTER_SEARCH,
	SEARCH_VPN_GET_WAREHOUSE_SEARCH,
	SEARCH_VPN_GET_PRODUCT_MODEL_CODE,
	SEARCH_VPN_GET_PRODUCT_MODEL_ID,
	SEARCH_VPN_GET_CONDITION_SEARCH,
	SEARCH_VPN_CLEAR_ALL_SEARCH_DATA,
	SEARCH_VPN_GET_LIST_MODEL,
	SEARCH_VPN_GET_LIST_EMAIL,
	SEARCH_VPN_CLEAR_DATA_EDIT_PRODUCT_ERP,
	SEARCH_VPN_CLEAR_DATA_EMAIL,
	SEARCH_VPN_GET_TEST_LOG_SERIAL_NUMBER_PRODUCT_ERP,
	SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP_SUCCESS,
	SEARCH_VPN_RESET_INIT_DATA_LOG_PRODUCT_ERP,
	SEARCH_VPN_GET_HISTORY_PRODUCT_ERP,
	SEARCH_VPN_GET_RECORD_LOGS_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_WAREHOUSE_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_CONDITION_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_MANAGEMENT_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_STATUS_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_HASHTAGS_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_SUPPLIER_PRODUCT_ERP,
	SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP_SUCCESS,
	SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP_FAIL,
	SEARCH_VPN_CLEAR_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP,
	SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP_SUCCESS,
	SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP_FAIL,
	SEARCH_VPN_CLEAR_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP,
	SEARCH_VPN_CLEAR_SEARCH_DATA_PRODUCT_ERP,
	SEARCH_VPN_GET_PRODUCT_MODEL_CODE_FILTER_PRODUCT_ERP,
	SEARCH_VPN_GET_PRODUCT_MODEL_CODE_ID_FILTER_PRODUCT_ERP,
	SEARCH_VPN_GET_CONDITION_FILTER_PRODUCT_ERP,
	SEARCH_VPN_GET_CONDITION_CODE_FILTER_PRODUCT_ERP,
	SEARCH_VPN_GET_WAREHOUSE_FILTER_PRODUCT_ERP,
	SEARCH_VPN_GET_ORGCODE_FILTER_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_USER_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_ORGANIZATION_PRODUCT_ERP,
	SEARCH_VPN_GET_LIST_POSITION_PRODUCT_ERP,
	SEARCH_VPN_GET_PRODUCT_PRICE_XERO_QUOTE_IN,
} from "./actionType";

const INIT_STATE = {
	dataProductPriceXero: [],
	dataProductPrice: [],
	dataProductPriceQuoteIn: [],
	dataProductPriceXeroQuoteIn: [],
	dataProductErp: [],
	dataAmountProductErpAu: [],
	dataAmountProductErpUs: [],
	loadingXero: false,
	loading: false,
	loadingQuoteIn: false,
	loadingErp: false,
	errorXero: null,
	error: null,
	errorQuoteIn: null,
	errorErp: null,

	//Detail
	dataListSN: [],
	totalRows: 0,
	loadingListSN: false,
	errorListSN: null,
	dataLogSN: [],

	//Xero invoice
	dataXeroInvoice: [],
	xeroNum: null,
	loadingListXeroInvoice: false,
	errorXeroInvoice: null,
	refreshLoading: false,

	contactNameFilter: "",
	invoiceDateFilter: "",
	datasearchVPNUpdateSNProductErp: {},
	isFetchListProductERP: false,
	isFetchListProductDetail: false,
	dataSNByID: {},
	dataListEmail: [],
	totalDataListEmail: 0,

	dataHistoryByID: [],
	dataRecordLogsByID: [],

	//getList
	listWarehouseErp: [],
	listConditionErp: [],
	listManagementErp: [],
	listStatusErp: [],
	listUserErp: [],
	listHashTagsErp: [],
	listSupplierErp: [],
	dataListAddNewSupplierFilter: null,
	dataListAddNewSupplierEdit: null,
	orgFilter: [],
	conditionFilter: [],
	productModelFilter: [],
	conditionCodeFilter: "",
	productModelIdFilter: "",
	orgCodeFilter: "",
	tableFooterSearch: {},
};

const DashboardSearchVPN = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SEARCH_VPN_RESPONSE_SUCCESS:
			switch (action.payload.actionType) {
				case SEARCH_VPN_GET_PRODUCT_PRICE_XERO:
					return {
						...state,
						dataProductPriceXero: action.payload.data,
						loadingXero: true,
						errorXero: null,
					};
				case SEARCH_VPN_GET_PRODUCT_PRICE:
					return {
						...state,
						dataProductPrice: action.payload.data,
						loading: true,
						error: null,
					};
				case SEARCH_VPN_GET_PRODUCT_PRICE_QUOTE_IN:
					return {
						...state,
						dataProductPriceQuoteIn: action.payload.data,
						loadingQuoteIn: true,
						errorQuoteIn: null,
					};
				case SEARCH_VPN_GET_PRODUCT_PRICE_XERO_QUOTE_IN:
					return {
						...state,
						dataProductPriceXeroQuoteIn: action.payload.data,
						loadingXeroQuoteIn: true,
						errorXeroQuoteIn: null,
					};
				case SEARCH_VPN_GET_PRODUCT_ERP:
					return {
						...state,
						dataProductErp: action.payload.data,
						loadingErp: true,
						errorErp: null,
					};

				case SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_AU:
					return {
						...state,
						dataAmountProductErpAu: action.payload.data,
						loadingErp: true,
						errorErp: null,
					};

				case SEARCH_VPN_GET_AMOUNT_PRODUCT_ERP_US:
					return {
						...state,
						dataAmountProductErpUs: action.payload.data,
						loadingErp: true,
						errorErp: null,
					};
				case SEARCH_VPN_GET_LIST_EMAIL:
					return {
						...state,
						dataListEmail: action.payload.data.data,
						totalDataListEmail: action.payload.data.total,
					};
				case SEARCH_VPN_GET_TEST_LOG_SERIAL_NUMBER_PRODUCT_ERP:
					return {
						...state,
						dataLogSN: action.payload.data.data,
					};

				//Detail
				case SEARCH_VPN_GET_LIST_SERIAL_NUMBER_SEARCH_VPN:
					return {
						...state,
						dataListSN: action.payload.data.data,
						totalRows: action.payload.data.total,
						loadingListSN: true,
						errorListSN: null,
					};
				case SEARCH_VPN_GET_XERO_INVOICE_BY_NUM:
					return {
						...state,
						dataXeroInvoice: action.payload.data,
						loadingListXeroInvoice: true,
					};
				//
				case SEARCH_VPN_GET_SN_BY_ID:
					return {
						...state,
						dataSNByID: action.payload.data.data,
					};
				// case SEARCH_VPN_GET_LIST_MODEL:
				// 	return {
				// 		...state,
				// 		listModel: action.payload.data,
				// 	};
				// case GET_MODEL:
				// 	return {
				// 		...state,
				// 		model: action.payload.data,
				// 	};
				// case SEARCH_VPN_GET_LIST_SUPPLIER:
				// 	return {
				// 		...state,
				// 		listSupplier: action.payload.data,
				// 	};

				// case SEARCH_VPN_GET_LIST_ORGANIZATION_PRODUCT_ERP:
				// 	return {
				// 		...state,
				// 		listOrganizationErp: action.payload.data.data,
				// 	};
				// case SEARCH_VPN_GET_LIST_POSITION_PRODUCT_ERP:
				// 	return {
				// 		...state,
				// 		listPositionErp: action.payload.data.data,
				// 	};
				case SEARCH_VPN_GET_LIST_USER_PRODUCT_ERP:
					return {
						...state,
						listUserErp: action.payload.data.data,
					};
				case SEARCH_VPN_GET_LIST_WAREHOUSE_PRODUCT_ERP:
					return {
						...state,
						listWarehouseErp: action.payload.data,
					};

				case SEARCH_VPN_GET_HISTORY_PRODUCT_ERP:
					return {
						...state,
						dataHistoryByID: action.payload.data.data,
					};
				case SEARCH_VPN_GET_RECORD_LOGS_PRODUCT_ERP:
					return {
						...state,
						dataRecordLogsByID: action.payload.data.data,
					};

				case SEARCH_VPN_GET_LIST_CONDITION_PRODUCT_ERP:
					return {
						...state,
						listConditionErp: action.payload.data,
					};
				case SEARCH_VPN_GET_LIST_MANAGEMENT_PRODUCT_ERP:
					return {
						...state,
						listManagementErp: action.payload.data,
					};
				case SEARCH_VPN_GET_LIST_STATUS_PRODUCT_ERP:
					return {
						...state,
						listStatusErp: action.payload.data,
					};
				case SEARCH_VPN_GET_LIST_HASHTAGS_PRODUCT_ERP:
					return {
						...state,
						listHashTagsErp: action.payload.data.data,
					};
				case SEARCH_VPN_GET_LIST_SUPPLIER_PRODUCT_ERP:
					return {
						...state,
						listSupplierErp: action.payload.data,
					};

				default:
					return state;
			}
		case SEARCH_VPN_RESPONSE_ERROR:
			switch (action.payload.actionType) {
				case SEARCH_VPN_GET_PRODUCT_PRICE_XERO:
					return {
						...state,
						errorXero: action.payload.error,
					};
				case SEARCH_VPN_GET_PRODUCT_PRICE:
					return {
						...state,
						error: action.payload.error,
					};
				case SEARCH_VPN_GET_PRODUCT_PRICE_QUOTE_IN:
					return {
						...state,
						errorQuoteIn: action.payload.error,
					};
				case SEARCH_VPN_GET_PRODUCT_ERP:
					return {
						...state,
						errorErp: action.payload.error,
					};
				case SEARCH_VPN_GET_LIST_SERIAL_NUMBER_SEARCH_VPN:
					return {
						...state,
						errorListSN: action.payload.error,
					};
				case SEARCH_VPN_GET_XERO_INVOICE_BY_NUM:
					return {
						...state,
						errorXeroInvoice: action.payload.error,
					};

				case SEARCH_VPN_GET_SN_BY_ID:
				case SEARCH_VPN_GET_LIST_WAREHOUSE_PRODUCT_ERP:
				case SEARCH_VPN_GET_LIST_CONDITION_PRODUCT_ERP:
				case SEARCH_VPN_GET_LIST_MANAGEMENT_PRODUCT_ERP:
				case SEARCH_VPN_GET_LIST_STATUS_PRODUCT_ERP:
				case SEARCH_VPN_GET_LIST_HASHTAGS_PRODUCT_ERP:
				case SEARCH_VPN_GET_LIST_SUPPLIER_PRODUCT_ERP:
				case SEARCH_VPN_GET_LIST_POSITION_PRODUCT_ERP:
					return {
						...state,
						error: action.payload.error,
					};
				default:
					return state;
			}
		case SEARCH_VPN_REFRESH_LOADING_LIST_SN:
			return {
				...state,
				//dataListSN: [],
				loadingListSN: false,
			};
		case GET_XERO_INVOICE_BY_NUM:
			return {
				...state,
				xeroNum: action.payload,
				refreshLoading: !state.refreshLoading,
			};
		case RESET_XERO_INVOICE_BY_NUM:
			return {
				...state,
				xeroNum: null,
			};
		case RESET_LIST_XERO_INVOICE_BY_NUM:
			return {
				...state,
				dataXeroInvoice: [],
				loadingListXeroInvoice: false,
			};
		case SEARCH_VPN_GET_FILTER_CONTACT_NAME:
			return {
				...state,
				contactNameFilter: action.payload.search,
			};
		case SEARCH_VPN_GET_FILTER_INVOICE_DATE:
			return {
				...state,
				invoiceDateFilter: action.payload.search,
			};
		case SEARCH_VPN_PRODUCT_ERP_UPDATE_SN_SUCCESS:
			return {
				...state,
				dataListSN: state.dataListSN?.map((i) => (i.id === action.payload.data?.id ? action.payload.data : i)),
				isFetchListProductERP: true,
				isFetchListProductDetail: true,
			};
		case SEARCH_VPN_CLEAR_IS_FETCH_LIST_PRODUCT_ERP:
			return {
				...state,
				isFetchListProductERP: false,
				isFetchListProductDetail: false,
			};
		case SEARCH_VPN_GET_PRODUCT_MODEL_ID:
			return {
				...state,
				productModelId: action.payload,
				isClear: false,
			};
		case SEARCH_VPN_GET_PRODUCT_MODEL_CODE:
			return {
				...state,
				productModelCode: action.payload,
				isClear: false,
			};

		case SEARCH_VPN_GET_CONDITION_SEARCH:
			return {
				...state,
				condition: action.payload,
				isClear: false,
			};

		case SEARCH_VPN_GET_PARAMS_TABLE_FOOTER_SEARCH:
			return {
				...state,
				tableFooterSearch: action.payload,
			};

		case SEARCH_VPN_CLEAR_ALL_SEARCH_DATA:
			return {
				...state,
				productModelCode: null,
				searchTextInput: "",
				productModelId: "",
				supplierId: "",
				orgCodeFilter: "",
				condition: "",
				managementKind: [],
				status: [],
				dateIn: "",
				updatedAt: "",
				isClear: true,
				tableFooterSearch: {},
				allParamsSearch: {},
				wareHouseFilter: [],
				conditionFilter: [],
			};
		case SEARCH_VPN_GET_WAREHOUSE:
			return {
				...state,
				wareHouseFilter: action.payload.search,
			};
		// case SEARCH_VPN_GET_FILTER_CONDITION:
		// 	return {
		// 		...state,
		// 		conditionFilter: action.payload.search,
		// 	};

		case SEARCH_VPN_CLEAR_DATA_EDIT_PRODUCT_ERP:
			return {
				...state,
				dataSNByID: {},
			};
		case SEARCH_VPN_CLEAR_DATA_EMAIL: {
			return {
				...state,
				dataListEmail: [],
				totalDataListEmail: 0,
			};
		}
		case SEARCH_VPN_GET_LOG_TEXT_SERIAL_NUMBER_PRODUCT_ERP_SUCCESS:
			return {
				...state,
				logTextSN: action.payload.content,
			};

		case SEARCH_VPN_RESET_INIT_DATA_LOG_PRODUCT_ERP:
			return {
				...state,
				logTextSN: "",
				dataLogSN: [],
			};

		case SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP_SUCCESS:
			return {
				...state,
				dataListAddNewSupplierFilter: action.payload.data,
			};

		case SEARCH_VPN_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP_FAIL:
			return {
				...state,
				error: action.payload.error,
			};
		case SEARCH_VPN_CLEAR_QUICK_ADD_SUPPLIER_FILTER_PRODUCT_ERP:
			return {
				...state,
				dataListAddNewSupplierFilter: {},
			};

		case SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP_SUCCESS:
			return {
				...state,
				dataListAddNewSupplierEdit: action.payload.data,
			};

		case SEARCH_VPN_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP_FAIL:
			return {
				...state,
				error: action.payload.error,
			};
		case SEARCH_VPN_CLEAR_QUICK_ADD_SUPPLIER_EDIT_PRODUCT_ERP:
			return {
				...state,
				dataListAddNewSupplierEdit: {},
			};
		case SEARCH_VPN_GET_PRODUCT_MODEL_CODE_FILTER_PRODUCT_ERP:
			return {
				...state,
				productModelFilter: action.payload,
			};
		case SEARCH_VPN_GET_PRODUCT_MODEL_CODE_ID_FILTER_PRODUCT_ERP:
			return {
				...state,
				productModelIdFilter: action.payload,
			};
		case SEARCH_VPN_GET_CONDITION_CODE_FILTER_PRODUCT_ERP:
			return {
				...state,
				conditionCodeFilter: action.payload,
			};
		case SEARCH_VPN_GET_CONDITION_FILTER_PRODUCT_ERP:
			return {
				...state,
				conditionFilter: action.payload.search,
			};
		case SEARCH_VPN_GET_WAREHOUSE_FILTER_PRODUCT_ERP:
			return {
				...state,
				orgFilter: action.payload.search,
			};
		case SEARCH_VPN_GET_ORGCODE_FILTER_PRODUCT_ERP:
			return {
				...state,
				orgCodeFilter: action.payload,
				isClear: false,
			};
		case SEARCH_VPN_CLEAR_SEARCH_DATA_PRODUCT_ERP:
			return {
				...state,
				productModelCode: null,
				searchTextInput: "",
				productModelId: "",
				supplierId: "",
				orgCode: "",
				condition: "",
				managementKind: [],
				status: [],
				dateIn: "",
				updatedAt: "",
				isClear: true,
				tableFooterSearch: {},
				allParamsSearch: {},
				orgFilter: null,
				conditionFilter: null,
				productModelFilter: null,
				conditionCodeFilter: "",
				productModelIdFilter: "",
				dataListSN: [],
				orgCodeFilter: "",
			};
		case SEARCH_VPN_REFRESH_LOADING:
			return {
				...state,
				dataProductPriceXero: [],
				dataProductPrice: [],
				dataProductPriceQuoteIn: [],
				dataProductPriceXeroQuoteIn: [],
				dataProductErp: [],
				dataAmountProductErpAu: [],
				dataAmountProductErpUs: [],
				dataListEmail: [],
				totalDataListEmail: 0,
				loadingXero: false,
				loading: false,
				loadingQuoteIn: false,
				loadingErp: false,
				errorXero: null,
				error: null,
				errorQuoteIn: null,
				errorErp: null,
				dataListSN: [],
				loadingListSN: false,
				dataHistoryByID: [],
				dataRecordLogsByID: [],
			};
		default:
			return state;
	}
};
export default DashboardSearchVPN;
