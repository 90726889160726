import React from "react";
import PropTypes from "prop-types";

const useCurrentDateTime = () => {
	const date = new Date();
	const getHours = date.getHours();
	const getMinutes = date.getMinutes();
	const getSeconds = date.getSeconds();
	return {
		date,
		getHours,
		getMinutes,
		getSeconds,
	};
};

export default useCurrentDateTime;
