import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { Input } from "reactstrap";

//SimpleBar
import SimpleBar from "simplebar-react";

import { clearCacheValueSearch, updateValueSearch } from "../../store/actions";
import useAuthUser from "../Hooks/AuthUser";
import InputComponent from "../Hooks/InputComponent";

const SearchOption = () => {
	const dispatch = useDispatch();
	const [value, setValue] = useState("");
	const [changeRouteByMenu, setChangeRouteByMenu] = useState(false);
	const [placeholder, setPlaceholder] = useState("Search...");
	const searchParams = new URLSearchParams(window.location.search);
	const history = useHistory();
	const location = useLocation();

	const { arrayMenuNotSearch, arrayPageDetail, arrayPage } = useAuthUser();

	useEffect(() => {
		const search = searchParams?.get("search");
		if (search !== "" && search !== null) {
			setValue(search);
			dispatch(updateValueSearch(search.trim()));
		}
	}, []);

	useEffect(() => {
		history.listen((h) => {
			if (h.pathname === location?.pathname) {
				return;
			}
		});
		const search = searchParams?.get("search");
		if (!changeRouteByMenu && (search === "" || search === null)) {
			setValue("");
			dispatch(clearCacheValueSearch());
			// const checkPreviousURL = arrayMenuNotSearch.findIndex((i) => {
			// 	return i === location?.pathname?.toString();
			// });
			// if (checkPreviousURL >= 0) {
			// 	dispatch(clearCacheValueSearch());
			// } else {
			// 	dispatch(clearCacheValueSearchByURLSearch());
			// }
		} else {
			setChangeRouteByMenu(false);
		}
	}, [dispatch, history, location.pathname]);

	const onChangeData = (value) => {
		setValue(value);
	};

	const handleClickSearch = () => {
		dispatch(updateValueSearch(value.trim()));
		if (arrayMenuNotSearch.includes(window.location.pathname)) {
			arrayPageDetail.map((item, idx) => {
				if (idx == 0) {
					history.push(history.push(item.menuPage));
				}
			});
		}
	};

	const handleClickFunction = () => {
		setChangeRouteByMenu(true);
		dispatch(updateValueSearch(value.trim()));
	};

	const hanldeDownSearch = (e) => {
		// if (e.key === "Enter" || e.keyCode === 13 || e.keyCode === 191 || e.key === "/") {
		if (e.key === "Enter" || e.keyCode === 13) {
			dispatch(updateValueSearch(value.trim()));
			var dropdown = document.getElementById("search-dropdown");
			dropdown.classList.remove("show");
			if (arrayMenuNotSearch.includes(window.location.pathname)) {
				arrayPageDetail.map((item, idx) => {
					if (idx == 0) {
						history.push(history.push(item.menuPage));
					}
				});
			}
		}
	};

	const handleKeyPress = (e) => {
		if (e.target.tagName == "BODY" && e.key == "/") {
			var searchInput = document.getElementById("search-options");
			var dropdown = document.getElementById("search-dropdown");
			searchInput.focus();
			dropdown.classList.add("show");
		}
	};

	useEffect(() => {
		window.addEventListener("keyup", handleKeyPress, false);
		return () => {
			window.removeEventListener("keyup", handleKeyPress);
		};
	}, []);

	useEffect(() => {
		var searchOptions = document.getElementById("search-close-options");
		var dropdown = document.getElementById("search-dropdown");
		var searchInput = document.getElementById("search-options");

		// searchInput.addEventListener("focus", function () {
		var inputLength = searchInput.value.length;
		if (inputLength > 0) {
			// dropdown.classList.add("show");
			searchOptions.classList.remove("d-none");
		} else {
			// dropdown.classList.remove("show");
			searchOptions.classList.add("d-none");
		}
		// });
		searchInput.addEventListener("click", function () {
			dropdown.classList.add("show");
		});

		searchInput.addEventListener("keyup", function () {
			var inputLength = searchInput.value.length;
			if (inputLength > 0) {
				// dropdown.classList.add("show");
				searchOptions.classList.remove("d-none");
			} else {
				// dropdown.classList.remove("show");
				searchOptions.classList.add("d-none");
			}
		});

		searchOptions.addEventListener("click", function () {
			setValue("");
			dropdown.classList.remove("show");
			searchOptions.classList.add("d-none");
			handleClickFunction();
		});

		document.body.addEventListener("click", function (e) {
			if (e.target.getAttribute("id") !== "search-options") {
				dropdown.classList.remove("show");
				// searchOptions.classList.add("d-none");
			}
		});
	}, []);

	useEffect(() => {
		var ele = document.getElementsByClassName("notify-item");
		for (var i = 0; i < ele.length; i++) {
			ele[i].style.background = "none";
		}

		if (document.getElementById(window.location.pathname) !== null) {
			document.getElementById(window.location.pathname).style.background = "#f3f3f9";
		} else {
			arrayPageDetail.map((item, idx) => {
				if (idx == 0) {
					document.getElementById(item.menuPage).style.background = "#f3f3f9";
				}
			});
		}
		// let pathname = window.location.pathname;
		// pathname = pathname.replace("/add", "");
		// pathname = pathname.replace("/edit", "");
		// pathname = pathname.replace("/detail", "");
		// if (!arrayPage.includes(pathname) && pathname != "/auth-404-basic" && pathname != "/login") {
		//   if (pathname == "/page-start") {
		//     arrayPage.map((item, idx) => {
		//       if (idx == 0) {
		//         history.push(item);
		//       }
		//     });
		//   } else {
		//     history.push("/auth-404-basic");
		//   }
		// }

		arrayPageDetail.map((menu, idx) => {
			if (arrayMenuNotSearch.includes(window.location.pathname)) {
				if (idx == 0) {
					setPlaceholder("Press / to search " + menu.menuName);
				}
			} else {
				if (menu.menuPage == window.location.pathname) {
					setPlaceholder("Press / to search " + menu.menuName);
				}
			}
		});
	}, [window.location.pathname, arrayPage, history]);

	return (
		<React.Fragment>
			<div className="app-search d-none d-md-block">
				<div className="position-relative input-search-option">
					<InputComponent
						type="text"
						className="form-control"
						placeholder={placeholder}
						id="search-options"
						value={value}
						autoComplete="off"
						onChange={(e) => {
							onChangeData(e);
						}}
						onKeyDown={hanldeDownSearch}
					/>
					<span className="mdi mdi-magnify search-widget-icon" onClick={handleClickSearch}></span>
					<span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options"></span>
				</div>
				<div className="dropdown-menu dropdown-menu-lg" id="search-dropdown">
					<SimpleBar style={{ height: "320px" }}>
						{arrayPageDetail.map((menu, keyMenu) => {
							if (arrayMenuNotSearch.includes(menu.menuPage)) {
								return (
									<React.Fragment key={keyMenu}>
										<span id={menu.menuPage}></span>
									</React.Fragment>
								);
							} else {
								return (
									<React.Fragment key={keyMenu}>
										<Link
											id={menu.menuPage}
											to={menu.menuPage + "?search=" + value.trim()}
											className="dropdown-item notify-item item-option-search-total"
											onClick={handleClickFunction}>
											<span>{menu.menuName}</span>
										</Link>
									</React.Fragment>
								);
							}
						})}

						{/* {arrayPageDetail.map((module, keyModule) => {
              let icon = "";
              if (module.moduleCode == "ERPS") {
                icon = "ri ri-tools-fill";
              } else if (module.moduleCode == "TOOLS") {
                icon = "ri ri-ie-fill";
              } else if (module.moduleCode == "users") {
                icon = "ri ri-user-line";
              }
              return (
                <React.Fragment key={keyModule}>
                  <div className="dropdown-header">
                    <h6 className="text-overflow text-muted mb-0 text-uppercase">
                      <i className={" align-middle fs-18 text-muted me-2 " + icon}></i>
                      {module.moduleName}
                    </h6>
                  </div>
                  {module.menu.map((menu, keyMenu) => {
                    return (
                      <React.Fragment key={keyMenu}>
                        <Link id={menu.menuPage} to={menu.menuPage} className="dropdown-item notify-item" onClick={handleClickFunction}>
                          <span>{menu.menuName}</span>
                        </Link>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })} */}
					</SimpleBar>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SearchOption;
