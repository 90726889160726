import { dateFormatString10, dateFormatString9 } from "../Common/FormatTime";
import moment from "moment";
import useCurrentDateTime from "./CurrentDateTime";

const useFormatDate = () => {
	const { getHours, getMinutes, getSeconds } = useCurrentDateTime();

	const FormatDateGMT = (date = "", formatDate = dateFormatString9) => {
		const dateTime = date !== "" ? new Date(date) : new Date();
		// Convert dateTime to GMT string
		const gmtString = dateTime.toLocaleString("en-US", { timeZone: "GMT" });
		return moment(gmtString).format(formatDate);
	};

	const FormatDateGMTNotTimePicker = (date = "", formatDate = dateFormatString10) => {
		const currentDate = new Date();
		const getHours = currentDate.getHours();
		const getMinutes = currentDate.getMinutes();
		const getSeconds = currentDate.getSeconds();
		const localDate = date ? `${moment(date).format("YYYY/MM/DD")} ${getHours}:${getMinutes}:${getSeconds}` : "";
		return localDate ? FormatDateGMT(localDate, formatDate) : "";
	};

	const FormatDateHasZoneTime = (date = "") => {
		const currentDate = new Date();
		const getHours = currentDate.getHours();
		const getMinutes = currentDate.getMinutes();

		const localDate = date ? `${moment(date).format("YYYY/MMM/DD")}, ${getHours}:${getMinutes}` : null;
		return localDate;
	};

	return {
		FormatDateGMTNotTimePicker,
		FormatDateGMT,
		FormatDateHasZoneTime,
	};
};

export default useFormatDate;
