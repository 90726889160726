import React, { useState, useEffect, memo, useMemo, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableProductRows from "./TableCommoditiesRows";
import { Button, Col, Row, Table, Input, FormFeedback } from "reactstrap";

function ListCommoditiesTable({ setFieldValue, isDisablePO = false, touched, errors, values }) {
	const dispatch = useDispatch();

	const [rowsData, setRowsData] = useState([]);
	const [adding, setAdding] = useState(false);

	const disabledPO = {
		pointerEvents: isDisablePO ? "none" : "unset",
		opacity: isDisablePO ? 0.7 : 1,
	};

	// //set data
	// useEffect(() => {
	// 	if (dataPackagePOByID?.packagePoDetails?.length > 0) {
	// 		let packagePoDetails = [...dataPackagePOByID.packagePoDetails];
	// 		setRowsData(packagePoDetails);
	// 	} else setRowsData([{ description: "", units: "", weight: "", measures: "", width: "", height: "", customsValue: "", unitValue: "" }]);
	// }, [dataPackagePOByID]);
	useEffect(() => {
		setRowsData([{ description: "", units: "", weight: "", measures: "", width: "", height: "", customsValue: "", unitValue: "" }]);
	}, []);

	useEffect(() => {
		if (values?.requestedPackageLineItems) {
			if (values?.requestedPackageLineItems.length > 0) {
				const height = values?.requestedPackageLineItems[0].dimensions?.height;
				const length = values?.requestedPackageLineItems[0].dimensions?.length;
				const units = values?.requestedPackageLineItems[0].dimensions?.units;
				const width = values?.requestedPackageLineItems[0].dimensions?.width;
				const weight = values?.requestedPackageLineItems[0].weight?.value;
				setRowsData([
					{
						description: "Computer Hardware",
						units: values?.requestedPackageLineItems.length ?? "",
						weight: weight,
						measures: "PCS",
						width: width,
						height: height,
						customsValue: "",
						unitValue: "",
					},
				]);
			}
		}
	}, [values?.requestedPackageLineItems]);

	const addTableRows = useCallback(() => {
		const rowInput = { description: "", units: "", weight: "", measures: "", width: "", height: "", customsValue: "", unitValue: "" };
		let newRowsData = rowsData?.length > 0 ? rowsData : [];
		setRowsData([...newRowsData, rowInput]);
		setAdding(true);
	}, [rowsData]);

	useEffect(() => {
		if (
			(rowsData[rowsData.length - 1]?.description && rowsData[rowsData.length - 1]?.description !== "") ||
			(rowsData[rowsData.length - 1]?.units && rowsData[rowsData.length - 1]?.units !== "") ||
			(rowsData[rowsData.length - 1]?.weight && rowsData[rowsData.length - 1]?.weight !== "") ||
			(rowsData[rowsData.length - 1]?.measures && rowsData[rowsData.length - 1]?.measures !== "") ||
			(rowsData[rowsData.length - 1]?.width && rowsData[rowsData.length - 1]?.width !== "") ||
			(rowsData[rowsData.length - 1]?.height && rowsData[rowsData.length - 1]?.height !== "") ||
			(rowsData[rowsData.length - 1]?.customsValue && rowsData[rowsData.length - 1]?.customsValue !== "") ||
			(rowsData[rowsData.length - 1]?.unitValue && rowsData[rowsData.length - 1]?.unitValue !== "")
		) {
			addTableRows();
		}
	}, [rowsData, addTableRows]);

	useEffect(() => {
		if (adding) {
			const element = document.getElementById(`list-product-scroll`);
			if (element) {
				element.scrollTop = element.scrollHeight;
			}
			setAdding(false);
		}
	}, [adding]);

	const deleteTableRows = (index) => {
		setRowsData((prevData) => [...prevData.slice(0, index), ...prevData.slice(index + 1, prevData.length)]);
	};

	const handleChange = (index, value, name, productModelId = "") => {
		setRowsData((prevData) => prevData.map((item, key) => (key === index ? { ...item, [name]: value } : item)));
	};

	useEffect(() => {
		let newRowsData = [];
		if (rowsData?.length > 0) {
			rowsData.forEach((row) => {
				if (
					(typeof row?.description !== "undefined" && row.description !== "") ||
					(typeof row?.weight !== "undefined" && row.weight !== "") ||
					(typeof row?.unitValue !== "undefined" && row.unitValue !== "") ||
					(typeof row?.units !== "undefined" && row.units !== "") ||
					(typeof row?.customsValue !== "undefined" && row.customsValue !== "")
				) {
					newRowsData.push(row);
				}
			});
		}

		const output_data = newRowsData.map((item) => {
			const weight = {
				value: parseFloat(item.weight),
				units: "KG",
			};
			const unitPrice = {
				amount: Number(item.unitValue),
				currency: "USD",
			};
			const customsValue = {
				amount: Number(item.customsValue),
				currency: "USD",
			};
			return {
				description: item.description,
				// countryOfManufacture: "US",
				quantity: Number(item.units),
				quantityUnits: "PCS", //default
				unitPrice: unitPrice,
				customsValue: customsValue,
				weight: weight,
			};
		});
		setFieldValue("commodities", output_data);
		setFieldValue("commoditiesData", newRowsData);
	}, [rowsData, setFieldValue]);

	return (
		<React.Fragment>
			<div id="list-product-scroll" className="pe-3 ps-3 pb-0 pt-0">
				<Row>
					<Table className="table-bordered align-middle table-wrap mb-0" size="sm">
						<thead style={{ ...disabledPO, position: "sticky", top: "-1px", zIndex: "1" }} className="table-light-add-row bg-theme-dark">
							<tr>
								<th scope="col" className="text-center">
									Description
								</th>
								<th scope="col" className="text-center" style={{ width: "100px" }}>
									No. of<br></br>Units
								</th>
								<th scope="col" className="text-center" style={{ width: "80px" }}>
									Weight<br></br>(Kg)
								</th>
								<th scope="col" className="text-center" style={{ width: "160px" }}>
									Unit of<br></br>Measures
								</th>
								<th scope="col" className="text-center" style={{ width: "80px" }}>
									Width<br></br>(Cm)
								</th>
								<th scope="col" className="text-center" style={{ width: "80px" }}>
									Height<br></br>(Cm)
								</th>
								<th scope="col" className="text-center" style={{ width: "100px" }}>
									Customs Value<br></br>(USD)
								</th>
								<th scope="col" className="text-center" style={{ width: "100px" }}>
									Unit Value<br></br>(USD)
								</th>
							</tr>
						</thead>
						<tbody id="tbody-table">
							{rowsData.length > 0 ? (
								rowsData.map((data, index) => {
									return (
										<tr key={index}>
											<TableProductRows
												data={data}
												index={index}
												handleChange={handleChange}
												deleteTableRows={deleteTableRows}
												disabledPO={disabledPO}
											/>
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan={7} className="text-center">
										<span>Already package all orders or no packages</span>
									</td>
								</tr>
							)}

							{touched.commodities && errors.commodities ? (
								<tr>
									<td colSpan={7} className="">
										<span className="error">{errors.commodities}</span>
									</td>
								</tr>
							) : null}
						</tbody>
					</Table>
				</Row>
			</div>
			{/* <Row className="text-center">
				<div style={{ ...disabledPO }}>
					<Button
						className="mt-1 p-1"
						type="button"
						color="success"
						onClick={() => {
							// const element = document.getElementById(`list-product-scroll`);
							// if (element) {
							// 	element.scrollTop = element.scrollHeight + 100;
							// }
							addTableRows();
						}}>
						Add more
					</Button>
				</div>
			</Row> */}
		</React.Fragment>
	);
}

export default ListCommoditiesTable;
