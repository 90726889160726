import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Calendar
import Calendar from "./calendar/reducer";

//Ecommerce
import Ecommerce from "./ecommerce/reducer";

//Form advanced
import changeNumber from "./formAdvanced/reducer";

// Dashboard Analytics
import DashboardAnalytics from "./dashboardAnalytics/reducer";

// Dashboard CRM
import DashboardCRM from "./dashboardCRM/reducer";

// Dashboard Ecommerce
import DashboardEcommerce from "./dashboardEcommerce/reducer";

// Dashboard Cryto
import DashboardCrypto from "./dashboardCrypto/reducer";

// Dashboard Cryto
import DashboardProject from "./dashboardProject/reducer";

// Dashboard NFT
import DashboardNFT from "./dashboardNFT/reducer";

// File Manager
import FileManager from "./fileManager/reducer";

// Search VPN
import DashboardSearchVPN from "./searchVPN/reducer";

//SearchOption
import DashboardSearchOption from "./searchOption/reducer";

//Master data
import DashboardMasterData from "./masterData/reducer";

//Category
import DashboardListCategory from "./category/reducer";

import DashboardListMails from "./listMails/reducer";

//Data Filter Search
import DashboardDataMasterFilter from "./masterDataFilter/reducer";

import DashboardListLogistics from "./logistics/reducer";

const rootReducer = combineReducers({
	// public
	Layout,
	Login,
	Account,
	ForgetPassword,
	Profile,
	Calendar,
	Ecommerce,
	changeNumber,
	DashboardAnalytics,
	DashboardCRM,
	DashboardEcommerce,
	DashboardCrypto,
	DashboardProject,
	DashboardNFT,
	FileManager,
	DashboardSearchVPN,
	DashboardSearchOption,
	DashboardMasterData,
	DashboardDataMasterFilter,
	DashboardListCategory,
	DashboardListMails,
	DashboardListLogistics,
});

export default rootReducer;
