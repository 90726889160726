import {
	API_RESPONSE_ERROR,
	API_RESPONSE_SUCCESS,
	CATEGORY_ADD_CATEGORY_FAIL,
	CATEGORY_ADD_CATEGORY_SUCCESS,
	CATEGORY_API_GET_LIST,
	CATEGORY_CLEAR_CACHE,
	CATEGORY_CLEAR_CACHE_ACTION,
	CATEGORY_GET_DATA_CATEGORY_BY_ID,
	CATEGORY_GET_LIST_CATEGORY,
	CATEGORY_GET_LIST_CATEGORY_FILTER,
	// CATEGORY_GET_LIST_MANUFACTOR,
	CATEGORY_UPDATE_CATEGORY_FAIL,
	CATEGORY_UPDATE_CATEGORY_SUCCESS,
	DELETE_CATEGORY_FAIL,
	DELETE_CATEGORY_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
	dataListCategory: [],
	// datalistManufactor: [],
	datalistCmbCategory: [],
	datalistCmbCategoryFilter: [],
	totalListCategory: 0,
	loadingPage: false,
	error: {},
};

const DashboardListCategory = (state = INIT_STATE, action) => {
	switch (action.type) {
		case API_RESPONSE_SUCCESS:
			switch (action.payload.actionType) {
				case CATEGORY_API_GET_LIST:
					return {
						...state,
						totalListCategory: action.payload.data.total,
						dataListCategory: action.payload.data.data,
					};
				// case CATEGORY_GET_LIST_MANUFACTOR:
				// 	return {
				// 		...state,
				// 		datalistManufactor: action.payload.data.data,
				// 	};
				case CATEGORY_GET_LIST_CATEGORY:
					return {
						...state,
						datalistCmbCategory: action.payload.data.data,
					};
				case CATEGORY_GET_LIST_CATEGORY_FILTER:
					return {
						...state,
						datalistCmbCategoryFilter: action.payload.data.data,
					};
				case CATEGORY_GET_DATA_CATEGORY_BY_ID:
					return {
						...state,
						dataCategoryByID: action.payload.data.data,
					};
				default:
					return state;
			}
		case API_RESPONSE_ERROR:
			switch (action.payload.actionType) {
				case CATEGORY_API_GET_LIST:
					return {
						...state,
						error: action.payload,
					};
				// case CATEGORY_GET_LIST_MANUFACTOR:
				// 	return {
				// 		...state,
				// 		error: action.payload,
				// 	};
				case CATEGORY_GET_LIST_CATEGORY:
					return {
						...state,
						error: action.payload,
					};
				case CATEGORY_GET_LIST_CATEGORY_FILTER:
					return {
						...state,
						error: action.payload,
					};
				case CATEGORY_GET_DATA_CATEGORY_BY_ID:
					return {
						...state,
						error: action.payload,
					};
				default:
					return state;
			}
		case DELETE_CATEGORY_SUCCESS:
			return {
				...state,
				loadingPage: !state.loadingPage,
			};

		case DELETE_CATEGORY_FAIL:
			return {
				...state,
				error: action.payload,
			};
		case CATEGORY_ADD_CATEGORY_SUCCESS:
			return {
				...state,
				isAddCategorySuccess: true,
				isAddCategoryFail: false,
				loadingPage: !state.loadingPage,
			};

		case CATEGORY_ADD_CATEGORY_FAIL:
			return {
				...state,
				error: action.payload.error,
				isAddCategorySuccess: false,
				isAddCategoryFail: true,
			};

		case CATEGORY_UPDATE_CATEGORY_SUCCESS:
			return {
				...state,
				isUpdateCategorySuccess: true,
				isUpdateCategoryFail: false,
				loadingPage: !state.loadingPage,
			};

		case CATEGORY_UPDATE_CATEGORY_FAIL:
			return {
				...state,
				error: action.payload.error,
				isUpdateCategorySuccess: false,
				isUpdateCategoryFail: true,
			};
		case CATEGORY_CLEAR_CACHE:
			return {
				dataListCategory: [],
				// datalistManufactor: [],
				datalistCmbCategory: [],
				datalistCmbCategoryFilter: [],
				totalListCategory: 0,
				loadingPage: false,
				error: {},
			};
		case CATEGORY_CLEAR_CACHE_ACTION:
			return {
				...state,
				isAddCategorySuccess: false,
				isAddCategoryFail: true,
				isUpdateCategorySuccess: false,
				isUpdateCategoryFail: true,
			};
		default:
			return state;
	}
};

export default DashboardListCategory;
