import {
	API_RESPONSE_ERROR,
	API_RESPONSE_SUCCESS,
	CATEGORY_ADD_CATEGORY,
	CATEGORY_ADD_CATEGORY_FAIL,
	CATEGORY_ADD_CATEGORY_SUCCESS,
	CATEGORY_API_GET_LIST,
	CATEGORY_CLEAR_CACHE,
	CATEGORY_CLEAR_CACHE_ACTION,
	CATEGORY_GET_DATA_CATEGORY_BY_ID,
	CATEGORY_GET_LIST_CATEGORY,
	CATEGORY_GET_LIST_CATEGORY_FILTER,
	// CATEGORY_GET_LIST_MANUFACTOR,
	CATEGORY_UPDATE_CATEGORY,
	CATEGORY_UPDATE_CATEGORY_FAIL,
	CATEGORY_UPDATE_CATEGORY_SUCCESS,
	DELETE_CATEGORY,
	DELETE_CATEGORY_FAIL,
	DELETE_CATEGORY_SUCCESS,
} from "./actionTypes";

export const getDataListCategory = (page, limit, order, sortdirection, search = "", parentId = "") => ({
	type: CATEGORY_API_GET_LIST, //Mô tả Hành động này là gì
	payload: {
		urlAPI: "/api/category/get-list",
		filter: {
			limit: limit,
			skip: page * limit - limit,
			order: order + " " + sortdirection,
			where: {
				parentId: parentId.length > 0 ? parentId : "",
				// manufacturerId: manufacturerId,
				status: "active",
				_q: search,
			},
		},
	},
});

// export const categoryGetListManufactor = (search) => ({
// 	type: CATEGORY_GET_LIST_MANUFACTOR,
// 	payload: {
// 		urlAPI: "/api/organization/list-combo-box",
// 		filter: {
// 			where: {
// 				_q: search,
// 				type: "manufactor",
// 			},
// 		},
// 	},
// });

export const categoryGetListCategoryFilter = (search) => ({
	type: CATEGORY_GET_LIST_CATEGORY_FILTER,
	payload: {
		urlAPI: "/api/category/list-combo-box",
		filter: {
			where: {
				_q: search,
			},
		},
	},
});

export const categoryGetListCategory = (search, notId = "") => ({
	type: CATEGORY_GET_LIST_CATEGORY,
	payload: {
		urlAPI: "/api/category/list-combo-box",
		filter: {
			where: {
				_q: search,
				level3: "0",
				notId: notId,
			},
		},
	},
});

export const deleteCategory = (data) => ({
	type: DELETE_CATEGORY,
	payload: {
		urlAPI: "/api/category/delete/" + data,
	},
});

export const deleteCategorySuccess = (user) => ({
	type: DELETE_CATEGORY_SUCCESS,
	payload: user,
});

export const deleteCategoryFail = (error) => ({
	type: DELETE_CATEGORY_FAIL,
	payload: error,
});

// common success
export const dashboardCategoryApiSuccess = (actionType, data) => ({
	type: API_RESPONSE_SUCCESS,
	payload: { actionType, data },
});

// common error
export const dashboardCategoryApiError = (actionType, error) => ({
	type: API_RESPONSE_ERROR,
	payload: { actionType, error },
});

export const onAddNewCategory = (data) => ({
	type: CATEGORY_ADD_CATEGORY,
	payload: {
		urlAPI: "/api/category/data-save",
		data,
	},
});

export const addNewCategorySuccess = (data) => ({
	type: CATEGORY_ADD_CATEGORY_SUCCESS,
	payload: data,
});

export const addNewCategoryFail = (error) => ({
	type: CATEGORY_ADD_CATEGORY_FAIL,
	payload: error,
});

export const onUpdateCategory = (data) => ({
	type: CATEGORY_UPDATE_CATEGORY,
	payload: {
		urlAPI: "/api/category/data-save",
		data,
	},
});

export const updateCategorySuccess = (data) => ({
	type: CATEGORY_UPDATE_CATEGORY_SUCCESS,
	payload: data,
});

export const updateCategoryFail = (error) => ({
	type: CATEGORY_UPDATE_CATEGORY_FAIL,
	payload: error,
});

//get detail by ID
export const getDataCategoryByID = (data) => ({
	type: CATEGORY_GET_DATA_CATEGORY_BY_ID,
	payload: {
		urlAPI: "/api/category/get-info/" + data,
	},
});

export const categoryClearCache = () => ({
	type: CATEGORY_CLEAR_CACHE,
});

export const categoryClearCacheAction = () => ({
	type: CATEGORY_CLEAR_CACHE_ACTION,
});
