import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Mailbox Redux States
import {
	LIST_MAILS_GET_LIST_MAILS,
	LIST_MAILS_GET_LOAD_FOLDER,
	LIST_MAILS_GET_LOAD_LABEL,
	LIST_MAILS_GET_DEFINITIONS,
	LIST_MAILS_GET_MAIL_BY_ID,
	LIST_MAILS_SYNC_DATA,
	LIST_MAILS_MARK_IMPORTANT,
	LIST_MAILS_ADD_MARK,
	LIST_MAILS_REMOVE_MARK,
	LIST_MAILS_ADD_LABEL,
	LIST_MAILS_REMOVE_LABEL,
	LIST_MAILS_REMOVE_IMPORTANT,
	LIST_MAILS_GET_LIST_QUICK_FILTER,
	LIST_MAILS_UPDATE_QUICK_FILTER,
	LIST_MAILS_ADD_QUICK_FILTER,
	LIST_MAILS_GET_LIST_USERS,
	LIST_MAILS_DELETE_QUICK_FILTER,
	LIST_MAILS_SEND_MAIL,
	LIST_MAILS_ASSIGN_TASK,
	LIST_MAILS_GET_LIST_ACCOUNTS,
	LIST_MAILS_DELETE_EMAIL_CONFIG,
	LIST_MAILS_GET_LIST_EMAIL_CONFIG,
	LIST_MAILS_ADD_CONFIG_EMAIL,
	LIST_MAILS_GET_LIST_TEAM_CONFIG_ASSIGN,
	LIST_MAILS_GET_LIST_USER_CONFIG_ASSIGN,
	LIST_MAILS_GET_LIST_CONFIG_ASSIGN,
	LIST_MAILS_DELETE_CONFIG_ASSIGN,
	LIST_MAILS_ADD_CONFIG_ASSIGN,
	LIST_MAILS_UPDATE_CONFIG_ASSIGN,
	LIST_MAILS_UPDATE_EMAIL_CONFIG,
	LIST_MAILS_TEST_EMAIL_CONFIG,
} from "./actionType";

import {
	listMailsApiResponseSuccess,
	listMailsApiResponseError,
	listMailsAddMarkFail,
	listMailsAddMarkSuccess,
	listMailsRemoveMarkFail,
	listMailsRemoveMarkSuccess,
	listMailsAddTagFail,
	listMailsAddTagSuccess,
	listMailsRemoveTagFail,
	listMailsRemoveTagSuccess,
	listMailsUpdateQuickFilterFail,
	listMailsUpdateQuickFilterSuccess,
	listMailsAddQuickFilterFail,
	listMailsAddQuickFilterSuccess,
	listMailsDeleteQuickFilterFail,
	listMailsDeleteQuickFilterSuccess,
	listMailsSendMailSuccess,
	listMailsSendMailFail,
	listMailsAssignTaskFail,
	listMailsAssignTaskSuccess,
	listMailsDeleteConfigEmailFail,
	listMailsDeleteConfigEmailSuccess,
	listMailsDeleteConfigAssignSuccess,
	listMailsDeleteConfigAssignFail,
	listMailsAddConfigEmailFail,
	listMailsAddConfigEmailSuccess,
	listMailsUpdateConfigEmailFail,
	listMailsUpdateConfigEmailSuccess,
	listMailsUpdateConfigAssignFail,
	listMailsUpdateConfigAssignSuccess,
	listMailsAddConfigAssignFail,
	listMailsAddConfigAssignSuccess,
	listEmailsTestEmailConfigFail,
	listEmailsTestEmailConfigSuccess,
} from "./action";

//Include Both Helper File with needed methods
import { transferDeleteData, transferPostData, transferGetData, transferDeleteDataMulti } from "../../helpers/fakebackend_helper";

function* listMailsGetListMails({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(listMailsApiResponseSuccess(LIST_MAILS_GET_LIST_MAILS, response));
	} catch (error) {
		yield put(listMailsApiResponseError(LIST_MAILS_GET_LIST_MAILS, error));
	}
}

export function* watchListMailsGetListMails() {
	yield takeEvery(LIST_MAILS_GET_LIST_MAILS, listMailsGetListMails);
}

function* listMailsGetLoadFolder({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(listMailsApiResponseSuccess(LIST_MAILS_GET_LOAD_FOLDER, response));
	} catch (error) {
		yield put(listMailsApiResponseError(LIST_MAILS_GET_LOAD_FOLDER, error));
	}
}

export function* watchListMailsGetLoadFolder() {
	yield takeEvery(LIST_MAILS_GET_LOAD_FOLDER, listMailsGetLoadFolder);
}

function* listMailsGetLoadLabel({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);

		yield put(listMailsApiResponseSuccess(LIST_MAILS_GET_LOAD_LABEL, response));
	} catch (error) {
		yield put(listMailsApiResponseError(LIST_MAILS_GET_LOAD_LABEL, error));
	}
}

export function* watchListMailsGetLoadLabel() {
	yield takeEvery(LIST_MAILS_GET_LOAD_LABEL, listMailsGetLoadLabel);
}

function* listMailsGetListDefinitions({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(listMailsApiResponseSuccess(LIST_MAILS_GET_DEFINITIONS, response));
	} catch (error) {
		yield put(listMailsApiResponseError(LIST_MAILS_GET_DEFINITIONS, error));
	}
}

export function* watchListMailsGetListDefinitions() {
	yield takeEvery(LIST_MAILS_GET_DEFINITIONS, listMailsGetListDefinitions);
}

function* listMailsGetMailByID({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(listMailsApiResponseSuccess(LIST_MAILS_GET_MAIL_BY_ID, response));
	} catch (error) {
		yield put(listMailsApiResponseError(LIST_MAILS_GET_MAIL_BY_ID, error));
	}
}

export function* watchListMailsGetMailByID() {
	yield takeEvery(LIST_MAILS_GET_MAIL_BY_ID, listMailsGetMailByID);
}

function* listMailsSyncData({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(listMailsApiResponseSuccess(LIST_MAILS_SYNC_DATA, response));
	} catch (error) {
		yield put(listMailsApiResponseError(LIST_MAILS_SYNC_DATA, error));
	}
}

export function* watchListMailsSyncData() {
	yield takeEvery(LIST_MAILS_SYNC_DATA, listMailsSyncData);
}

//Mark important
function* listMailsMarkImportant({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			// yield put(listMailsAddMarkFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			// yield put(listMailsAddMarkFail(response));
		} else {
			// yield put(listMailsAddMarkSuccess(response));
			// toast.success("Update Status Package Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		console.log(error);
		// yield put(listMailsAddMarkFail(error));
		// toast.error("Update Status Package Failed", { autoClose: 3000 });
	}
}
export function* watchListMailsMarkImportant() {
	yield takeEvery(LIST_MAILS_MARK_IMPORTANT, listMailsMarkImportant);
}

//Mark important
function* listMailsRemoveImportant({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			// yield put(listMailsAddMarkFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			// yield put(listMailsAddMarkFail(response));
		} else {
			// yield put(listMailsAddMarkSuccess(response));
			// toast.success("Update Status Package Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		console.log(error);
		// yield put(listMailsAddMarkFail(error));
		// toast.error("Update Status Package Failed", { autoClose: 3000 });
	}
}
export function* watchListMailsRemoveImportant() {
	yield takeEvery(LIST_MAILS_REMOVE_IMPORTANT, listMailsRemoveImportant);
}

//Add Mark
function* listMailsAddMark({ payload: data, kind }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsAddMarkFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsAddMarkFail(response));
		} else {
			yield put(listMailsAddMarkSuccess(response));
			toast.success(`Mark ${kind} Mails Successfully`, { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listMailsAddMarkFail(error));
		toast.error(`Mark ${kind} Mails Failed`, { autoClose: 3000 });
	}
}
export function* watchListMailsAddMark() {
	yield takeEvery(LIST_MAILS_ADD_MARK, listMailsAddMark);
}

//Remove Mark
function* listMailsRemoveMark({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsRemoveMarkFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsRemoveMarkFail(response));
		} else {
			yield put(listMailsRemoveMarkSuccess(response));
			toast.success(`Mark Unread Mails Successfully`, { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listMailsRemoveMarkFail(error));
		toast.error(`Mark Unread Mails Failed`, { autoClose: 3000 });
	}
}
export function* watchListMailsRemoveMark() {
	yield takeEvery(LIST_MAILS_REMOVE_MARK, listMailsRemoveMark);
}

//Add Tag
function* listMailsAddTag({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsAddTagFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsAddTagFail(response));
		} else {
			yield put(listMailsAddTagSuccess(response));
			// toast.success(`Add Tag Mail Successfully`, { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listMailsAddTagFail(error));
		// toast.error(`Add Tag Mail Failed`, { autoClose: 3000 });
	}
}
export function* watchListMailsAddTag() {
	yield takeEvery(LIST_MAILS_ADD_LABEL, listMailsAddTag);
}

//Remove Tag
function* listMailsRemoveTag({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsRemoveTagFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsRemoveTagFail(response));
		} else {
			yield put(listMailsRemoveTagSuccess(response));
			// toast.success(`Remove Tag Mail Successfully`, { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listMailsRemoveTagFail(error));
		// toast.error(`Remove Tag Mail Failed`, { autoClose: 3000 });
	}
}
export function* watchListMailsRemoveTag() {
	yield takeEvery(LIST_MAILS_REMOVE_LABEL, listMailsRemoveTag);
}

//Get Quick Filter
function* listMailsGetListQuickFilter({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(listMailsApiResponseSuccess(LIST_MAILS_GET_LIST_QUICK_FILTER, response));
	} catch (error) {
		yield put(listMailsApiResponseError(LIST_MAILS_GET_LIST_QUICK_FILTER, error));
	}
}
export function* watchListMailsGetListQuickFilter() {
	yield takeEvery(LIST_MAILS_GET_LIST_QUICK_FILTER, listMailsGetListQuickFilter);
}

//Update Quick Filter
function* listMailsAddQuickFilter({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsAddQuickFilterFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsAddQuickFilterFail(response));
		} else {
			yield put(listMailsAddQuickFilterSuccess(response));
			toast.success(`Add Quick Filter Successfully`, { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listMailsAddQuickFilterFail(error));
		toast.error(`Add Quick Filter Failed`, { autoClose: 3000 });
	}
}
export function* watchListMailsAddQuickFilter() {
	yield takeEvery(LIST_MAILS_ADD_QUICK_FILTER, listMailsAddQuickFilter);
}

//Update Quick Filter
function* listMailsUpdateQuickFilter({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsUpdateQuickFilterFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsUpdateQuickFilterFail(response));
		} else {
			yield put(listMailsUpdateQuickFilterSuccess(response));
			toast.success(`Update Quick Filter Successfully`, { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listMailsUpdateQuickFilterFail(error));
		toast.error(`Update Quick Filter Failed`, { autoClose: 3000 });
	}
}
export function* watchListMailsUpdateQuickFilter() {
	yield takeEvery(LIST_MAILS_UPDATE_QUICK_FILTER, listMailsUpdateQuickFilter);
}

//Delete Quick Filter
function* listMailsDeleteQuickFilter({ payload: data, id }) {
	try {
		const response = yield call(transferDeleteData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsDeleteQuickFilterFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsDeleteQuickFilterFail(response));
		} else {
			yield put(listMailsDeleteQuickFilterSuccess({ response, id }));
			toast.success(`Delete Quick Filter Successfully`, { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listMailsDeleteQuickFilterFail(error));
		toast.error(`Delete Quick Filter Failed`, { autoClose: 3000 });
	}
}
export function* watchListMailsDeleteQuickFilter() {
	yield takeEvery(LIST_MAILS_DELETE_QUICK_FILTER, listMailsDeleteQuickFilter);
}

//Get Quick Filter
function* listMailsGetListUsers({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(listMailsApiResponseSuccess(LIST_MAILS_GET_LIST_USERS, response));
	} catch (error) {
		yield put(listMailsApiResponseError(LIST_MAILS_GET_LIST_USERS, error));
	}
}
export function* watchListMailsGetListUsers() {
	yield takeEvery(LIST_MAILS_GET_LIST_USERS, listMailsGetListUsers);
}

function* listMailsSendMail({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);

		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsSendMailFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsSendMailFail(response));
		} else {
			yield put(listMailsSendMailSuccess(response));
			toast.success("Send mail Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		// yield put(receivingUpdatePackagePOFail(error));
		// toast.error("Skipped Received Package PO Fail", { autoClose: 3000 });
	}
}

function* watcherlistMailsSendMail() {
	yield takeEvery(LIST_MAILS_SEND_MAIL, listMailsSendMail);
}

//Assign
function* listMailsAssignTask({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsAssignTaskFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsAssignTaskFail(response));
		} else {
			yield put(listMailsAssignTaskSuccess(response));
			toast.success(`Assign Mail Successfully`, { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listMailsAssignTaskFail(error));
		toast.error(`Assign Mail Failed`, { autoClose: 3000 });
	}
}
export function* watchListMailsAssignTask() {
	yield takeEvery(LIST_MAILS_ASSIGN_TASK, listMailsAssignTask);
}

//Get List Account
function* listMailsGetListAccount({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(listMailsApiResponseSuccess(LIST_MAILS_GET_LIST_ACCOUNTS, response));
	} catch (error) {
		yield put(listMailsApiResponseError(LIST_MAILS_GET_LIST_ACCOUNTS, error));
	}
}
export function* watchListMailsGetListAccount() {
	yield takeEvery(LIST_MAILS_GET_LIST_ACCOUNTS, listMailsGetListAccount);
}

//Delete Quick Filter
function* listMailsDeleteConfigEmail({ payload: data, id }) {
	try {
		const response = yield call(transferDeleteData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsDeleteConfigEmailFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsDeleteConfigEmailFail(response));
		} else {
			yield put(listMailsDeleteConfigEmailSuccess({ response, id }));
			toast.success(`Delete Successfully`, { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listMailsDeleteConfigEmailFail(error));
		toast.error(`Delete Successfully`, { autoClose: 3000 });
	}
}
export function* watchListMailsDeleteConfigEmail() {
	yield takeLatest(LIST_MAILS_DELETE_EMAIL_CONFIG, listMailsDeleteConfigEmail);
}

function* listMailsGetListConfigEmail({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(listMailsApiResponseSuccess(LIST_MAILS_GET_LIST_EMAIL_CONFIG, response));
	} catch (error) {
		yield put(listMailsApiResponseError(LIST_MAILS_GET_LIST_EMAIL_CONFIG, error));
	}
}

export function* watchListMailsGetListConfigEmail() {
	yield takeEvery(LIST_MAILS_GET_LIST_EMAIL_CONFIG, listMailsGetListConfigEmail);
}

//Add Quick Email
function* listMailsAddConfigEmail({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsAddConfigEmailFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsAddConfigEmailFail(response));
		} else {
			yield put(listMailsAddConfigEmailSuccess(response));
			toast.success(`Add Config Email Successfully`, { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listMailsAddConfigEmailFail(error));
		toast.error(`Add Config Email Failed`, { autoClose: 3000 });
	}
}
export function* watchListMailsAddConfigEmail() {
	yield takeLatest(LIST_MAILS_ADD_CONFIG_EMAIL, listMailsAddConfigEmail);
}

//Update Quick Filter
function* listMailsUpdateConfigEmail({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsUpdateConfigEmailFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsUpdateConfigEmailFail(response));
		} else {
			yield put(listMailsUpdateConfigEmailSuccess(response));
			toast.success(`Update Successfully`, { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listMailsUpdateConfigEmailFail(error));
		toast.error(`Update Failed`, { autoClose: 3000 });
	}
}
export function* watchListMailsUpdateConfigEmail() {
	yield takeLatest(LIST_MAILS_UPDATE_EMAIL_CONFIG, listMailsUpdateConfigEmail);
}

function* listMailsGetListUserConfigAssignFilter({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(listMailsApiResponseSuccess(LIST_MAILS_GET_LIST_USER_CONFIG_ASSIGN, response));
	} catch (error) {
		yield put(listMailsApiResponseError(LIST_MAILS_GET_LIST_USER_CONFIG_ASSIGN, error));
	}
}

export function* watchListMailsGetListUserConfigAssignFilter() {
	yield takeEvery(LIST_MAILS_GET_LIST_USER_CONFIG_ASSIGN, listMailsGetListUserConfigAssignFilter);
}

function* listMailsGetListTeamConfigAssignFilter({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(listMailsApiResponseSuccess(LIST_MAILS_GET_LIST_TEAM_CONFIG_ASSIGN, response));
	} catch (error) {
		yield put(listMailsApiResponseError(LIST_MAILS_GET_LIST_TEAM_CONFIG_ASSIGN, error));
	}
}

export function* watchListMailsGetListTeamConfigAssignFilter() {
	yield takeEvery(LIST_MAILS_GET_LIST_TEAM_CONFIG_ASSIGN, listMailsGetListTeamConfigAssignFilter);
}

function* listMailsGetListConfigAssignFilter({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(listMailsApiResponseSuccess(LIST_MAILS_GET_LIST_CONFIG_ASSIGN, response));
	} catch (error) {
		yield put(listMailsApiResponseError(LIST_MAILS_GET_LIST_CONFIG_ASSIGN, error));
	}
}

export function* watchListMailsGetListConfigAssignFilter() {
	yield takeEvery(LIST_MAILS_GET_LIST_CONFIG_ASSIGN, listMailsGetListConfigAssignFilter);
}

function* listMailsDeleteConfigAssign({ payload: data, id }) {
	try {
		const response = yield call(transferDeleteData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsDeleteConfigAssignFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsDeleteConfigAssignFail(response));
		} else {
			yield put(listMailsDeleteConfigAssignSuccess({ response, id }));
			toast.success(`Delete Successfully`, { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listMailsDeleteConfigEmailFail(error));
		toast.error(`Delete Successfully`, { autoClose: 3000 });
	}
}
export function* watchlistMailsDeleteConfigAssign() {
	yield takeLatest(LIST_MAILS_DELETE_CONFIG_ASSIGN, listMailsDeleteConfigAssign);
}

//Add Config assign
function* listMailsAddConfigAssign({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsAddConfigAssignFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsAddConfigAssignFail(response));
		} else {
			yield put(listMailsAddConfigAssignSuccess(response));
			toast.success(`Add Config Successfully`, { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listMailsAddQuickFilterFail(error));
		toast.error(`Add Config Failed`, { autoClose: 3000 });
	}
}
export function* watchListMailsAddConfigAssign() {
	yield takeLatest(LIST_MAILS_ADD_CONFIG_ASSIGN, listMailsAddConfigAssign);
}

//Update ConfigAssign
function* listMailsUpdateConfigAssign({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR") {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(listMailsUpdateConfigAssignFail(response));
		} else if (typeof response.error != "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(listMailsUpdateConfigAssignFail(response));
		} else {
			yield put(listMailsUpdateConfigAssignSuccess(response));
			toast.success(`Update Successfully`, { autoClose: 1000 });
		}
	} catch (error) {
		yield put(listMailsUpdateConfigAssignFail(error));
		toast.error(`Update Failed`, { autoClose: 3000 });
	}
}
export function* watchlistMailsUpdateConfigAssign() {
	yield takeLatest(LIST_MAILS_UPDATE_CONFIG_ASSIGN, listMailsUpdateConfigAssign);
}

//Update ConfigAssign
function* listEmailsTestEmailConfig({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		yield put(listEmailsTestEmailConfigSuccess(response));
	} catch (error) {
		yield put(listEmailsTestEmailConfigFail(error));
		toast.error(`Update Failed`, { autoClose: 3000 });
	}
}
export function* watchlistEmailsTestEmailConfig() {
	yield takeLatest(LIST_MAILS_TEST_EMAIL_CONFIG, listEmailsTestEmailConfig);
}

function* DashboardListMailsSaga() {
	yield all([
		fork(watchListMailsGetListMails),
		fork(watchListMailsGetLoadFolder),
		fork(watchListMailsGetLoadLabel),
		fork(watchListMailsGetListDefinitions),
		fork(watchListMailsGetMailByID),
		fork(watchListMailsSyncData),
		fork(watchListMailsMarkImportant),
		fork(watchListMailsRemoveImportant),
		fork(watchListMailsAddMark),
		fork(watchListMailsRemoveMark),
		fork(watchListMailsAddTag),
		fork(watchListMailsRemoveTag),
		fork(watchListMailsGetListQuickFilter),
		fork(watchListMailsAddQuickFilter),
		fork(watchListMailsUpdateQuickFilter),
		fork(watchListMailsDeleteQuickFilter),
		fork(watchListMailsGetListUsers),
		fork(watcherlistMailsSendMail),
		fork(watchListMailsAssignTask),
		fork(watchListMailsGetListAccount),
		fork(watchListMailsDeleteConfigEmail),
		fork(watchListMailsGetListConfigEmail),
		fork(watchListMailsUpdateConfigEmail),
		fork(watchListMailsAddConfigEmail),
		fork(watchListMailsGetListUserConfigAssignFilter),
		fork(watchListMailsGetListTeamConfigAssignFilter),
		fork(watchListMailsGetListConfigAssignFilter),
		fork(watchlistMailsDeleteConfigAssign),
		fork(watchlistMailsUpdateConfigAssign),
		fork(watchListMailsAddConfigAssign),
		fork(watchlistEmailsTestEmailConfig),
	]);
}

export default DashboardListMailsSaga;
