import React from "react";
import { ToastContainer, toast } from "react-toastify";

const Notification = (
  message = "Notification",
  type = "success",
  position = "top-right",
  hideProgressBar = false
) => {
  const className = (type) => {
    switch (type) {
      case "error":
        return "bg-danger text-white";
      case "primary":
        return "bg-primary text-white";
      case "warning":
        return "bg-warning text-white";

      default:
        return "bg-success text-white";
    }
  };
  const notification = () =>
    toast(message, {
      position: position,
      hideProgressBar: hideProgressBar,
      className: className(type),
    });
  return notification(type);
};

export default Notification;
