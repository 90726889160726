import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik } from "formik";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import FormSubmit from "../../../../Components/Common/FormSubmit";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import ListQuickSelectTable from "./Table/ListQuickSelectTable";
import { listLogisticsSetValuePackageSizeDomestic, listLogisticsSetValuePackageSizeInternational } from "../../../../store/actions";

const ModelQuickSelect = ({ isOpen = false, toggle = () => {} }) => {
	const dispatch = useDispatch();

	const formikRef = useRef();
	const validateSchema = yup.object({
		// emailAddress: yup.string("Email is required").email("Please enter email format").required("Email is required"),
	});

	const submitForm = (values) => {
		let data = values.package_information;
		dispatch(listLogisticsSetValuePackageSizeInternational(data));
	};

	const initialValues = {
		package_information: [],
	};

	const handleReset = () => {
		toggle();
	};

	return (
		<Modal
			isOpen={isOpen}
			toggle={() => {
				handleReset();
			}}
			centered
			className="modal-xl-4">
			<Formik enableReinitialize initialValues={initialValues} validationSchema={validateSchema} onSubmit={submitForm} innerRef={formikRef}>
				{(formik) => {
					const {
						values,
						handleChange,
						setFieldValue,
						handleSubmit,
						errors,
						touched,
						handleBlur,
						isValid,
						dirty,
						validateForm,
						setFieldError,
						setFieldTouched,
					} = formik;
					return (
						<FormSubmit onSubmit={handleSubmit}>
							<ModalHeader className="bg-soft-info p-3" toggle={toggle}>
								Config
							</ModalHeader>
							<ModalBody className="p-0">
								<Row className="m-0">
									<Col xs={12} className="border-start border-1 pb-3 ps-0 pe-0  d-flex flex-column justify-content-center ">
										<div className="py-2 border-1 w-100 px-4">
											<ListQuickSelectTable setFieldValue={setFieldValue} isOpen={isOpen} />
										</div>
										<div className="mt-3 w-100 mx-auto d-flex align-items-center justify-content-end">
											<div className="me-4">
												<Button
													className="btn-info"
													disabled={!dirty}
													type="button"
													color="success"
													onClick={() => {
														handleSubmit(values);
														toggle();
													}}>
													Add
												</Button>
											</div>
										</div>
									</Col>
								</Row>
							</ModalBody>
						</FormSubmit>
					);
				}}
			</Formik>
		</Modal>
	);
};

export default ModelQuickSelect;
