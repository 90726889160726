import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";

//calendar
import calendarSaga from "./calendar/saga";

//ecommerce
import ecommerceSaga from "./ecommerce/saga";

// Dashboard Analytics
import dashboardAnalyticsSaga from "./dashboardAnalytics/saga";

// Dashboard CRM
import dashboardCrmSaga from "./dashboardCRM/saga";

// Dashboard Ecommerce
import dashboardEcommerceSaga from "./dashboardEcommerce/saga";

// Dashboard Crypto
import dashboardCryptoSaga from "./dashboardCrypto/saga";

// Dashboard Project
import dashboardProjectSaga from "./dashboardProject/saga";

// Dashboard NFT
import dashboardNFTSaga from "./dashboardNFT/saga";

// File Manager
import fileManager from "./fileManager/saga";

//search VPN
import dashboardSearchVPNSaga from "./searchVPN/saga";

//SearchOption
import dashboardValueSearchSaga from "./searchOption/saga";

//Master data
import dashboardMasterDataSaga from "./masterData/saga";

//Category
import dashboardCategorySaga from "./category/saga";

import DashboardListMailsSaga from "./listMails/saga";

import DashboardListLogisticsSaga from "./logistics/saga";

export default function* rootSaga() {
	yield all([
		//public
		fork(LayoutSaga),
		fork(AccountSaga),
		fork(AuthSaga),
		fork(ForgetSaga),
		fork(ProfileSaga),
		fork(calendarSaga),
		fork(ecommerceSaga),
		fork(dashboardAnalyticsSaga),
		fork(dashboardCrmSaga),
		fork(dashboardEcommerceSaga),
		fork(dashboardCryptoSaga),
		fork(dashboardProjectSaga),
		fork(dashboardNFTSaga),
		fork(fileManager),
		fork(dashboardSearchVPNSaga),
		fork(dashboardValueSearchSaga),
		fork(dashboardMasterDataSaga),
		fork(dashboardCategorySaga),
		fork(DashboardListMailsSaga),
		fork(DashboardListLogisticsSaga),
	]);
}
