import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik } from "formik";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import FormSubmit from "../../../../Components/Common/FormSubmit";
import InputComponent from "../../../../Components/Hooks/InputComponent";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import LabelComponent from "../../../../Components/Hooks/LabelComponent";
import {
	listLogisticsAddAddress,
	listLogisticsDeleteAddress,
	listLogisticsGetListAddress,
	listLogisticsSetValueFromToDomestic,
	listLogisticsUpdateAddress,
} from "../../../../store/actions";
import ModalLoading from "../../../../Components/Hooks/ModalLoading";

const ModalBooked = ({ isOpen = false, toggle = () => {}, typeBooked = "from" }) => {
	const dispatch = useDispatch();

	const [searchAddress, setSearchAddress] = useState("");

	const [address, setaddress] = useState("");
	const [streetLine, setStreetLine] = useState("");
	const [city, setCity] = useState("");
	const [postCode, setPostCode] = useState("");
	const [state, setState] = useState("");
	const [country, setCountry] = useState("");
	const [phone, setPhone] = useState("");
	const [instruction, setInstruction] = useState("");

	const [data, setData] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [order, setOrder] = useState("updatedAt");
	const [sortDirection, setSortDirection] = useState("desc");
	const [perPage, setPerPage] = useState(50);
	const [rowData, setRowData] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { dataListAddress, totalListAddress, loadingPage } = useSelector((state) => state.DashboardListLogistics);

	useEffect(() => {
		if (isOpen) handleSearch();
	}, [isOpen]);

	useEffect(() => {
		setaddress(rowData?.name);
		setStreetLine(rowData?.address);
		setCity(rowData?.city);
		setPostCode(rowData?.postCode);
		setState(rowData?.state);
		setCountry(rowData?.country);
		setPhone(rowData?.phone);
		setInstruction(rowData?.instruction);
	}, [rowData]);

	const handleClearRowsData = () => {
		setRowData(null);

		setaddress("");
		setStreetLine("");
		setCity("");
		setPostCode("");
		setState("");
		setCountry("");
		setPhone("");
		setInstruction("");
	};

	const handleSearch = () => {
		dispatch(listLogisticsGetListAddress(currentPage, perPage, order, sortDirection, searchAddress));
	};

	const handleDeleteAddress = (data) => {
		if (data?.id) {
			const dataDelete = {
				id: data?.id,
				status: "deleted",
			};
			dispatch(listLogisticsDeleteAddress(dataDelete));
			handleClearRowsData();
		}
	};

	useEffect(() => {
		setData(dataListAddress);
	}, [dataListAddress]);

	const formikRef = useRef();

	const initialValues = {
		id: rowData?.id ?? "",
		address: rowData?.name ?? "",
		streetLine: rowData?.address ?? "",
		postCode: rowData?.postCode ?? "",
		city: rowData?.city ?? "",
		state: rowData?.state ?? "",
		country: rowData?.country ?? "",
		phone: rowData?.phone ?? "",
		instruction: rowData?.instruction ?? "",
		name: rowData?.name ?? "",
		companyName: rowData?.companyName ?? "",
		emailAddress: rowData?.emailAddress ?? "",
	};

	const validateSchema = yup.object({
		address: yup.string().required("Address is required"),
		streetLine: yup.string().required("Street line is required"),
		postCode: yup.string().required("Postal Code is required"),
		city: yup.string().required("City is required"),
		state: yup.string().required("State is required"),
		country: yup.string().required("Country is required"),
		phone: yup.string().required("Phone is required"),
	});

	const submitForm = (values) => {
		let body = {
			name: values?.address,
			address: values?.streetLine,
			postCode: values?.postCode,
			city: values?.city,
			state: values?.state,
			country: values?.country,
			phone: values?.phone,
			instruction: values?.instruction,
		};

		if (values?.id && values?.id !== "") {
			body.id = values?.id;
			dispatch(listLogisticsUpdateAddress(body));
		} else {
			if (typeBooked == "from") {
				body.instruction = "";
			}
			dispatch(listLogisticsAddAddress(body));
			handleClearRowsData();
		}
	};

	const handleReset = () => {
		handleClearRowsData();
		toggle();
	};

	useEffect(() => {
		if (!isOpen) {
			handleClearRowsData();
		}
	}, [isOpen]);

	return (
		<>
			<ModalLoading isOpen={isSubmitting} />
			<Modal
				isOpen={isOpen}
				toggle={() => {
					handleReset();
				}}
				centered
				className="modal-xl-4">
				<Formik enableReinitialize initialValues={initialValues} validationSchema={validateSchema} onSubmit={submitForm} innerRef={formikRef}>
					{(formik) => {
						const {
							values,
							handleChange,
							setFieldValue,
							handleSubmit,
							errors,
							touched,
							handleBlur,
							isValid,
							dirty,
							validateForm,
							setFieldError,
							setFieldTouched,
						} = formik;
						return (
							<FormSubmit onSubmit={handleSubmit}>
								<ModalHeader className="bg-soft-info p-3" toggle={toggle}>
									Recently booked
								</ModalHeader>
								<ModalBody className="p-0">
									<Row className="m-0">
										<Col xs={5} className="pb-3 ps-0 pe-0">
											<div className="py-2 border-bottom border-1 w-100 px-2 align-items-center justify-content-between">
												<Row className="gy-3">
													<Col lg={12}>
														<InputComponent
															showButtonSearch
															type="search"
															className="form-control"
															placeholder={"Search..."}
															value={searchAddress}
															onChange={(value) => {
																setSearchAddress(value);
															}}
															onKeyDown={(keyEvent) => {
																if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
																	setCurrentPage(1);
																	handleSearch();
																}
															}}
															onClickSearch={() => {
																handleSearch();
																setCurrentPage(1);
															}}
														/>
													</Col>
												</Row>
											</div>
											<div
												className=""
												style={{
													height: "750px",
													overflowY: "scroll",
												}}>
												{data.map((item, key) => {
													return (
														<div
															className="border border-1 m-2 align-items-center p-2 justify-content-between row-data-custom"
															onClick={() => {
																setRowData(item);
															}}
															style={item?.id == rowData?.id && rowData?.id ? { backgroundColor: "aliceblue" } : null}
															key={key}>
															<div>Name: {item?.name}</div>
															<div>Add: {item?.address}</div>
															<div>Phone: {item?.phone}</div>
														</div>
													);
												})}
											</div>
										</Col>
										<Col xs={7} className="border-start border-1 pb-3 ps-0 pe-0  d-flex flex-column justify-content-start ">
											<div className="py-2 border-1 w-100 d-flex px-4 align-items-center justify-content-between">
												<Row className="gy-3">
													<Col lg={12}>
														<LabelComponent title="Name of Address:" requiredField />
														<InputComponent
															type="text"
															name={`address`}
															value={address}
															onChange={(value, name) => {
																setaddress(value);
																setFieldValue("address", value);
															}}
															placeholder={"Name of Address"}
															requiredField
															validate={{
																required: { value: true },
															}}
															onBlur={handleBlur}
															invalid={touched.address && errors.address ? true : false}
															error={touched?.address && errors?.address}
															englishValue={false}
														/>
													</Col>
													<Col lg={12}>
														<LabelComponent title="Street line:" requiredField />
														<InputComponent
															requiredField
															type="text"
															name={`streetLine`}
															value={streetLine}
															onChange={(value, name) => {
																setStreetLine(value);
																setFieldValue("streetLine", value);
															}}
															placeholder={"Street line"}
															validate={{
																required: { value: true },
															}}
															onBlur={handleBlur}
															invalid={touched.streetLine && errors.streetLine ? true : false}
															error={touched?.streetLine && errors?.streetLine}
															englishValue={false}
														/>
													</Col>
													<Col lg={4}>
														<LabelComponent title="Postal Code:" requiredField />
														<InputComponent
															type="text"
															name={`postCode`}
															value={postCode}
															onChange={(value, name) => {
																setPostCode(value);
																setFieldValue("postCode", value);
															}}
															placeholder={"Postal Code"}
															requiredField
															validate={{
																required: { value: true },
															}}
															onBlur={handleBlur}
															invalid={touched.postCode && errors.postCode ? true : false}
															error={touched?.postCode && errors?.postCode}
															englishValue={false}
														/>
													</Col>
													<Col lg={4}>
														<LabelComponent title="City:" requiredField />
														<InputComponent
															type="text"
															name={`city`}
															value={city}
															onChange={(value, name) => {
																setCity(value);
																setFieldValue("city", value);
															}}
															placeholder={"City"}
															requiredField
															validate={{
																required: { value: true },
															}}
															onBlur={handleBlur}
															invalid={touched.city && errors.city ? true : false}
															error={touched?.city && errors?.city}
															englishValue={false}
														/>
													</Col>
													<Col lg={4}>
														<LabelComponent title="State:" requiredField />
														<InputComponent
															type="text"
															name={`state`}
															value={state}
															onChange={(value, name) => {
																setState(value);
																setFieldValue("state", value);
															}}
															placeholder={"State"}
															requiredField
															validate={{
																required: { value: true },
															}}
															onBlur={handleBlur}
															invalid={touched.state && errors.state ? true : false}
															error={touched?.state && errors?.state}
															englishValue={false}
														/>
													</Col>
													<Col lg={6}>
														<LabelComponent title="Country:" requiredField />
														<InputComponent
															type="text"
															name={`country`}
															value={country}
															onChange={(value, name) => {
																setCountry(value);
																setFieldValue("country", value);
															}}
															placeholder={"Country"}
															requiredField
															validate={{
																required: { value: true },
															}}
															onBlur={handleBlur}
															invalid={touched.country && errors.country ? true : false}
															error={touched?.country && errors?.country}
															englishValue={false}
														/>
													</Col>
													<Col lg={6}>
														<LabelComponent title="Phone:" requiredField />
														<InputComponent
															type="text"
															name={`phone`}
															value={phone}
															onChange={(value, name) => {
																setPhone(value);
																setFieldValue("phone", value);
															}}
															placeholder={"Phone"}
															requiredField
															validate={{
																required: { value: true },
															}}
															onBlur={handleBlur}
															invalid={touched.phone && errors.phone ? true : false}
															error={touched?.phone && errors?.phone}
															englishValue={false}
														/>
													</Col>
													{typeBooked == "to" ? (
														<Col lg={12}>
															<LabelComponent title="Instruction/Attn:" />
															<InputComponent
																type="textarea"
																name={`instruction`}
																value={instruction}
																onChange={(value, name) => {
																	setInstruction(value);
																	setFieldValue("instruction", value);
																}}
																placeholder={"Instruction/Attn"}
																rows={3}
																englishValue={false}
															/>
														</Col>
													) : (
														<></>
													)}
												</Row>
											</div>
											<div className="mt-3 w-100 mx-auto d-flex align-items-center justify-content-end">
												<div className="me-4">
													<Button
														disabled={!rowData?.id}
														className="ms-3"
														type="button"
														color="danger"
														onClick={() => {
															handleDeleteAddress(rowData);
														}}>
														Delete
													</Button>
													<Button
														disabled={!dirty && rowData?.id}
														className="ms-3"
														type="button"
														color="success"
														onClick={() => {
															setFieldValue("id", "");
															handleSubmit(values);
														}}>
														Add
													</Button>
													<Button
														disabled={!dirty || !rowData?.id}
														className="ms-3 me-3"
														type="button"
														color="success"
														onClick={() => {
															handleSubmit(values);
														}}>
														Save
													</Button>
													<Button
														disabled={rowData == null}
														type="button"
														color="primary"
														onClick={() => {
															values.typeBooked = typeBooked;
															dispatch(listLogisticsSetValueFromToDomestic(values));
															handleReset();
														}}>
														Select
													</Button>
												</div>
											</div>
										</Col>
									</Row>
								</ModalBody>
							</FormSubmit>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
};

export default ModalBooked;
