import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Calender Redux States
import {
	CATEGORY_ADD_CATEGORY,
	CATEGORY_API_GET_LIST,
	CATEGORY_GET_DATA_CATEGORY_BY_ID,
	CATEGORY_GET_LIST_CATEGORY,
	CATEGORY_GET_LIST_CATEGORY_FILTER,
	CATEGORY_GET_LIST_MANUFACTOR,
	CATEGORY_UPDATE_CATEGORY,
	DELETE_CATEGORY,
} from "./actionTypes";

import {
	addNewCategoryFail,
	addNewCategorySuccess,
	dashboardCategoryApiError,
	dashboardCategoryApiSuccess,
	deleteCategoryFail,
	deleteCategorySuccess,
	updateCategoryFail,
	updateCategorySuccess,
} from "./action";

//Include Both Helper File with needed methods
import { transferDeleteData, transferGetData, transferPostData } from "../../helpers/fakebackend_helper";
import { toast } from "react-toastify";

function* getDataListCategory({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(dashboardCategoryApiSuccess(CATEGORY_API_GET_LIST, response));
	} catch (error) {
		yield put(dashboardCategoryApiError(CATEGORY_API_GET_LIST, error));
	}
}
export function* watchGetCategory() {
	yield takeEvery(CATEGORY_API_GET_LIST, getDataListCategory);
}

//get list cmb manufactor
// function* categoryGetListManufactor({ payload: data }) {
// 	try {
// 		const response = yield call(transferGetData, data);
// 		yield put(dashboardCategoryApiSuccess(CATEGORY_GET_LIST_MANUFACTOR, response));
// 	} catch (error) {
// 		yield put(dashboardCategoryApiError(CATEGORY_GET_LIST_MANUFACTOR, error));
// 	}
// }
// export function* watchCategoryGetListManufactor() {
// 	yield takeEvery(CATEGORY_GET_LIST_MANUFACTOR, categoryGetListManufactor);
// }

//get list cmb category
function* categoryGetListCategoryFilter({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(dashboardCategoryApiSuccess(CATEGORY_GET_LIST_CATEGORY_FILTER, response));
	} catch (error) {
		yield put(dashboardCategoryApiError(CATEGORY_GET_LIST_CATEGORY_FILTER, error));
	}
}
export function* watchCategoryGetListCategoryFilter() {
	yield takeEvery(CATEGORY_GET_LIST_CATEGORY_FILTER, categoryGetListCategoryFilter);
}

function* categoryGetListCategory({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(dashboardCategoryApiSuccess(CATEGORY_GET_LIST_CATEGORY, response));
	} catch (error) {
		yield put(dashboardCategoryApiError(CATEGORY_GET_LIST_CATEGORY, error));
	}
}
export function* watchCategoryGetListCategory() {
	yield takeEvery(CATEGORY_GET_LIST_CATEGORY, categoryGetListCategory);
}

//Delete category
function* deleteCategory({ payload: data }) {
	try {
		const response = yield call(transferDeleteData, data);
		if (response.Status === "OK") {
			yield put(deleteCategorySuccess(response));
			toast.success("Delete Category Successfully", { autoClose: 1000 });
		} else {
			yield put(deleteCategoryFail(response));
			toast.error("Delete Category Failed", { autoClose: 3000 });
		}
	} catch (error) {
		yield put(deleteCategoryFail(error));
		toast.error("Delete Category Failed", { autoClose: 3000 });
	}
}
export function* watchDeleteCategory() {
	yield takeEvery(DELETE_CATEGORY, deleteCategory);
}

//Add New Category
function* onAddNewCategory({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR" || Number(response.Status) >= 400) {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(addNewCategoryFail(response));
		} else if (typeof response.error !== "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(addNewCategoryFail(response));
		} else {
			yield put(addNewCategorySuccess(response));
			toast.success("Add New Category Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		yield put(addNewCategoryFail(error));
		toast.error("Add New Category Failed", { autoClose: 3000 });
	}
}
export function* watchAddNewCategory() {
	yield takeEvery(CATEGORY_ADD_CATEGORY, onAddNewCategory);
}

function* onUpdateCategory({ payload: data }) {
	try {
		const response = yield call(transferPostData, data);
		if (response.Status === "ERROR" || Number(response.Status) >= 400) {
			toast.error(response.Msg ? response.Msg : "", { autoClose: 3000 });
			yield put(updateCategoryFail(response));
		} else if (typeof response.error !== "undefined") {
			toast.error(response.error.message, { autoClose: 3000 });
			yield put(updateCategoryFail(response));
		} else {
			yield put(updateCategorySuccess(response));
			toast.success("Update Category Successfully", { autoClose: 1000 });
		}
	} catch (error) {
		yield put(updateCategoryFail(error));
		toast.error("update Category Failed", { autoClose: 3000 });
	}
}
export function* watchUpdateCategory() {
	yield takeEvery(CATEGORY_UPDATE_CATEGORY, onUpdateCategory);
}

function* getDataCategoryByID({ payload: data }) {
	try {
		const response = yield call(transferGetData, data);
		yield put(dashboardCategoryApiSuccess(CATEGORY_GET_DATA_CATEGORY_BY_ID, response));
	} catch (error) {
		yield put(dashboardCategoryApiError(CATEGORY_GET_DATA_CATEGORY_BY_ID, error));
	}
}
export function* watchGetDataCategoryByID() {
	yield takeEvery(CATEGORY_GET_DATA_CATEGORY_BY_ID, getDataCategoryByID);
}

function* dashboardCategorySaga() {
	yield all([
		fork(watchGetCategory),
		// fork(watchCategoryGetListManufactor),
		fork(watchCategoryGetListCategory),
		fork(watchDeleteCategory),
		fork(watchAddNewCategory),
		fork(watchUpdateCategory),
		fork(watchGetDataCategoryByID),
		fork(watchCategoryGetListCategoryFilter),
	]);
}

export default dashboardCategorySaga;
