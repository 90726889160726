import { CLEAR_CACHE_VALUE_SEARCH, CLEAR_CACHE_VALUE_SEARCH_BY_SEARCH, UPDATE_VALUE_SEARCH } from "./actionType";

const INIT_STATE = {
	searchOption: "",
	loadingSearchOption: false,
	errorSearchOption: null,
};

const DashboardSearchOption = (state = INIT_STATE, action) => {
	switch (action.type) {
		case UPDATE_VALUE_SEARCH:
			return {
				...state,
				searchOption: action.payload,
				loadingSearchOption: !state.loadingSearchOption,
			};
		case CLEAR_CACHE_VALUE_SEARCH:
			return {
				...state,
				searchOption: "",
				loadingSearchOption: !state.loadingSearchOption,
			};
		case CLEAR_CACHE_VALUE_SEARCH_BY_SEARCH:
			return {
				...state,
				searchOption: "",
				loadingSearchOption: false,
			};
		default:
			return state;
	}
};
export default DashboardSearchOption;
