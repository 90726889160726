import React from "react";
import { Modal, ModalBody, Row } from "reactstrap";
const Spinner = require("react-spinkit");
function ModalLoading({ isOpen = false, toggle = () => {}, text}) {
	return (
		<React.Fragment>
			<Modal fade={false} isOpen={isOpen} toggle={toggle} centered className="modal-fullscreen modal-bg-none">
				<ModalBody>
					<Row style={{ height: "100%" }} className="d-flex align-items-center justify-content-center flex-column">
						<div className="d-flex justify-content-center">
							<Spinner name="ball-clip-rotate-multiple" color="#FFFFFF" fadeIn="quarter" />
						</div>
						<div className="text-white fs-2 d-flex justify-content-center mt-3">
							{text ? text :"Loading"} <Spinner className="text-white" name="three-bounce" fadeIn="quarter" />
						</div>
					</Row>
				</ModalBody>
			</Modal>
		</React.Fragment>
	);
}

export default ModalLoading;
