import { CLEAR_CACHE_VALUE_SEARCH, CLEAR_CACHE_VALUE_SEARCH_BY_SEARCH, UPDATE_VALUE_SEARCH } from "./actionType";

export const updateValueSearch = (data) => ({
	type: UPDATE_VALUE_SEARCH,
	payload: data,
});

export const clearCacheValueSearch = () => ({
	type: CLEAR_CACHE_VALUE_SEARCH,
});
export const clearCacheValueSearchByURLSearch = () => ({
	type: CLEAR_CACHE_VALUE_SEARCH_BY_SEARCH,
});
