import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { regexSerialNumber } from "./ValidateComponent";
import unidecode from "unidecode";

const SelectComponent = ({
	value,
	onChange,
	options,
	label,
	error,
	onBlur,
	id,
	name,
	touched,
	placeholder,
	display,
	fieldValue,
	requiredField,

	isMulti,
	isDisabled,
	inputValue,
	setInputValue,
	isShowMultiRows,
	isSearchServer,
	isClearable,
	isFixed,
	regexInputValue,
	isUpperValue,
	onKeyDown,
	selectRef,
	isRemoveVersion,
	englishValue = true,
	setInputValueVersion = () => {},
	optionDetail,
	optionIcon,
	displayFieldIcon = "icon",
	setBgColor = false,
	isSearchable = true,
	size = "",
	colorStyles = {},
	menuPosition = "absolute",
	maxMenuHeight = 200,
	menuPlacement = "auto",
}) => {
	const initIsClearable = typeof isClearable !== "undefined" ? isClearable : true;
	const iniMenuPlacement = typeof menuPlacement !== "undefined" ? menuPlacement : "auto";
	const iniIsFixed = typeof isFixed !== "undefined" ? isFixed : "absolute"; // fixed/absolute

	const selectStyles = {
		menuList: (styles) => {
			return {
				...styles,
				maxHeight: 136,
			};
		},
	};

	const multiValueContainer = ({ selectProps, data }) => {
		// console.log(selectProps, "selectProps");
		// console.log(data, "data");
		const label = data.name;
		const allSelected = selectProps.value;
		const index = allSelected.findIndex((selected) => selected.name === name);
		const isLastSelected = index === allSelected.length - 1;
		const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
		const val = `${label}${labelSuffix}`;
		return val;
	};

	const customStyles = {
		valueContainer: (provided, state) => ({
			...provided,
			textOverflow: "ellipsis",
			maxWidth: "90%",
			whiteSpace: "nowrap",
			overflow: "hidden",
			display: "initial",
		}),
	};

	const onInputChange = (option, { action }) => {
		if (!setInputValue) return;

		if (action === "input-change") {
			// const optionLength = option.length;
			// const inputValueLength = inputValue.length;
			// const newInputValue = optionLength < inputValueLength ? option : inputValue + option[option.length - 1];
			// setInputValue(newInputValue);
			let valueInput = isUpperValue ? option.toUpperCase() : option;
			// if (typeof isRemoveVersion !== "undefined") {
			// 	const arrValueInput = valueInput?.split(" ");
			// 	if (arrValueInput?.length > 1) {
			// 		if (arrValueInput[1]?.toUpperCase()?.indexOf("V") === 0) {
			// 			valueInput = arrValueInput[0];
			// 			setInputValueVersion(arrValueInput[1]);
			// 		} else setInputValueVersion("");
			// 	} else setInputValueVersion("");
			// }

			if (typeof regexInputValue !== "undefined") {
				if (valueInput?.match(regexInputValue)) {
					setInputValue(isUpperValue ? valueInput?.match(regexInputValue)?.join("").toUpperCase() : valueInput?.match(regexInputValue)?.join(""));
				} else setInputValue("");
			} else {
				// const valueInput1 = valueInput ? (isUpperValue ? valueInput.toUpperCase() : valueInput) : "";
				// const valueInput2 = regexSerialNumber(valueInput1, false);
				// setInputValue(valueInput2);
				const convertedValue = englishValue ? unidecode(valueInput) : valueInput;
				const upperCaseText = isUpperValue ? convertedValue?.toString()?.toUpperCase() : convertedValue;
				setInputValue(upperCaseText);
			}
		}
	};

	const filterOption = (option, query) => {
		// Otherwise, show all options.
		return options;
	};

	const filterOptionDefault = (option, inputValue) => {
		let fieldSearch = display ? display : "name";
		const { label, value } = option;
		// looking if other options with same label are matching inputValue
		//const otherKey = options?.filter((opt) => opt[fieldSearch] === label && opt[fieldSearch]?.includes(inputValue));
		const otherKey = options?.filter(
			(opt) => opt[fieldSearch]?.toUpperCase() === label?.toUpperCase() && opt[fieldSearch]?.toUpperCase()?.includes(inputValue?.toUpperCase())
		);
		return String(value)?.includes(inputValue) || otherKey.length > 0;
	};

	// console.log(requiredField, touched, error, name, "requiredField && touched && error");
	const showOptionDetail = (props) => {
		const option = props.data;
		return (
			<components.Option {...props}>
				{display == "fullName" ? (
					<div>
						<span>
							<span className="autocomplete-input-option">{option.fullName ? option.fullName.trim() : option.code ? option.code.trim() : ""}</span>
						</span>
						<div className="autocomplete-input-option-detail">{option.code ? option.code.trim() : ""}</div>
					</div>
				) : (
					<div>
						<span>
							<span className="autocomplete-input-option">{option.code ? option.code.trim() : option.name ? option.name.trim() : ""}</span>
						</span>
						<div className="autocomplete-input-option-detail">{option.name ? option.name.trim() : ""}</div>
					</div>
				)}
			</components.Option>
		);
	};

	const showOptionIcon = (props) => {
		return (
			<components.Option {...props} className="d-flex align-items-center">
				<div>{props?.data?.[displayFieldIcon]}</div>
				<div className="mx-2">{props?.data?.[display ?? "code"]}</div>
			</components.Option>
		);
	};
	const singleValueOptionIcon = ({ children, ...props }) => (
		<components.SingleValue {...props} className="d-flex align-items-center">
			<div>{props?.data?.[displayFieldIcon]}</div>
			<div className="mx-2">{props?.data?.[display ?? "code"]}</div>
		</components.SingleValue>
	);

	const multiValueRemove = (props) => {
		return (
			<components.MultiValueRemove {...props}>
				<i className={`ri-close-line ${setBgColor && "text-white"}`}></i>
			</components.MultiValueRemove>
		);
	};
	const dataClassName = ["bg-primary", "bg-secondary", "bg-success", "bg-info", "bg-warning", "bg-danger", "bg-dark"];

	const multiValueOptionIcon = (props) => {
		const optionArr = props?.selectProps?.value ?? [];
		const findIndex = optionArr?.findIndex((i) => i?.[fieldValue ?? "code"] === props?.data?.[fieldValue ?? "code"]);
		return (
			<components.MultiValue {...props} className={`${setBgColor && dataClassName[findIndex % dataClassName.length]} `}>
				<div>{optionArr?.[findIndex]?.[displayFieldIcon]}</div>
				<div className={`mx-2 ${setBgColor && "text-white"}`}>{optionArr[findIndex]?.[display ?? "code"] ?? ""}</div>
			</components.MultiValue>
		);
	};
	const chooseComponentSelect = () => {
		if (optionDetail) {
			return { Option: showOptionDetail };
		}
		if (optionIcon) {
			if (isMulti) return { Option: showOptionIcon, MultiValue: multiValueOptionIcon, MultiValueRemove: multiValueRemove };
			return { Option: showOptionIcon, SingleValue: singleValueOptionIcon };
		}
		return null;
	};
	return (
		<div>
			{label ? (
				<div className="block text-xaplink_gray_3 text-sm font-bold mb-2">
					{label}{" "}
					{requiredField ? (
						<span>
							(<span className={"text-danger"}>*</span>)
						</span>
					) : undefined}
				</div>
			) : null}
			<Select
				ref={selectRef}
				isDisabled={isDisabled}
				isMulti={isMulti}
				closeMenuOnSelect={isMulti ? false : true}
				placeholder={placeholder}
				isClearable={initIsClearable}
				value={value}
				onChange={onChange}
				onKeyDown={onKeyDown}
				inputValue={inputValue}
				onInputChange={onInputChange}
				id={id}
				name={name}
				onBlur={onBlur}
				// theme={(theme) => ({
				//   ...theme,
				//   borderColor: "#FF0000",
				//   colors: {
				//     ...theme.colors,
				//     // primary25: "silver",
				//     // primary50: "gray",
				//     primary: "gray",
				//   },
				// })}
				options={options ?? []}
				getOptionLabel={(option) => {
					if (display) {
						return option[display] ? option[display] : option.code;
					} else {
						return option.code ? option.code : option.name ? option.name : "";
					}
				}}
				getOptionValue={(option) => {
					if (fieldValue) {
						return option[fieldValue] ? option[fieldValue] : option.code;
					} else {
						return option.code;
					}
				}}
				components={chooseComponentSelect()}
				//components={optionDetail && { Option } : null}
				// components={{ Option: optionDetail ? showOptionDetail : null }}
				// styles={{
				//   option: (base) => ({
				//     ...base,
				//     border: `1px dotted #FF0000`,
				//     height: "100%",
				//   }),
				// }}
				styles={{
					control: (baseStyles, state) => ({
						...baseStyles,
						borderColor: requiredField && touched && error ? "red" : isDisabled ? "#c3c3c3" : "gray",
						height: size === "sm" ? 33 : "auto",
						minHeight: size === "sm" ? 33 : 38,
						fontSize: size === "sm" ? "11px" : "auto",
					}),
					multiValueLabel: (styles, { data }) => ({
						...styles,
						display: "flex",
						alignItems: "center",
					}),
					...colorStyles,
					// valueContainer: (provided, state) => ({
					//   ...provided,
					//   textOverflow: "ellipsis",
					//   maxWidth: "90%",
					//   whiteSpace: "nowrap",
					//   overflow: "hidden",
					//   display: "initial",
					// display: `${state.hasValue ? "initial" : "flex"}`,
					// }),
				}}
				// styles={{
				//   valueContainer: (styles: CSSObjectWithLabel, state) => {
				//     console.log("Current state :  ", state);
				//     return {
				//       ...styles,
				//       textOverflow: "ellipsis",
				//       maxWidth: "90%",
				//       whiteSpace: "nowrap",
				//       overflow: "hidden",
				//       flexWrap:
				//       display: `${state.hasValue ? "initial" : "flex"}`,
				//     };
				//   },
				// }}
				isSearchable={isSearchable}
				filterOption={isSearchServer ? filterOption : filterOptionDefault}
				classNamePrefix={
					isShowMultiRows
						? `${size === "sm" ? `react-select-sm ` : ""} react-select-multi-line`
						: `${size === "sm" ? `react-select-sm ` : ""} react-select`
				}
				menuPosition={menuPosition}
				maxMenuHeight={maxMenuHeight}
				menuPlacement={menuPlacement}
			/>
			{requiredField && touched && error ? (
				<span className="text-danger" style={{ width: "100%", marginTop: "0.25rem", fontSize: "0.875em" }}>
					{error}
				</span>
			) : null}
		</div>
	);
};
export default SelectComponent;
