import React from "react";
import { Label } from "reactstrap";

const LabelComponent = (props) => {
	const { title, htmlFor, className, requiredField, style } = props;
	let initClassName = typeof className === "undefined" ? "form-label" : className;
	let initStyle = typeof style === "undefined" ? {} : style;
	return (
		<React.Fragment>
			{requiredField ? (
				<Label htmlFor={htmlFor} className={initClassName} style={initStyle}>
					{title ? title : <>&nbsp;</>}
					&nbsp;
					<span>
						(<span className={"text-danger"}>*</span>)
					</span>
				</Label>
			) : (
				<Label htmlFor={htmlFor} className={initClassName} style={initStyle}>
					{title ? title : <>&nbsp;</>}
				</Label>
			)}
		</React.Fragment>
	);
};

export default LabelComponent;
