import React, { useState, useEffect, useCallback, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputComponent from "../../../../Components/Hooks/InputComponent";
import PopoverDelete from "../../../../Components/Hooks/PopoverDelete";
import { Link, useHistory } from "react-router-dom";
import { Input } from "reactstrap";

function TablePackageRows({
	setFieldValue,
	data,
	index,
	handleChange,
	disabledPO = {
		pointerEvents: "unset",
		opacity: 1,
	},
	deleteTableRows,
	rowsDataLength,
}) {
	const { numberOfPackage, numPK, weight, length, width, height, statusChecked } = data;

	const dispatch = useDispatch();

	const [flagDisable, setFlagDisable] = useState(false);

	const formikRef = useRef(null);

	const [isErrorName, setIsErrorName] = useState(false);
	const [isErrorSizeW, setIsErrorSizeW] = useState(false);
	const [isErrorSizeL, setIsErrorSizeL] = useState(false);
	const [isErrorSizeH, setIsErrorSizeH] = useState(false);
	const [isErrorWeight, setIsErrorWeight] = useState(false);
	const [checked, setCheck] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		setCheck(statusChecked);
	}, [statusChecked]);

	useEffect(() => {
		if (numberOfPackage == "" && width == "" && length == "" && height == "" && weight == "") {
			setIsErrorName(false);
			setIsErrorSizeW(false);
			setIsErrorSizeL(false);
			setIsErrorSizeH(false);
			setIsErrorWeight(false);
		} else {
			// if (numberOfPackage != "") {
			// 	setIsErrorName(false);
			// } else setIsErrorName(true);
			if (width != "" && width >= 1 && width <= 999) {
				setIsErrorSizeW(false);
			} else setIsErrorSizeW(true);
			if (length != "" && length >= 1 && length <= 999) {
				setIsErrorSizeL(false);
			} else setIsErrorSizeL(true);
			if (height != "" && height >= 1 && height <= 999) {
				setIsErrorSizeH(false);
			} else setIsErrorSizeH(true);
			if (weight != "" && weight > 0 && weight <= 999) {
				setIsErrorWeight(false);
			} else setIsErrorWeight(true);
		}
	}, [numberOfPackage, width, length, height, weight]);

	useEffect(() => {
		if (isErrorName || isErrorSizeW || isErrorSizeL || isErrorSizeH || isErrorWeight) {
			setFieldValue("requestedPackageLineItemsError", true);
		} else {
			setFieldValue("requestedPackageLineItemsError", false);
		}
	}, [isErrorName, isErrorSizeW, isErrorSizeL, isErrorSizeH, isErrorWeight]);

	const handleChecked = () => {
		// setCheck(!checked);
		// handleChange(index, !statusChecked, "statusChecked");
	};

	return (
		<React.Fragment>
			<td>
				<div className="input-step">
					<button
						type="button"
						className="minus"
						onClick={() => {
							if (numPK == 1) {
								return;
							} else {
								handleChange(index, numPK - 1, "numPK");
							}
						}}>
						–
					</button>
					<Input
						type="text"
						className="product-quantity"
						value={numPK}
						name="demo_vertical"
						style={{ width: "28px", padding: "0px", margin: "0px" }}
						readOnly
						// onChange={(e) => {
						// 	handleChange(index, e.target.value, "numPK");
						// }}
					/>
					<button
						type="button"
						className="plus"
						onClick={() => {
							if (numPK == 999) {
								return;
							} else {
								handleChange(index, numPK + 1, "numPK");
							}
						}}>
						+
					</button>
				</div>
			</td>
			{/* <td>
				<div style={disabledPO}>
					<InputComponent
						type="text"
						value={numberOfPackage || ""}
						onChange={(value) => {
							handleChange(index, value, "numberOfPackage");
							if (!checked) {
								handleChecked();
							}
						}}
						name="numberOfPackage"
						className="form-control fst-italic"
						style={{ fontSize: 11 }}
						placeholder="Number of package"
						disabled={flagDisable}
						requiredField
						error={isErrorName}
						invalid={isErrorName}
					/>
				</div>
			</td> */}
			<td className={`text-center `}>
				<InputComponent
					type="text"
					value={weight || ""}
					onChange={(value) => {
						const estimate = Math.sqrt((value * 5000) / 18);
						handleChange(index, 18, "height");
						handleChange(index, Math.round(estimate), "length");
						handleChange(index, Math.round(estimate), "width");
						handleChange(index, value, "weight");
						if (!checked) {
							handleChecked();
						}
					}}
					name="weight"
					className="form-control fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Weight"
					disabled={flagDisable}
					requiredField
					error={isErrorWeight}
					invalid={isErrorWeight}
				/>
			</td>
			<td className={`text-center `}>
				<InputComponent
					type="text"
					value={length || ""}
					onChange={(value) => {
						handleChange(index, value, "length");
						if (!checked) {
							handleChecked();
						}
					}}
					name="length"
					className="form-control fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Length"
					disabled={flagDisable}
					requiredField
					error={isErrorSizeL}
					invalid={isErrorSizeL}
				/>
			</td>
			<td>
				<InputComponent
					type="text"
					value={width || ""}
					onChange={(value) => {
						handleChange(index, value, "width");
						if (!checked) {
							handleChecked();
						}
					}}
					name="width"
					className="form-control fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Width"
					disabled={flagDisable}
					requiredField
					error={isErrorSizeW}
					invalid={isErrorSizeW}
				/>
			</td>
			<td className="text-center">
				<InputComponent
					type="text"
					value={height || ""}
					onChange={(value) => {
						handleChange(index, value, "height");
						if (!checked) {
							handleChecked();
						}
					}}
					name="height"
					className="form-control fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Height"
					disabled={flagDisable}
					requiredField
					error={isErrorSizeH}
					invalid={isErrorSizeH}
				/>
			</td>

			<td
				className="text-center"
				style={{
					background: "white",
					borderTop: "1px solid white",
					borderRight: "0px solid white",
					borderBottom: "1px solid white",
				}}>
				{width == "" && length == "" && height == "" && weight == "" ? null : (
					<React.Fragment>
						<Link id={`popoverProductRows${index}`} className="remove-item-btn text-danger" onClick={() => setIsOpen(!isOpen)} to="#">
							<i className="ri-delete-bin-line align-bottom fs-16"></i>
						</Link>
						<PopoverDelete
							isOpen={isOpen}
							target={`popoverProductRows${index}`}
							toggle={() => setIsOpen(!isOpen)}
							handleCancel={() => setIsOpen(false)}
							handleConfirm={() => {
								deleteTableRows(index);
								setIsOpen(false);
							}}
						/>
					</React.Fragment>
				)}
				{/* {numberOfPackage == "" || width == "" || length == "" || height == "" || weight == "" ? (
					<></>
				) : (
					<InputComponent
						type="checkbox"
						checked={checked || false}
						onChange={() => {
							handleChecked();
						}}
					/>
				)} */}
			</td>
		</React.Fragment>
	);
}

export default TablePackageRows;
