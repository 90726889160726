import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik } from "formik";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import FormSubmit from "../../../../../Components/Common/FormSubmit";
import InputComponent from "../../../../../Components/Hooks/InputComponent";
import SelectComponent from "../../../../../Components/Hooks/SelectComponent";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import LabelComponent from "../../../../../Components/Hooks/LabelComponent";
import Fedex_logo from "../../../../../assets/images/logo/Fedex_logo.png";
import { getDataFromApi } from "../../../../../Components/Hooks/Functions";
import ModalLoading from "../../../../../Components/Hooks/ModalLoading";
import ModalViewFile from "../../../../../Components/Hooks/ModalViewFile";
import { toast } from "react-toastify";
import { phoneRegExp, regexEmail } from "../../../../../Components/Common/Constant";

const ModalFedex = ({
	isOpen = false,
	toggle = () => {},
	type,
	valuesCheckPrice,
	dataRow,
	token,
	currentTimestamp,
	typeShipment,
	convertTimeShip,
}) => {
	const dispatch = useDispatch();
	const formikRefViewFile = useRef();

	//Ship
	const [addressShip, setAddressShip] = useState("");
	const [personNameShip, setPersonNameShip] = useState("");
	const [companyNameShip, setCompanyNameShip] = useState("");
	const [phoneNumberShip, setPhoneNumberShip] = useState("");
	const [emailShip, setEmailShip] = useState("");
	const [taxIDNumberShip, setTaxIDNumberShip] = useState("");
	const [taxIDNumberValueShip, setTaxIDNumberValueShip] = useState("");

	//Recipient
	const [addressRecip, setAddressRecip] = useState("");
	const [personNameRecip, setPersonNameRecip] = useState("");
	const [companyNameRecip, setCompanyNameRecip] = useState("");
	const [phoneNumberRecip, setPhoneNumberRecip] = useState("");
	const [emailRecip, setEmailRecip] = useState("");
	const [taxIDNumberRecip, setTaxIDNumberRecip] = useState("");
	const [taxIDNumberValueRecip, setTaxIDNumberValueRecip] = useState("");

	const [invoiceNumber, setInvoiceNumber] = useState("");

	const [imageType, setImageType] = useState(null);
	const [labelStockType, setLabelStockType] = useState(null);

	const [listImageType, setListImageType] = useState([]);
	const [listLabelStockType, setListLabelStockType] = useState([]);

	const [productName, setProductName] = useState("");
	const [price, setPrice] = useState("");
	const [time, setTime] = useState("");

	const [isOpenModel, setIsOpenModel] = useState(false);
	const [isOpenLoading, setIsOpenLoading] = useState(false);
	const [dataDocument, setDataDocument] = useState(null);

	const { dataListDefinitions } = useSelector((state) => state.DashboardListLogistics);

	const [listTaxID, setListTaxID] = useState([]);

	useEffect(() => {
		const statusArr = Object.values(dataListDefinitions?.TAX_ID ?? {})?.reduce((prevResult, currItem) => {
			prevResult.push({
				id: currItem,
				code: currItem,
			});
			return prevResult;
		}, []);
		setListTaxID(statusArr);
	}, [dataListDefinitions]);

	const hanldeGetAddressData = (field = "from") => {
		let addressData = "";
		if (valuesCheckPrice?.addressInfo[field]?.address) {
			addressData = valuesCheckPrice?.addressInfo[field]?.address;
		}
		if (valuesCheckPrice?.addressInfo[field]?.city) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.city;
			} else {
				addressData = addressData + ", " + valuesCheckPrice?.addressInfo[field]?.city;
			}
		}
		if (valuesCheckPrice?.addressInfo[field]?.state) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.state;
			} else {
				addressData = addressData + ", " + valuesCheckPrice?.addressInfo[field]?.state;
			}
		}
		if (valuesCheckPrice?.addressInfo[field]?.postCode) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.postCode;
			} else {
				addressData = addressData + " " + valuesCheckPrice?.addressInfo[field]?.postCode;
			}
		}
		if (valuesCheckPrice?.addressInfo[field]?.country) {
			if (addressData == "") {
				addressData = valuesCheckPrice?.addressInfo[field]?.country;
			} else {
				addressData = addressData + ", " + valuesCheckPrice?.addressInfo[field]?.country;
			}
		}
		return addressData;
	};

	useEffect(() => {
		if (isOpen) {
			// setPersonNameShip("harry potter 2");
			// setCompanyNameShip("apac tech 2");
			// setPhoneNumberShip("+6128066886");

			// setPersonNameRecip("harry potter");
			// setCompanyNameRecip("apac tech");
			// setPhoneNumberRecip("+9138584708");

			const addressDataShip = hanldeGetAddressData("from");
			setAddressShip(addressDataShip);
			setPhoneNumberShip(valuesCheckPrice?.phoneFrom);
			setPersonNameShip(valuesCheckPrice?.personNameFrom);
			setCompanyNameShip(valuesCheckPrice?.companyNameFrom);
			setEmailShip(valuesCheckPrice?.emailAddressFrom);

			const addressDataRecip = hanldeGetAddressData("to");
			setAddressRecip(addressDataRecip);
			setPhoneNumberRecip(valuesCheckPrice?.addressInfo?.to?.phone);
			setPersonNameRecip(valuesCheckPrice?.personNameTo);
			setCompanyNameRecip(valuesCheckPrice?.companyNameTo);
			setEmailRecip(valuesCheckPrice?.emailAddressTo);
		}
	}, [valuesCheckPrice?.addressInfo, isOpen]);

	useEffect(() => {
		const imageTypeData = Object.values(dataListDefinitions?.Label_Stock_Types ?? {})?.reduce((prevResult, currItem) => {
			if (!prevResult.includes(currItem.image_type)) {
				prevResult.push(currItem.image_type);
			}
			return prevResult;
		}, []);

		// const imageTypeData = ["PDF", "PNG", "ZPLII", "EPL2"];
		const methodObjects = imageTypeData.map((method, index) => ({
			id: index + 1,
			name: method,
		}));
		setListImageType(methodObjects);
	}, [dataListDefinitions]);

	useEffect(() => {
		if (imageType?.name) {
			const methodsStockType = Object.values(dataListDefinitions?.Label_Stock_Types ?? {})?.reduce((prevResult, currItem) => {
				if (!prevResult.includes(currItem.stock_type) && imageType.name == currItem.image_type) {
					prevResult.push(currItem.stock_type);
				}
				return prevResult;
			}, []);
			// let methodsStockType = [];
			// if (["PNG", "PDF"].includes(imageType?.name)) {
			// 	methodsStockType = [
			// 		"PAPER_4X6",
			// 		"PAPER_4X675",
			// 		"PAPER_4X8",
			// 		"PAPER_4X9",
			// 		"PAPER_7X475",
			// 		"PAPER_85X11_BOTTOM_HALF_LABEL",
			// 		"PAPER_85X11_TOP_HALF_LABEL",
			// 		"PAPER_LETTER",
			// 	];
			// } else if (["ZPLII", "EPL2"].includes(imageType?.name)) {
			// 	methodsStockType = [
			// 		"STOCK_4X6",
			// 		"STOCK_4X675_LEADING_DOC_TAB",
			// 		"STOCK_4X675_TRAILING_DOC_TAB",
			// 		"STOCK_4X8",
			// 		"STOCK_4X9",
			// 		"STOCK_4X9_LEADING_DOC_TAB",
			// 		"STOCK_4X9_TRAILING_DOC_TAB",
			// 		"STOCK_4X85_TRAILING_DOC_TAB",
			// 		"STOCK_4X105_TRAILING_DOC_TAB",
			// 	];
			// }
			const methodObjectsStockType = methodsStockType.map((method, index) => ({
				id: index + 1,
				name: method,
			}));
			setListLabelStockType(methodObjectsStockType);
		} else {
			setListLabelStockType([]);
		}
	}, [imageType]);

	useEffect(() => {
		setImageType(listImageType.find((item) => item.name == "PDF"));
	}, [listImageType]);
	useEffect(() => {
		setLabelStockType(listLabelStockType.find((item) => item.name == "PAPER_4X6"));
	}, [listLabelStockType]);

	useEffect(() => {
		if (isOpen && dataRow?.historyId) {
			setProductName(dataRow?.serviceName);
			setPrice(dataRow?.price);
			setTime(convertTimeShip(dataRow?.deliverDateRange));
		}
	}, [isOpen]);

	const formikRef = useRef();

	const validate = (values) => {
		let errors = {};

		if (values?.personNameShip === "") errors.personNameShip = "Please enter person name";
		if (values?.companyNameShip === "") errors.companyNameShip = "Please enter company name";
		if (values?.phoneNumberShip === "") errors.phoneNumberShip = "Please enter phone number";
		if (!regexEmail.test(values?.emailShip) && values?.emailShip !== "") errors.emailShip = "Invalid Email";
		if (!phoneRegExp.test(values?.phoneNumberShip) && values?.phoneNumberShip !== "") errors.phoneNumberShip = "Invalid Phone Number";

		if (values?.personNameRecip === "") errors.personNameRecip = "Please enter person name";
		if (values?.companyNameRecip === "") errors.companyNameRecip = "Please enter company name";
		if (values?.phoneNumberRecip === "") errors.phoneNumberRecip = "Please enter phone number";
		if (!regexEmail.test(values?.emailRecip) && values?.emailRecip !== "") errors.emailRecip = "Invalid Email";
		if (!phoneRegExp.test(values?.phoneNumberRecip) && values?.phoneNumberRecip !== "") errors.phoneNumberRecip = "Invalid Phone Number";

		if (values?.invoiceNumber === "") errors.invoiceNumber = "Please enter invoice number";
		if (!labelStockType?.name) errors.labelStockType = "Please select label stock type";
		if (!imageType?.name) errors.imageType = "Please select image type";

		return errors;
	};

	const submitForm = async (values) => {
		const requestData = {
			courier: typeShipment,
			historyId: dataRow?.historyId,
			commodities: [],
			requestedPackageLineItems: valuesCheckPrice.requestedPackageLineItems ?? [],
			shipper: {
				address: valuesCheckPrice.from,
				contact: {
					personName: personNameShip,
					phoneNumber: phoneNumberShip,
					companyName: companyNameShip,
					emailAddress: emailShip,
				},
			},
			recipient: {
				address: valuesCheckPrice.to,
				contact: {
					personName: personNameRecip,
					phoneNumber: phoneNumberRecip,
					companyName: companyNameRecip,
					emailAddress: emailRecip,
				},
			},
			domesticInformation: valuesCheckPrice?.domesticInformation ?? "",

			inforCreateFedex: {
				invoiceNumber: invoiceNumber, // not required. mã đơn hàng
				// pickupType: "USE_SCHEDULED_PICKUP", // not required. một trong: "CONTACT_FEDEX_TO_SCHEDULE" "DROPOFF_AT_FEDEX_LOCATION" "USE_SCHEDULED_PICKUP"
				labelSpecification: {
					// not required. cho biết định dạng document muốn fedex trả về. https://developer.fedex.com/api/en-us/guides/api-reference.html#labelstocktypes
					labelStockType: labelStockType?.name ?? "",
					imageType: imageType?.name ?? "",
				},
			},
			addressInfo: valuesCheckPrice?.addressInfo,
		};
		setIsOpenLoading(true);
		const response = await handleCreateShipment(requestData);
		if (response?.error) {
			toast.error(response?.error && response?.error?.message);
		} else {
			setDataDocument(response);
		}
		setIsOpenLoading(false);
	};

	const initialValues = {
		addressShip: "",
		personNameShip: valuesCheckPrice?.personNameFrom ?? "",
		companyNameShip: valuesCheckPrice?.companyNameFrom ?? "",
		phoneNumberShip: valuesCheckPrice?.phoneFrom ?? "",
		emailShip: valuesCheckPrice?.emailAddressFrom ?? "",
		taxIDNumberShip: "",
		taxIDNumberValueShip: "",

		addressRecip: "",
		personNameRecip: "",
		companyNameRecip: "",
		phoneNumberRecip: "",
		emailRecip: "",
		taxIDNumberRecip: "",
		taxIDNumberValueRecip: "",

		invoiceNumber: "",
		commercialInvoice: "",

		imageType: null,
		labelStockType: null,
	};

	const handleReset = () => {
		toggle();

		setAddressShip("");
		setPersonNameShip(valuesCheckPrice?.personNameFrom ?? "");
		setCompanyNameShip(valuesCheckPrice?.companyNameFrom ?? "");
		setPhoneNumberShip(valuesCheckPrice?.phoneFrom ?? "");
		setEmailShip(valuesCheckPrice?.emailAddressFrom ?? "");
		setTaxIDNumberShip("");
		setTaxIDNumberValueShip("");

		setAddressRecip("");
		setPersonNameRecip("");
		setCompanyNameRecip("");
		setPhoneNumberRecip("");
		setEmailRecip("");
		setTaxIDNumberRecip("");
		setTaxIDNumberValueRecip("");

		setInvoiceNumber("");
		setImageType(null);
		setLabelStockType(null);
	};

	const handleCreateShipment = async (requestBody) => {
		if (token != "") {
			const url = process.env.REACT_APP_API_URL + "/api/shipment/create-shipment";

			return await getDataFromApi("POST", url, requestBody, token, currentTimestamp)
				.then((data) => {
					return data;
				})
				.catch((error) => {
					return [];
					// console.error("Error fetching data:", error);
				});
		}
	};

	return (
		<>
			<ModalLoading isOpen={isOpenLoading} />
			<ModalViewFile
				dataDocument={dataDocument}
				setIsOpen={setIsOpenModel}
				isOpen={isOpenModel}
				formikRef={formikRefViewFile}
				toggleCreateShipment={() => {
					handleReset();
				}}
			/>
			<Modal
				isOpen={isOpen}
				toggle={() => {
					handleReset();
				}}
				centered
				className="modal-lg-2">
				<Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={submitForm} innerRef={formikRef}>
					{(formik) => {
						const {
							values,
							handleChange,
							setFieldValue,
							handleSubmit,
							errors,
							touched,
							handleBlur,
							isValid,
							dirty,
							validateForm,
							setFieldError,
							setFieldTouched,
						} = formik;
						return (
							<FormSubmit onSubmit={handleSubmit}>
								<ModalHeader
									className="bg-soft-info p-3"
									toggle={() => {
										handleReset();
									}}>
									Fedex Label
								</ModalHeader>
								<ModalBody className="p-0">
									<Row className="ps-3 pb-1 d-flex align-items-center">
										<img
											src={Fedex_logo}
											alt=""
											className="acitivity-avatar"
											style={{
												marginLeft: "13px",
												marginTop: "10px",
												width: "250px",
												height: "120px",
												borderRadius: "10%",
											}}
										/>
										<div className="p-2 w-auto px-1 text-left  ">
											<div className="d-flex align-items-center">
												<legend className="fw-bold  float-none" style={{ fontSize: "15px" }}>
													Product Name: {productName}
												</legend>
											</div>
											<div className="d-flex align-items-center">
												<legend className="fw-bold  float-none" style={{ fontSize: "15px" }}>
													Price: {price} USD
												</legend>
											</div>
											<div className="d-flex align-items-center">
												<legend className="fw-bold  float-none" style={{ fontSize: "15px" }}>
													Time: {time}
												</legend>
											</div>
										</div>
									</Row>
									<Row>
										<div className="d-flex align-items-center">
											<div className="mb-0 w-100 pb-1">
												<fieldset className="border rounded-3">
													<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
														Shipper Information
													</legend>
													<Row className="gy-3 pe-3 ps-3">
														<Col lg={12}>
															<LabelComponent title="Address:" />
															<InputComponent
																type="search"
																name={`addressShip`}
																value={addressShip}
																onChange={(value, name) => {
																	setAddressShip(value);
																	setFieldValue("addressShip", value);
																}}
																placeholder={"Address"}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Person name:" requiredField />
															<InputComponent
																type="search"
																name={`personNameShip`}
																value={personNameShip}
																onChange={(value, name) => {
																	setPersonNameShip(value);
																	setFieldValue("personNameShip", value);
																}}
																placeholder={"Person name"}
																onBlur={handleBlur}
																invalid={touched.personNameShip && errors.personNameShip ? true : false}
																error={touched?.personNameShip && errors?.personNameShip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Company name:" requiredField />
															<InputComponent
																type="search"
																name={`companyNameShip`}
																value={companyNameShip}
																onChange={(value, name) => {
																	setCompanyNameShip(value);
																	setFieldValue("companyNameShip", value);
																}}
																placeholder={"Company name"}
																onBlur={handleBlur}
																invalid={touched.companyNameShip && errors.companyNameShip ? true : false}
																error={touched?.companyNameShip && errors?.companyNameShip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Phone number:" requiredField />
															<InputComponent
																type="search"
																name={`phoneNumberShip`}
																value={phoneNumberShip}
																onChange={(value, name) => {
																	setPhoneNumberShip(value);
																	setFieldValue("phoneNumberShip", value);
																}}
																placeholder={"Phone number"}
																onBlur={handleBlur}
																invalid={touched.phoneNumberShip && errors.phoneNumberShip ? true : false}
																error={touched?.phoneNumberShip && errors?.phoneNumberShip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Email:" />
															<InputComponent
																type="search"
																name={`emailShip`}
																value={emailShip}
																onChange={(value, name) => {
																	setEmailShip(value);
																	setFieldValue("emailShip", value);
																}}
																placeholder={"Email"}
																onBlur={handleBlur}
																invalid={touched.emailShip && errors.emailShip ? true : false}
																error={touched?.emailShip && errors?.emailShip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Tax ID Number:" />
															<SelectComponent
																isSearchServer={true}
																label=""
																options={listTaxID}
																name="taxIDNumberShip"
																onBlur={() => {
																	handleBlur({ target: { name: "taxIDNumberShip" } });
																}}
																onChange={(option) => {
																	setFieldValue("taxIDNumberShip", option);
																	setTaxIDNumberShip(option);
																}}
																validate={{
																	required: { value: true },
																}}
																value={taxIDNumberShip}
																placeholder={"Select value"}
																fieldValue="id"
																display="name"
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="&nbsp;" />
															<InputComponent
																type="search"
																name={`taxIDNumberValueShip`}
																value={taxIDNumberValueShip}
																onChange={(value, name) => {
																	setTaxIDNumberValueShip(value);
																	setFieldValue("taxIDNumberValueShip", value);
																}}
																placeholder={"Value"}
															/>
														</Col>
													</Row>
												</fieldset>
											</div>
										</div>
									</Row>
									<Row>
										<div className="d-flex align-items-center">
											<div className="mb-0 w-100 pb-1">
												<fieldset className="border rounded-3">
													<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
														Recipient Information
													</legend>
													<Row className="gy-3 pe-3 ps-3">
														<Col lg={12}>
															<LabelComponent title="Address:" />
															<InputComponent
																type="search"
																name={`addressRecip`}
																value={addressRecip}
																onChange={(value, name) => {
																	setAddressRecip(value);
																	setFieldValue("addressRecip", value);
																}}
																placeholder={"Address"}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Person name:" requiredField />
															<InputComponent
																type="search"
																name={`personNameRecip`}
																value={personNameRecip}
																onChange={(value, name) => {
																	setPersonNameRecip(value);
																	setFieldValue("personNameRecip", value);
																}}
																placeholder={"Person name"}
																onBlur={handleBlur}
																invalid={touched.personNameRecip && errors.personNameRecip ? true : false}
																error={touched?.personNameRecip && errors?.personNameRecip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Company name:" requiredField />
															<InputComponent
																type="search"
																name={`companyNameRecip`}
																value={companyNameRecip}
																onChange={(value, name) => {
																	setCompanyNameRecip(value);
																	setFieldValue("companyNameRecip", value);
																}}
																placeholder={"Company name"}
																onBlur={handleBlur}
																invalid={touched.companyNameRecip && errors.companyNameRecip ? true : false}
																error={touched?.companyNameRecip && errors?.companyNameRecip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Phone number:" requiredField />
															<InputComponent
																type="search"
																name={`phoneNumberRecip`}
																value={phoneNumberRecip}
																onChange={(value, name) => {
																	setPhoneNumberRecip(value);
																	setFieldValue("phoneNumberRecip", value);
																}}
																placeholder={"Phone number"}
																onBlur={handleBlur}
																invalid={touched.phoneNumberRecip && errors.phoneNumberRecip ? true : false}
																error={touched?.phoneNumberRecip && errors?.phoneNumberRecip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Email:" />
															<InputComponent
																type="search"
																name={`emailRecip`}
																value={emailRecip}
																onChange={(value, name) => {
																	setEmailRecip(value);
																	setFieldValue("emailRecip", value);
																}}
																placeholder={"Email"}
																onBlur={handleBlur}
																invalid={touched.emailRecip && errors.emailRecip ? true : false}
																error={touched?.emailRecip && errors?.emailRecip}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Tax ID Number:" />
															<SelectComponent
																isSearchServer={true}
																label=""
																options={listTaxID}
																name="taxIDNumberRecip"
																onBlur={() => {
																	handleBlur({ target: { name: "taxIDNumberRecip" } });
																}}
																onChange={(option) => {
																	setFieldValue("taxIDNumberRecip", option);
																	setTaxIDNumberRecip(option);
																}}
																validate={{
																	required: { value: true },
																}}
																value={taxIDNumberRecip}
																placeholder={"Select value"}
																fieldValue="id"
																display="name"
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="&nbsp;" />
															<InputComponent
																type="search"
																name={`taxIDNumberValueRecip`}
																value={taxIDNumberValueRecip}
																onChange={(value, name) => {
																	setTaxIDNumberValueRecip(value);
																	setFieldValue("taxIDNumberValueRecip", value);
																}}
																placeholder={"Value"}
															/>
														</Col>
													</Row>
												</fieldset>
											</div>
										</div>
									</Row>

									<Row>
										<div className="d-flex align-items-center">
											<div className="mb-0 w-100 pb-1">
												<fieldset className="border rounded-3">
													<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
														Commercial Invoice
													</legend>
													<Row className="gy-3 pe-3 ps-3">
														<Col lg={12}>
															<LabelComponent title="Invoice Number:" requiredField />
															<InputComponent
																type="search"
																name={`invoiceNumber`}
																value={invoiceNumber}
																onChange={(value, name) => {
																	setInvoiceNumber(value);
																	setFieldValue("invoiceNumber", value);
																}}
																placeholder={"Invoice Number"}
																onBlur={handleBlur}
																invalid={touched.invoiceNumber && errors.invoiceNumber ? true : false}
																error={touched?.invoiceNumber && errors?.invoiceNumber}
															/>
														</Col>
													</Row>
												</fieldset>
											</div>
										</div>
									</Row>

									<Row>
										<div className="d-flex align-items-center">
											<div className="mb-0 w-100 pb-1">
												<fieldset className="border rounded-3">
													<legend className="float-none w-auto px-1 text-left fw-bold" style={{ fontSize: "15px" }}>
														Label Specification
													</legend>
													<Row className="gy-3 pe-3 ps-3">
														<Col lg={6}>
															<LabelComponent title="Image Type:" requiredField />
															<SelectComponent
																isSearchServer={true}
																label=""
																options={listImageType}
																name="imageType"
																onBlur={() => {
																	handleBlur({ target: { name: "imageType" } });
																}}
																onChange={(option) => {
																	setFieldValue("imageType", option);
																	setImageType(option);
																	setFieldValue("labelStockType", null);
																	setLabelStockType(null);
																}}
																validate={{
																	required: { value: true },
																}}
																value={imageType}
																placeholder={"Select value"}
																fieldValue="id"
																display="name"
																requiredField
																touched={touched.imageType && errors.imageType ? true : false}
																error={touched?.imageType && errors?.imageType}
															/>
														</Col>
														<Col lg={6}>
															<LabelComponent title="Label Stock Type:" requiredField />
															<SelectComponent
																isSearchServer={true}
																label=""
																options={listLabelStockType}
																name="labelStockType"
																onBlur={() => {
																	handleBlur({ target: { name: "labelStockType" } });
																}}
																onChange={(option) => {
																	setFieldValue("labelStockType", option);
																	setLabelStockType(option);
																}}
																validate={{
																	required: { value: true },
																}}
																value={labelStockType}
																placeholder={"Select value"}
																fieldValue="id"
																display="name"
																requiredField
																touched={touched.labelStockType && errors.labelStockType ? true : false}
																error={touched?.labelStockType && errors?.labelStockType}
															/>
														</Col>
													</Row>
												</fieldset>
											</div>
										</div>
									</Row>
									<div className="mb-3 w-100 mx-auto d-flex align-items-center justify-content-end">
										<div style={{ marginRight: "29px" }}>
											<Button
												// disabled={!dirty}
												type="button"
												color="success"
												onClick={() => {
													handleSubmit(values);
												}}>
												Create Shipment
											</Button>
										</div>
									</div>
								</ModalBody>
							</FormSubmit>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
};

export default ModalFedex;
