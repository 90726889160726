import React, { useState, useEffect, useCallback, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputComponent from "../../../../Components/Hooks/InputComponent";
import SelectComponent from "../../../../Components/Hooks/SelectComponent";
import PopoverDelete from "../../../../Components/Hooks/PopoverDelete";
import { Link, useHistory } from "react-router-dom";

function TableCommoditiesRows({
	data,
	index,
	handleChange,
	disabledPO = {
		pointerEvents: "unset",
		opacity: 1,
	},
	deleteTableRows,
}) {
	const { description, units, weight, measures, width, height, customsValue, unitValue } = data;
	const { dataListDefinitions } = useSelector((state) => state.DashboardListLogistics);
	const dispatch = useDispatch();

	const [flagDisable, setFlagDisable] = useState(false);
	const [optionProductCode, setOptionProductCode] = useState(null);
	const [isOpen, setIsOpen] = useState(false);

	const formikRef = useRef(null);

	const [optionMeasures, setOptionMeasures] = useState(null);
	const [inputValueSearchMeasures, setInputValueSearchMeasures] = useState("");
	const [listMeasures, setListMeasures] = useState([]);

	useEffect(() => {
		const dataDefinitions = Object.values(dataListDefinitions?.UNIT_OF_MEASURE ?? {})?.reduce((prevResult, currItem) => {
			prevResult.push({
				id: currItem.value,
				code: currItem.description,
			});
			return prevResult;
		}, []);
		setListMeasures(dataDefinitions);
	}, [dataListDefinitions]);

	useEffect(() => {
		if (measures != "" && listMeasures.length > 0) {
			setOptionMeasures(listMeasures.find((item) => item.id == measures));
		} else {
			setOptionMeasures(null);
		}
	}, [measures, listMeasures]);

	const handleChangeMeasures = (option) => {
		setOptionMeasures(option);
		handleChange(index, option?.id ? option.id : "", "measures");
		setInputValueSearchMeasures("");
	};

	return (
		<React.Fragment>
			<td>
				<div style={disabledPO}>
					<InputComponent
						type="text"
						value={description || ""}
						onChange={(value) => handleChange(index, value, "description")}
						name="description"
						className="form-control fst-italic"
						style={{ fontSize: 11 }}
						placeholder="Description"
						disabled={flagDisable}
					/>
				</div>
			</td>
			<td className={`text-center `}>
				<InputComponent
					type="text"
					value={units || ""}
					onChange={(value) => handleChange(index, value, "units")}
					name="units"
					className="form-control fst-italic"
					style={{ fontSize: 11 }}
					placeholder="No. of Units"
					disabled={flagDisable}
				/>
			</td>
			<td className={`text-center `}>
				<InputComponent
					type="text"
					value={weight || ""}
					onChange={(value) => handleChange(index, value, "weight")}
					name="weight"
					className="form-control fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Weight"
					disabled={flagDisable}
				/>
			</td>
			<td>
				<SelectComponent
					isSearchServer={false}
					options={listMeasures}
					name="measures"
					onChange={(option) => {
						handleChangeMeasures(option);
					}}
					value={optionMeasures?.id ? optionMeasures : []}
					placeholder="Select Measures"
					fieldValue="id"
					setInputValue={setInputValueSearchMeasures}
					inputValue={inputValueSearchMeasures}
					display="code"
					size="sm"
				/>
			</td>
			<td className="text-center">
				<InputComponent
					type="text"
					value={width || ""}
					onChange={(value) => handleChange(index, value, "width")}
					name="width"
					className="form-control fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Width"
					disabled={flagDisable}
				/>
			</td>
			<td className="text-center">
				<InputComponent
					type="text"
					value={height || ""}
					onChange={(value) => handleChange(index, value, "height")}
					name="height"
					className="form-control fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Height"
					disabled={flagDisable}
				/>
			</td>
			<td className="text-center">
				<InputComponent
					type="text"
					value={customsValue || ""}
					onChange={(value) => handleChange(index, value, "customsValue")}
					name="customsValue"
					className="form-control fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Customs Value"
					disabled={flagDisable}
				/>
			</td>
			<td className="text-center">
				<InputComponent
					type="text"
					value={unitValue || ""}
					onChange={(value) => handleChange(index, value, "unitValue")}
					name="unitValue"
					className="form-control fst-italic"
					style={{ fontSize: 11 }}
					placeholder="Unit Value"
					disabled={flagDisable}
				/>
			</td>
			<td
				className="text-center"
				style={{
					background: "white",
					borderTop: "1px solid white",
					borderRight: "0px solid white",
					borderBottom: "1px solid white",
				}}>
				{description == "" &&
				units == "" &&
				weight == "" &&
				measures == "" &&
				width == "" &&
				height == "" &&
				customsValue == "" &&
				unitValue == "" ? null : (
					<React.Fragment>
						<Link id={`popoverProductRows${index}`} className="remove-item-btn text-danger" onClick={() => setIsOpen(!isOpen)} to="#">
							<i className="ri-delete-bin-line align-bottom fs-16"></i>
						</Link>
						<PopoverDelete
							isOpen={isOpen}
							target={`popoverProductRows${index}`}
							toggle={() => setIsOpen(!isOpen)}
							handleCancel={() => setIsOpen(false)}
							handleConfirm={() => {
								deleteTableRows(index);
								setIsOpen(false);
							}}
						/>
					</React.Fragment>
				)}
			</td>
		</React.Fragment>
	);
}

export default TableCommoditiesRows;
